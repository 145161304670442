import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'kt-verify-ocr-dialog',
    templateUrl: './verify-ocr-dialog.component.html',
    styleUrls: ['./verify-ocr-dialog.component.scss']
})
export class VerifyOcrDialogComponent implements OnInit {

    private loading$ = new BehaviorSubject<boolean>(false);
    spinner$ = this.loading$.asObservable();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<VerifyOcrDialogComponent>) {
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    save(feedBack: boolean) {
        this.dialogRef.close(feedBack);
    }
}
