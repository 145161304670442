import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "checkListDocTranslate",
})
export class ChecklistfilesTranslatePipe implements PipeTransform {
  transform(checkListDoc: string): string {
    let tempCheckListDoc = checkListDoc.toLowerCase();

    let docName = "";

    switch (tempCheckListDoc) {
      //FundingRequest
      case "fundingRequestCCCC".toLowerCase():
        docName = "Coordonnées du client complètes et conformes";
        break;
      case "DFSCC".toLowerCase():
        docName = "La durée du financement souhaitée complète et conforme";
        break;
      case "DFSC".toLowerCase():
        docName = "La demande de financement signée par le client";
        break;
      case "fundingRequestSCACA".toLowerCase():
        docName =
          "La signature du client est authentifiée par le chargé d'authentification (CACHET FDV )";
        break;
      case "DFD".toLowerCase():
        docName = "La demande de financement est datée";
        break;
      case "fundingRequestRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //consultationAuthorizationFromCremBA
      case "consultationAuthorizationFromCremBACCCC".toLowerCase():
        docName = "Coordonnées du client complètes et conformes";
        break;
      case "DSC".toLowerCase():
        docName = "Le document est signé par le client";
        break;
      case "consultationAuthorizationFromCremBASCACA".toLowerCase():
        docName =
          "La signature du client est authentifiée par le chargé d'authentification  (CACHET FDV )";
        break;
      case "DD".toLowerCase():
        docName = "Le document est daté";
        break;
      case "consultationAuthorizationFromCremBARD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //BiometricNationalCard
      case "CLCRV".toLowerCase():
        docName = "Copie lisible de la CNI recto verso";
        break;
      case "CCV".toLowerCase():
        docName = "CNI en cours de validité";
        break;
      /*case 'biometricNationalCardCCCC'.toLowerCase():
                docName = 'La CNI correspond au client concerné';
                break;*/
      case "biometricNationalCardDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "BiometricNationalCardDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "biometricNationalCardRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //BirthCertificate
      case "NPSELF".toLowerCase():
        docName = "Le nom et prénom sont écrits en langue française";
        break;
      case "DCV".toLowerCase():
        docName = " Le document est en cours de validité < 1 année";
        break;
      case "DLAPCQ".toLowerCase():
        docName =
          "Le document est legalisé par l'APC ou porte le code QR (acte de naissance établi par internet)";
        break;
      case "DPNOHD".toLowerCase():
        docName = " Le document porte un numéro d'ordre en haut à droite";
        break;
      case "LNM".toLowerCase():
        docName = "Le lieu de naissance et mentionné";
        break;
      case "CAM75".toLowerCase():
        docName = "Le client est agé de moins de 75 ans";
        break;
      case "BirthCertificateDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "BirthCertificateDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "BirthCertificateDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "BirthCertificateRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //ResidenceCard
      case "DCV6".toLowerCase():
        docName = "Le document est en cours de validité < 6 mois";
        break;
      case "ResidenceCardDLAPC".toLowerCase():
        docName = "Le document est legalisé par l'APC";
        break;
      case "DACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "ResidenceCardDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "ResidenceCardDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "residenceCarRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      case "ResidencySCEGR".toLowerCase():
        docName =
            "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;

      //WorkCertificate
      case "AATAC".toLowerCase():
        docName = "S'assurer que l'attestation de travail appartient au client";
        break;
      case "DCV1".toLowerCase():
        docName = "Le document est en cours de validité < 1 mois";
        break;
      case "DSCEGR".toLowerCase():
        docName =
          "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;
      case "CDCCDICDD".toLowerCase():
        docName =
          "Le client détient un contrat CDI ou CDD (période inférieure à la période de financement)Minimun 1 année";
        break;
      case "WorkCertificateDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "WorkCertificateDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "workCertificateRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //PaySlipsOne
      case "DEMPDD_ONE".toLowerCase():
        docName =  "Le document est  établi le  mois qui précède le dépôt de dossier";
        break;
      case "DCE_ONE".toLowerCase():
        docName = "Le document est cacheté par l'employeur";
        break;
      case "SCIRC_ONE".toLowerCase():
        docName =
          "Le salaire est conforme à celui indiqué sur  le relevé de compte";
        break;
      case "SSSMEF25_ONE".toLowerCase():
        docName =
          "Le salaire est supérieur au salaire minimum exigé pour le financement (25 000 DA)";
        break;
      case "PaySlipsOneDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "PaySlipsOneDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "PaySlipsOneDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "paySlipsOneRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //PaySlipsTwo
      case "DEMPDD_TWO".toLowerCase():
        docName =  "Le document est  établi le  mois qui précède le dépôt de dossier";
        break;
      case "DCE_TWO".toLowerCase():
        docName = "Le document est cacheté par l'employeur";
        break;
      case "SCIRC_TWO".toLowerCase():
        docName =
          "Le salaire est conforme à celui indiqué sur  le relevé de compte";
        break;
      case "SSSMEF25_TWO".toLowerCase():
        docName =
          "Le salaire est supérieur au salaire minimum exigé pour le financement (25 000 DA)";
        break;
      case "PaySlipsTwoDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "PaySlipsTwoDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "PaySlipsTwoDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "paySlipsTwoRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //PaySlipsThree
      case "DEMPDD_THREE".toLowerCase():
        docName =  " Le document est  établi le  mois qui précède le dépôt de dossier";
        break;
      case "DCE_THREE".toLowerCase():
        docName = "Le document est cacheté par l'employeur";
        break;
      case "SCIRC_THREE".toLowerCase():
        docName =
          "Le salaire est conforme à celui indiqué sur  le relevé de compte";
        break;
      case "SSSMEF25_THREE".toLowerCase():
        docName =
          "Le salaire est supérieur au salaire minimum exigé pour le financement (25 000 DA)";
        break;
      case "PaySlipsThreeDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "PaySlipsThreeDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "PaySlipsThreeDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "paySlipsThreeRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //BankStatement
      case "RCP3".toLowerCase():
        docName = "Le relevé du compte porte sur 3 mois au minimum";
        break;
      case "VRS".toLowerCase():
        docName = "Vérifier la régularité des salaires";
        break;
      case "VCEMCMD_VETC".toLowerCase():
        docName =
          "Vérifier les concordance entre les montants crédités et les montants débités   Vérfiier l'existence des taxes et commissions";
        break;
      case "VDERC".toLowerCase():
        docName = "Vérifier la date d'établissement du relevé du compte";
        break;
      case "BankStatementDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "BankStatementDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "BankStatementDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "bankStatementRD".toLowerCase():
        docName = "Correspond au bon document";
        break;
      case "taxeCode".toLowerCase():
        docName = "Code 206 Non-détecté";
        break;
      case "taxeCodeDZD".toLowerCase():
        docName = "Taxe de 300 Non-détectée";
        break;
      case "BSDSCEGR".toLowerCase():
        docName = "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;

      //SignatureSheet
      case "NPC".toLowerCase():
        docName = "Le nom et prénom du client";
        break;
      case "DSCC".toLowerCase():
        docName = "Le document est  signé par le client  concerné";
        break;
      case "PCICS".toLowerCase():
        docName = "La photo du client est insérée sur la carte de signature";
        break;
      case "SignatureSheetSCACA".toLowerCase():
        docName =
          "La signature du client est authentifiee par le charge d'authentification (CACHET FDV)";
        break;
      case "DRS".toLowerCase():
        docName = "La date de recueil de la signature";
        break;
      case "CCCAD".toLowerCase():
        docName = "Une copie de la CNI du client est attachée à ce document";
        break;
      case "SignatureSheetRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //Facta
      case "DCC".toLowerCase():
        docName = "Le document est complet et conforme";
        break;
      case "FactaDSCP".toLowerCase():
        docName = "Le document est signé par le client  et paraphe";
        break;
      case "FactaSCACA".toLowerCase():
        docName =
          "La signature du client est authentifiée par le chargé d'authentification";
        break;
      case "factaRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //RequestOpeningCheckingAccount
      case "ATCR".toLowerCase():
        docName = "Le document est complet et conforme";
        break;
      case "RequestOpeningCheckingAccountDSCP".toLowerCase():
        docName = "Le document est signé par le client  et paraphe";
        break;
      case "RequestOpeningCheckingAccountSCACA".toLowerCase():
        docName =
          "La signature du client est authentifiée par le charge d'authentification (CACHET FDV)";
        break;
      case "requestOpeningCheckingAccountRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //CommitementProvisionCCPAccount
      case "ICCC".toLowerCase():
        docName = "L'identification du client est complète et conforme";
        break;
      case "DDDC".toLowerCase():
        docName = " Le document est daté (date création)";
        break;
      case "CommitementProvisionCCPAccountDSCP".toLowerCase():
        docName = "Le document est signé par le client et paraphe";
        break;
      case "CommitementProvisionCCPAccountSCACA".toLowerCase():
        docName =
          "La signature du client est authentifiée par le charge d'authentification (CACHET FDV)";
        break;
      case "commitementProvisionCCPAccountRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //CCPDebitAuthorization
      case "CCPDebitAuthorizationDSCP".toLowerCase():
        docName = "Le document est signé par le client et paraphe";
        break;
      case "CCPDebitAuthorizationDLAPC".toLowerCase():
        docName = "Le document est legalisé par l'APC";
        break;
      case "NCCCMD".toLowerCase():
        docName = "Le n° compte ccp du client est mentionné sur le document";
        break;
      case "NPCMD".toLowerCase():
        docName =
          "Le nom et le prénom du client sont mentionnés sur le document";
        break;
      case "CCPDebitAuthorizationRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //ChifaCard
      case "ChifaCardDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "ChifaCardDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "ChifaCardDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "ChifaCarRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //Check
      case "CheckDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "CheckDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      // case 'CheckNCCCRC'.toLowerCase():
      //     docName = 'Numéro de compte ccp comparer avec relevé de compte'
      //     break;
      case "CheckDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "checkRD".toLowerCase():
        docName = "Correspond au bon document";
        break;
      case "CheckSCEGR".toLowerCase():
        docName = "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;

      //FamilySheet
      case "FamilySheetDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "FamilySheetDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "FamilySheetDCV1".toLowerCase():
        docName = "Le document est en cours de validité < 1 année";
        break;
      case "FamilySheetDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "familySheetRD".toLowerCase():
        docName = "Correspond au bon document";
        break;
      case "FamilySheetSCEGR".toLowerCase():
        docName = "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;



      //Radiation
      case "RadiationDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "RadiationDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "RadiationDLNCCNI".toLowerCase():
        docName = "Date et lieu de naissance comparer avec CNI";
        break;
      case "RadiationDate".toLowerCase():
        docName = "Date de le radiation";
        break;
      case "RadiationDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "radiationRD".toLowerCase():
        docName = "Correspond au bon document";
        break;
      case "RadiationSCEGR".toLowerCase():
        docName = "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;

      //CorpPresence
      case "CorpPresenceDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "CorpPresenceDACC".toLowerCase():
        docName = "Le document appartient au clfient concerné";
        break;
      case "CorpPresenceDCV6".toLowerCase():
        docName = "Le document est en cours de validité < 6 MOIS";
        break;
      case "CorpPresenceDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "CorpPresenceRD".toLowerCase():
        docName = "Correspond au bon document";
        break;

      //RENA
      case "RENADM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "RENADACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "RENADL".toLowerCase():
        docName = "Document lisible";
        break;
      case "RENARD".toLowerCase():
        docName = "Correspond au bon document";
        break;
      case "RenaSCEGR".toLowerCase():
        docName = "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;

      //Retirement
      case "RetirementDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "RetirementDACC".toLowerCase():
        docName = "Le document appartient au client concerné";
        break;
      case "RetirementDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "retirementRD".toLowerCase():
        docName = "Correspond au bon document";
        break;
      case "RetirementSCEGR".toLowerCase():
        docName = "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;

      //Attestation des revenue militaires
      case "MilitaryIncomeCertificateDM".toLowerCase():
        docName = "Document non modifié";
        break;
      case "MilitaryIncomeCertificateSCIR".toLowerCase():
        docName = "le salaire est conforme à celui indiqué sur le relevé";
        break;
      case "MilitaryIncomeCertificateDL".toLowerCase():
        docName = "Document lisible";
        break;
      case "MilitaryIncomeCertificateRD".toLowerCase():
        docName = "Correspond au bon document";
        break;
      case "MilitaryIncomeCertificateSS25000".toLowerCase():
        docName =
          "Le salaire est supérieur au salaire minimum exigé pour le financement (25 000 DA)";
        break;
      case "MilitaryIncomeCertificateAEDM".toLowerCase():
        docName = "Le document est en cours de validité < 1 mois";
        break;
      case "MilitaryIncomeCertificateSCEGR".toLowerCase():
        docName = "Le document est signé et cacheté par l'employeur avec la griffe du responsable";
        break;
    }
    return docName;
  }
}
