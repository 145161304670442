import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {NotificationRole} from '../../../../../../core/ek-e-commerce/ek-models/NotificationRole';
import {
    NotificationSettingsService
} from '../../../../../../core/ek-e-commerce/ek-services/notification-settings.service';

@Component({
    selector: 'kt-notification-management-by-role',
    templateUrl: './notification-management-by-role.component.html',
    styleUrls: ['./notification-management-by-role.component.scss']
})
export class NotificationManagementByRoleComponent implements OnInit {

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '10rem',
        minHeight: '5rem',
        maxHeight: 'auto',
        width: '100%',
        minWidth: '0',
        translate: 'no',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Entrez le texte ici ...',
        defaultParagraphSeparator: '',
        defaultFontName: 'no',
        defaultFontSize: 'no',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['insertImage'],
            ['insertVideo']
        ]
    };
    notificationContent = '';
    currentRole = '';
    constructor(public dialogRef: MatDialogRef<NotificationManagementByRoleComponent>,
                private NotificationService: NotificationSettingsService,
                @Inject(MAT_DIALOG_DATA) public data: { notificationRow: NotificationRole }) {
    }

    ngOnInit(): void {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.notificationContent = this.data.notificationRow.notificationContent !== 'NONE'  ? this.data.notificationRow.notificationContent : '';
    }

    getLabelForKey(key: string): string {
        return key;
    }

    getFilteredKeys(obj: any): string[] {
        return Object.keys(obj).filter(key => key !== 'id' && key !== 'folderState' && key !== 'updatedAt' && key !== 'notificationContent' && key !== 'createdAt');
    }

    isChecked(roleKey: string, notificationType: string): boolean {
        return this.data.notificationRow[roleKey] === notificationType || this.data.notificationRow[roleKey] === 'EMAIL_SMS';
    }

    onNotificationChange(event: any, key: string, notificationType: string) {

        const currentType = this.data.notificationRow[key];

        if (event.checked) {

            if ((currentType === 'EMAIL' && notificationType === 'SMS') || (currentType === 'SMS' && notificationType === 'EMAIL')) {
                this.data.notificationRow[key] = 'EMAIL_SMS';
            } else {
                this.data.notificationRow[key] = notificationType;
            }

        } else {
            switch (currentType) {
                case 'EMAIL_SMS':
                    this.data.notificationRow[key] = notificationType === 'SMS' ? 'EMAIL' : 'SMS';
                    break;
                default:
                    this.data.notificationRow[key] = 'NONE';
                    break;
            }
        }
    }

    close() {
        this.dialogRef.close(false);
    }

    update() {
        this.data.notificationRow.notificationContent = this.notificationContent.replace(/<[^>]*>/g, '');
        this.NotificationService.update(this.data.notificationRow).subscribe({
            next: (res) => {
                this.dialogRef.close(true);
            }
        });
    }

}
