// Angular
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
// Material
import {MatDialog, MatSort, MatTable} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
// RXJS
import { map, tap} from 'rxjs/operators';
import { merge, Observable, Subscription} from 'rxjs';
// NGRX
import { Store} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// State
import {AppState} from '../../../../../../../../core/reducers';
// CRUD
import {
    LayoutUtilsService,
    MessageType,
    QueryParamsModel,
    TypesUtilsService
} from '../../../../../../../../core/_base/crud';
// Services and Models
import {ConfigurationCharacteristicsDatasource} from "../../../../../../../../core/ek-e-commerce/ek-data-sources/configuration-characteristics.datasource";
import {ConfigurationCharacteristicModel} from "../../../../../../../../core/ek-e-commerce/ek-models/configuration-characteristic.model";
import {
    ConfigurationCharacteristicCreatedSuccessfullyWithUpdater,
    ConfigurationCharacteristicCreatedWithUpdater,
    ConfigurationCharacteristicDeletedSaveUpdater, ConfigurationCharacteristicsPageLoadedSuccessfully,
    ConfigurationCharacteristicsPageRequested,
    ConfigurationCharacteristicUpdatedSaveUpdater, ConfigurationCharacteristicUpdatedSuccessfullySaveUpdater
} from "../../../../../../../../core/ek-e-commerce/ek-actions/configuration-characteristic.actions";
import {CharacteristicModel} from "../../../../../../../../core/ek-e-commerce/ek-models/characteristic.model";
import {
    CharacteristicsPageRequestedOfSearch
} from "../../../../../../../../core/ek-e-commerce/ek-actions/characteristic.actions";
import {NgxPermissionsService} from "ngx-permissions";
import {Actions, ofType} from "@ngrx/effects";
import {CharacteristicsService} from "../../../../../../../../core/ek-e-commerce/ek-services/characteristics.service";
import {ConfigurationCharacteristicsService} from "../../../../../../../../core/ek-e-commerce/ek-services/configuration-characteristics.service";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {startWith} from "rxjs/internal/operators/startWith";
@Component({
    selector: 'kt-configuration-characteristic',
    templateUrl: './configuration-characteristic.component.html',
    styleUrls: ['./configuration-characteristic.component.scss']
})
export class EkConfigurationCharacteristicComponent implements OnInit, OnDestroy {

    @Input() configurationId:number;

    characteristics: CharacteristicModel[] = [];
    // Table fields
    dataSource: ConfigurationCharacteristicsDatasource;
    dataSource_:ConfigurationCharacteristicsDatasource;
    displayedColumns = [ 'characteristic', 'valeur', 'actions'];
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('table', { static: true }) table: MatTable<ConfigurationCharacteristicModel>;
    // Filter fields
    //  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selection = new SelectionModel<ConfigurationCharacteristicModel>(true, []);
    configurationCharacteristicResut: ConfigurationCharacteristicModel[] = [];
    // Add and Edit
    isSwitchedToEditMode = false;
    loadingAfterSubmit = false;

    formGroup: FormGroup;
    confCharForAdd: ConfigurationCharacteristicModel;
    confCharForEdit: ConfigurationCharacteristicModel;
    filteredOptions: Observable<CharacteristicModel[]>;
    filteredOptionsEdit: Observable<CharacteristicModel[]>;

    characteristicFind:CharacteristicModel;
    characteristicFindEdit:CharacteristicModel;
    updater:string;
    // Private properties
    newCharacteristic:string;
    private componentSubscriptions: Subscription[] = [];
    private PERMISSIONS = ['ALL_PRODUCT', 'UPDATE_PRODUCT'];
    canEdit = false;
    dragDisabled = true;
    currentRole = '';
    /**
     * Component constructor
     *
     * @param store
     * @param fb
     * @param dialog
     * @param typesUtilsService
     * @param layoutUtilsService
     * @param ngxPermissionService
     * @param _actions$
     * @param CharacteristicsService
     * @param configurationCharacteristicsService
     */
    constructor(private store: Store<AppState>,
                private fb: FormBuilder,
                public dialog: MatDialog,
                public typesUtilsService: TypesUtilsService,
                private layoutUtilsService: LayoutUtilsService,
                private ngxPermissionService: NgxPermissionsService,
                private _actions$: Actions,
                private CharacteristicsService:CharacteristicsService,
                private configurationCharacteristicsService:ConfigurationCharacteristicsService,
    ) {
    }



    /**
     * On init
     */
    ngOnInit() {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        //getting characteristics
        this.getChars();
        this.confCharForAdd = new ConfigurationCharacteristicModel();
        this.confCharForEdit = new ConfigurationCharacteristicModel();

        // If the user changes the sort order, reset back to the first page.
        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        merge(this.sort.sortChange)
            .pipe(
                tap(() => {
                    this.loadConfCharsListFromService(this.configurationId);
                })
            )
            .subscribe();



        // Init DataSource
        this.dataSource = new ConfigurationCharacteristicsDatasource(this.store);
        this.dataSource.entitySubject.subscribe(res => this.configurationCharacteristicResut = res);

        this.createFormGroup();
        this.test();
        this.executedfilteredOptionsEdit();

    }
    test(){
        this.filteredOptions = this.formGroup.get('newCharacteristic').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.characteristics.slice())
            );
    }
    executedfilteredOptionsEdit(){
        this.filteredOptionsEdit = this.formGroup.get('editCharacteristic').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.characteristics.slice())
            );
    }

    getChars() {
        const queryParams = new QueryParamsModel(
            this.filterChar(),
            this.sort.direction,
            this.sort.active,
            0,
            100
        );
        this.store.dispatch(CharacteristicsPageRequestedOfSearch({page:queryParams}));
        this.CharacteristicsService.getAll().subscribe(res=>{
            this.characteristics = res;
        })

    }

    /**
     * Disable the form if the used doesnt have the permission to update
     */
    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            if (!hasPermission) {
                this.formGroup.disable();
                this.canEdit = false;
            } else
                this.canEdit = true;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach(sub => sub.unsubscribe());
        }
    }

    /**
     * Loading Remarks list
     */
    loadConfCharsList() {
        this.selection.clear();
        this.store.dispatch(ConfigurationCharacteristicsPageRequested({
            configurationId: this.configurationId
        }));

        // Init DataSource
        this._actions$.pipe(ofType(ConfigurationCharacteristicsPageLoadedSuccessfully)).subscribe((data: any) => {
            this.getChars();
            this.dataSource = new ConfigurationCharacteristicsDatasource(this.store);
            this.dataSource.entitySubject.subscribe(res => this.configurationCharacteristicResut = res);
        })

    }
    loadConfCharsListFromService(characteristicId){
        this.configurationCharacteristicsService.findByConfigurationId(characteristicId).subscribe(res => {
            this.getChars();
            this.loadConfCharsList();
            this.isSwitchedToEditMode = false;



        });
    }

    /**
     * Create Reactive Form
     * @param _item: remark
     */
    createFormGroup(_item = null) {
        // 'edit' prefix - for item editing
        // 'add' prefix - for item creation
        this.formGroup = this.fb.group({
            editValue: ['', Validators.compose([Validators.required])],
            editCharacteristic: ['', this.forbiddenCharacteristicValidator()],
            newValue: ['', Validators.compose([Validators.required])],
            newCharacteristic:['' , this.forbiddenCharacteristicValidator()],
        });
        this.clearAddForm();
        this.clearEditForm();
        this.checkPermissionToUpdate();
    }

    // ADD REMARK FUNCTIONS: clearAddForm | checkAddForm | addRemarkButtonOnClick | cancelAddButtonOnClick | saveNewRemark
    clearAddForm() {
        const controls = this.formGroup.controls;
        controls.newValue.setValue('');
        controls.newValue.markAsPristine();
        controls.newValue.markAsUntouched();
        controls.newCharacteristic.setValue('');
        controls.newCharacteristic.markAsPristine();
        controls.newCharacteristic.markAsUntouched();

        this.confCharForAdd.clear(this.configurationId);
        this.confCharForAdd._isEditMode = false;
    }

    /**
     * Check if Add Form is Valid
     */
    checkAddForm() {
        const controls = this.formGroup.controls;
        if (controls.newValue.invalid || controls.newCharacteristic.invalid) {
            controls.newValue.markAsTouched();
            // controls['newType'].markAsTouched();
            // controls.newDueDate.markAsTouched();
            return false;
        }

        return true;
    }

    /**
     * Open ConfChar Add Form
     */
    addConfCharButtonOnClick() {
        this.clearAddForm();
        this.confCharForAdd._isEditMode = true;
        this.isSwitchedToEditMode = true;
    }

    /**
     * Close ConfChar Add Form
     */
    cancelAddButtonOnClick() {
        this.confCharForAdd._isEditMode = false;
        this.isSwitchedToEditMode = false;
    }

    /**
     *  Create new confChar
     */
    ngOnChanges(changes: SimpleChanges) {
        if(changes) {
            this.loadConfCharsList();
        }
    }
    saveNewConfChar() {
        if (!this.checkAddForm()) {
            return;
        }
        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        this.confCharForAdd._isEditMode = false;
        this.confCharForAdd.value = controls.newValue.value;
        this.characteristics.find(c=>{
            c.name = this.characteristicFind.name;
            this.confCharForAdd.characteristicId = c.id;
        });
        this.confCharForAdd.characteristicId = this.characteristicFind.id;
        this.confCharForAdd.configurationId = this.configurationId;
        this.test();
        controls.newCharacteristic.setValue('');
        this.store.dispatch(ConfigurationCharacteristicCreatedWithUpdater({configurationCharacteristic: this.confCharForAdd,updater:this.updater}));
        this._actions$.pipe(ofType(ConfigurationCharacteristicCreatedSuccessfullyWithUpdater)).subscribe((data: any) => {

            this.loadConfCharsListFromService(this.configurationId);
            const _saveMessage = `Characteristic has been created`;
            this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Create, 10000, true, true);
        });

    }

    // EDIT REMARK FUNCTIONS: clearEditForm | checkEditForm | editRemarkButtonOnClick | cancelEditButtonOnClick |
    clearEditForm() {
        const controls = this.formGroup.controls;
        controls.editValue.setValue('');
        controls.editCharacteristic.setValue('');
        this.confCharForEdit = new ConfigurationCharacteristicModel();
        this.confCharForEdit.clear(this.configurationId);
        this.confCharForEdit._isEditMode = false;
    }

    /**
     * Check is Edit Form valid
     */
    checkEditForm() {
        const controls = this.formGroup.controls;
        if (controls.editValue.invalid || controls.editCharacteristic.invalid) {
            controls['editValue'].markAsTouched();
            controls['editCharacteristic'].markAsTouched();
            return false;
        }

        return true;
    }

    /**
     * Update remark
     *
     * @param _item: ConfChar
     */
    editConfCharButtonOnClick(_item: ConfigurationCharacteristicModel) {
        const controls = this.formGroup.controls;
        controls.editValue.setValue(_item.value);
        this.characteristicFindEdit=  this.characteristics.find(x => x.id == _item.characteristicId);
        controls.editCharacteristic.setValue(this.characteristicFindEdit.name);
        _item._isEditMode = true;
         this.isSwitchedToEditMode = true;
    }

    /**
     * Cancel confChar
     *
     * @param _item: confCharModel
     */
    cancelEditButtonOnClick(_item: ConfigurationCharacteristicModel) {
        _item._isEditMode = false;
        this.isSwitchedToEditMode = false;
    }

    /**
     * Save confChar
     *
     * @param _item: ConfCharModel
     */
    saveUpdatedConfChar(_item: ConfigurationCharacteristicModel) {
        if (!this.checkEditForm()) {
            return;
        }

        this.loadingAfterSubmit = true;
        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        const objectForUpdate = new ConfigurationCharacteristicModel();
        objectForUpdate.id = _item.id;
        objectForUpdate.configurationId = _item.configurationId;
        objectForUpdate._isEditMode = _item._isEditMode;
        objectForUpdate.value = controls.editValue.value;
        this.characteristicFindEdit=  this.characteristics.find(x => x.name == controls.editCharacteristic.value);

        objectForUpdate.characteristicId= this.characteristicFindEdit.id;
        objectForUpdate._isEditMode = false;
        const updateConfChar: Update<ConfigurationCharacteristicModel> = {
            id: _item.id,
            changes: objectForUpdate
        };

        this.store.dispatch(ConfigurationCharacteristicUpdatedSaveUpdater({
            partialConfigurationCharacteristic: updateConfChar,
            configurationCharacteristic: objectForUpdate,
            updater:this.updater
        }));
        this._actions$.pipe(ofType(ConfigurationCharacteristicUpdatedSuccessfullySaveUpdater)).subscribe((data: any) => {

            this.loadConfCharsListFromService(this.configurationId);
        });
        const saveMessage = `Characteristic has been updated`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, true);
    }

    /**
     * Returns object for filtration
     */
    filterChar(): any {
        const filter: any = {};
        //const searchText: string = this.searchInput.nativeElement.value;

        filter.text = "";
        return filter;
    }

    alreadyExist(id: number): boolean {
        const res = this.configurationCharacteristicResut.filter(conf => conf.characteristicId == id)[0];
        return !!res;
    }

    /**
     * Check all rows are selected
     */
    isAllSelected() {
        // const numSelected = this.selection.selected.length;
        // const numRows = this.productRemarksResult.length;
        // return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection
     */
    masterToggle() {
        // if (this.isAllSelected()) {
        // 	this.selection.clear();
        // } else {
        // 	this.productRemarksResult.forEach(row => this.selection.select(row));
        // }
    }

    /** ACTIONS */
    /**
     * Delete remark
     *
     * @param _item: ProductRemarkModel
     */
    deleteConfChar(_item: ConfigurationCharacteristicModel) {
        const _title = 'Characteristic Delete';
        const _description = 'Are you sure to permanently delete this characteristic?';
        const _waitDesciption = 'Characteristic is deleting...';
        const _deleteMessage = `Characteristic has been deleted`;

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(ConfigurationCharacteristicDeletedSaveUpdater({configurationCharacteristicId: _item.id,updater:this.updater}));
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        });
    }



    /* UI **/
    /**
     * Returns type in string
     *
     * @param _confChar: ConfCharModel
     */
    getTypeStr(_confChar: ConfigurationCharacteristicModel): string {
        if(!_confChar._isEditMode) {
            while (this.characteristics.length > 0) {
                const char = this.characteristics.filter(char => char.id == _confChar.characteristicId)[0];
                return char.name
            }
        }else{
            return "";
        }
    }
    drop(event: CdkDragDrop<ConfigurationCharacteristicModel[]>) {
        this.dragDisabled = true;

        const currentValue = this.dataSource.entitySubject.getValue();
        //
        if (event.currentIndex == event.previousIndex && currentValue[event.previousIndex].ord != null) {

            const message = `you chose the same order !`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);


        }
        else{
            if(this.configurationId&&this.configurationId>0){
                this.OnChangeOrderConfigurationCharacteristic(currentValue[event.previousIndex].id, event.currentIndex, event.previousIndex);
                this.table.renderRows();

            }else {
            }
        }
        // const previousIndex = this.dataSource.entitySubject.getValue();

        // moveItemInArray(this.dataSource, previousIndex, event.currentIndex);
    }
    OnChangeOrderConfigurationCharacteristic(id: number, value: number, position: number) {

        this.configurationCharacteristicsService.changeOrderConfigurationCharacteristic(id, value, position).subscribe(
            res => {
                this.loadConfCharsListFromService(this.configurationId);
                //this.store.dispatch(ConfigurationsPageRequested({productId: this.configuration.productId}));


            });

        const message = `Characteristic order successfully has been changed.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);


    }

    private _filter(name: any): CharacteristicModel[] {

        const filterValue = name.toLowerCase();

        return this.characteristics.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    getCharacteristicSelected(event) {
        this.formGroup.get('newCharacteristic').setValue('')

        if(this.formGroup.controls.newCharacteristic.dirty){
            this.formGroup.controls.newCharacteristic.enable();
            //this.isEditable = false;
            this.characteristicFind = this.characteristics.find(char=>char.id==event.option.value);
            const controls = this.formGroup.controls;
            controls.newCharacteristic.setValue(this.characteristicFind.name);
            this.confCharForAdd.id = this.characteristicFind.id ;


        }




    }
    getCharacteristicSelectedEdit(event) {
        this.formGroup.get('editCharacteristic').setValue('')

        if(this.formGroup.controls.editCharacteristic.dirty){
            this.formGroup.controls.editCharacteristic.enable();
            //this.isEditable = false;
            this.characteristicFindEdit = this.characteristics.find(char=>char.id==event.option.value);
            const controls = this.formGroup.controls;
            controls.editCharacteristic.setValue(this.characteristicFindEdit.name);
            this.confCharForEdit.id = this.characteristicFindEdit.id ;


        }




    }
    forbiddenCharacteristicValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            // below findIndex will check if control.value is equal to one of our options or not

            const index = this.characteristics.findIndex(char => char.name === control.value);
            return index < 0 ? { 'forbiddenCharacteristic': { value: control.value } } : null;
        }}

}

