
// Models and Consts
export {CustomerModel} from './_models/customer.model';
export {ProductRemarkModel} from '../ek-e-commerce/ek-models/product-remark.model';
export {ProductSpecificationModel} from './_models/product-specification.model';
export {ProductModel} from '../ek-e-commerce/ek-models/product.model';
export {PointRelaitModel} from './_models/point-relait.model';
export {CommentModel} from './_models/comment.model';
export {AttachmentsModel} from './_models/attachments.model';
export {Discount_CustomerModel} from './_models/discount_Customer.model';
export {DiscountModel} from'./_models/discount.model'

// DataSources
export {CustomersDataSource} from './_data-sources/customers.datasource';
export {ProductRemarksDataSource} from './_data-sources/product-remarks.datasource';
export {CommentDatasource} from './_data-sources/comment.datasource';
export {ProductBestSellerDataSource} from './_data-sources/products-best-seller.datasource';

// Actions
import * as CustomerDiscountAction from './_actions/customer-discount.action';
import * as CustomerActions from './_actions/customer.actions';
import * as CustomerAddressesActions from './_actions/customer-address.actions';
import * as CategoryActions from '../ek-e-commerce/ek-actions/category.action';
import * as CharacteristicActions from '../ek-e-commerce/ek-actions/characteristic.actions';
import * as OrderActions from './_actions/order.action';
import * as BestSellerActions from './_actions/bestSeller.action';
import * as NewArrivalActions from './_actions/newArrival.action';
import * as BestSellsActions from './_actions/bestSells.action';
import * as PromoCodeActions from './_actions/promoCode.action';
import * as CollaboratorActions from './_actions/collaborator.action';
import * as Commentactions from './_actions/comment.action';
import * as CategoryPopularActions from './_actions/category-popular.action';


export {
    Commentactions,
    CustomerDiscountAction,
    CustomerActions,
    CustomerAddressesActions,
    CategoryActions,
    PromoCodeActions,
    CharacteristicActions,
    OrderActions,
    BestSellerActions,
    NewArrivalActions,
    BestSellsActions,
    CollaboratorActions,
    CategoryPopularActions
};


// Effects
export {CustomerEffects} from './_effects/customer.effects';
export {DiscountCustomerEffects} from './_effects/discountCustomer.effects';
export {PromoCodeEffects} from './_effects/promoCode.effects';
export {CollaboratorEffects} from './_effects/collaborator.effects';
export {BestSellerEffects} from './_effects/bestSeller.effects';
export {NewArrivalEffects} from './_effects/newArrival.effects';
export {BestSellsEffects} from './_effects/bestSells.effects';
export {CommentEffects} from './_effects/comment.effects';
export {ProductsBestSellerEffects} from './_effects/products-best-seller.effects';

export {DiscountEffect} from './_effects/discount.effect';
export {CategoryPopularEffects} from './_effects/category-popular.effects';

// Reducers
export {commentReducer1} from './_reducers/comment.reducers';
export {customersReducer} from './_reducers/customer.reducers';
export {customerDiscountReducers} from './_reducers/CustomerDiscount.reducers';

export {customerAddressesReducer} from './_reducers/customer-address.reducers';
export {categoriesReducer} from '../ek-e-commerce/ek-reducers/category.reducers';
export {promoCodesReducer} from './_reducers/promoCode.reducers';

export {bestSellersReducer} from './_reducers/bestSeller.reducers'
export {newArrivalsReducer} from './_reducers/newArrival.reducers';
export {bestSellsReducer} from './_reducers/bestSells.reducer';
export {collaboratorsReducer} from './_reducers/collaborator.reducers';
export {trendingConfigurationsReducer} from './_reducers/product-trending.reducer';
export {bestSellerConfigurationsReducer} from './_reducers/products-best-seller.reducer';
export {discountsReducer} from './_reducers/discount.reducer';
export {categoriesPopularReducer} from './_reducers/category-popular.reducers';

// Selectors

// Comment selectors =>
export {
    selectCommentById,
    selectCommentsInStore,
    selectCommentsPageLoading,
    selectLastCreatedCommentId,
    selectCommentsActionLoading,
    selectCommentsShowInitWaitingMessage,
    selectCommentProgress,
    selectCommentsPageLastQuery,
} from './_selectors/comment.selectors';
// Customer selectors =>
export {
    selectCustomersByStatus,
    selectCustomerById,
    selectCustomersInStore,
    selectCustomersPageLoading,
    selectLastCreatedCustomerId,
    selectCustomersActionLoading,
    selectCustomersShowInitWaitingMessage
} from './_selectors/customer.selectors';
// CustomerDiscount selectors =>
export {
    selectDiscontCustomerById,
    selectDiscontCustomerInStore,
    selectDiscontCustomerPageLoading,
    selectLastCreatedDiscontCustomerId,
    selectDiscontCustomerActionLoading,
    selectDiscontCustomerShowInitWaitingMessage
} from './_selectors/CustomerDiscount.selector';
// Discount selectors =>
export {
    selectDiscountById,
    selectDiscountInStore,
    selectDiscountPageLoading,
    selectError,
    selectlastAction,
    selectLastCreatedDiscountId,
    selectDiscountsActionLoading,
    selectDiscountsShowInitWaitingMessage,

} from './_selectors/discount.selectors';

// PromoCode selectors
export {
    selectPromoCodeById,
    selectPromoCodesInStore,
    selectPromoCodesPageLoading,
    selectPromoCodesActionLoading,
    selectLastCreatedPromoCodeId,
    selectPromoCodesState,
    selectPromoCodesShowInitWaitingMessage
} from './_selectors/promoCode.selector';

// Collaborator  selectors
export {
    selectCollaboratorById,
    selectCollaboratorsInStore,
    selectCollaboratorsPageLoading,
    selectCollaboratorsActionLoading,
    selectLastCreatedCollaboratorId,
    selectCollaboratorsState,
    selectCollaboratorShowInitWaitingMessage
} from './_selectors/collaborator.selectors';
// ProductRemark selectors =>
export {
    selectProductRemarkById,
    selectProductRemarksInStore,
    selectProductRemarksPageLoading,
    selectCurrentProductIdInStoreForProductRemarks,
    selectLastCreatedProductRemarkId,
    selectPRShowInitWaitingMessage
} from './_selectors/product-remark.selectors';
// ProductSpecification selectors =>

export {selectLastCreatedBestSellerId} from './_selectors/bestSeller.selectors';
export {selectLastCreatedNewArrivalId} from './_selectors/newArrival.selectors';
export {selectCategoriesPopularInStore} from './_selectors/category-popular.selector';


export {
    selectTrendingConfigurationsState,
    selectTrendingInitWaitingMessage,
    selectTrendingInStore,
    selectTrendingPageLoading
}
from './_selectors/product-trending.selector'
export {
    selectBestSellerConfigurationsState,
    selectBestSellerInitWaitingMessage,
    selectBestSellerInStore,
    selectBestSellerPageLoading
}
from './_selectors/products-best-seller.selector'
// Services
export {CustomersService} from '../ek-e-commerce/ek-services/customers.service';
export {ProductsService} from './_services/products.service';
export {PointRelaitService} from './_services/point-relait.service';
export {PhoneNumberService} from './_services/phone-number.service';
export {FooterInformationService} from './_services/footer-information.service';
export {QuestionAnswerService} from '../ek-e-commerce/ek-services/question-answer.service';
export {NotificationService} from '../ek-e-commerce/ek-services/notification.service';
export {ArchivedConfigurationService} from './_services/archived-configuration.service';
export {PromoCodeService} from './_services/promoCode.service';
export {CollaboratorService} from './_services/collaborator.service';
export {CommentService} from './_services/comment.service';
export {ConfigurationService} from '../ek-e-commerce/ek-services/confguration.service';
export {CustomerDiscountService} from './_services/customer-discount.service';
export {DiscountsService} from './_services/discounts.service';
export {CategoriesService} from '../ek-e-commerce/ek-services/categories.service';
