import {BaseModel} from '../../_base/crud';
import {DiscountModel} from '../../e-commerce';
import {ImageModel} from './image.model';
import {ConfigurationCharacteristicModel} from './configuration-characteristic.model';

export class ConfigurationModel extends BaseModel {
    id: number;
    price: number;
    state: string;
    discount: DiscountModel;
    images: ImageModel[];
    productId: number;
    productName:string;
    name: string;
    buyingPrice: number;
    _isEditMode: boolean;
    updater: string;
    active:boolean;
    description: string;
    productCharacteristics: ConfigurationCharacteristicModel[];
    availabilityDate: string;
    display: boolean;
    productBrand: string;
    categoryLabel :string;
    reference:string;
    installLink:string;
    certificateUrl:string;

    clear() {

        this._isEditMode = false;

    }
}
