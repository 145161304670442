// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
// Auth
import {AuthGuard} from './core/auth';
import {ErrorPageComponent} from "./views/pages/error-page/error-page.component";
import {NgxPermissionsGuard} from "ngx-permissions";
import {FoldersListComponent} from "./views/pages/apps/ek-e-commerce/folders/folders-list/folders-list.component";
import {EkUserManagementModule} from "./views/pages/ek-user-management/ek-user-management.module";
import {OrderStatisticsComponent} from "./views/pages/apps/ek-e-commerce/order-statistics/order-statistics.component";
import {
	NotificationSettingsComponent
} from "./views/pages/apps/ek-e-commerce/notification-settings/notification-settings.component";

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				canActivate: [NgxPermissionsGuard],
				redirectTo: '/statistics',
				pathMatch: 'full'
			},
			// Orders Statistics
			{
				path: 'statistics',
				component: OrderStatisticsComponent,
				canActivate: [NgxPermissionsGuard]
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'ecommerce',
				loadChildren: () => import('./views/pages/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
			},
			{
				path: 'ek-ecommerce',
				loadChildren: () => import('./views/pages/apps/ek-e-commerce/ek-e-commerce.module').then(m => m.EkECommerceModule),
			},
			{
				path: 'ek-user-management',
				loadChildren: () => import('./views/pages/ek-user-management/ek-user-management.module').then(m => m.EkUserManagementModule),
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: ['ALL_WSUSER','READ_WSUSER'],
						redirectTo: '/error/403'
					}
				}
			},
			{
				path: 'error/403',
				component: OrderStatisticsComponent,


			},

			{
				path: 'error/404',
				component: OrderStatisticsComponent,


			},


			{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'error/404', pathMatch: 'full'},
		],
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
