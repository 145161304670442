import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'scoringExceptionsInterpret'
})
export class ScoringExceptionsInterpretPipe implements PipeTransform {

    transform(exceptionMsg: string): string {

        let interpretation = exceptionMsg;

        switch (exceptionMsg) {

            case 'Folder not found with ID: idFolder':
                interpretation = 'Dossier Client Non Trouvé'
                break;

            case 'User not found with Email/Username:  user/email':
                interpretation = 'Email Client Introuvable'
                break;

            case 'User with " Role" can not do this operation ':
                interpretation = 'Utilisateur Non-Autorisé A Effectuer Cette Opération'
                break;

            case 'Credit Analyst " CreditAnalyste email/user "not found with Email/Username: ':
                interpretation = 'Email/Utilisateur Credit Analyst Introuvable'
                break;

            case 'ClientInformation not found with Folder ID:  + idFolder':
                interpretation = 'Information Client Introuvable Avec ce ID Dossier'
                break;

            case 'Can not calculate ProfessionalSituation points because RecruitmentDate is null or empty':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Date de Recrutement Nulle ou Vide'
                break;

            case 'Can not calculate ProfessionalSituation points because OtherCreditAmount is null':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Autre Crédit Nul'
                break;

            case 'Can not calculate Identification points because Age is null':
                interpretation = 'Calcul Non Effectué-Informations Personnelles-Date de Naissance Nulle'
                break;

            case 'Can not calculate Identification points because FamilyState is null':
                interpretation = 'Calcul Non Effectué-Informations Personnelles-Situation Familiale Nulle'
                break;

            case 'Can not calculate Identification points because OtherPeopleDepending is null':
                interpretation = 'Calcul Non Effectué-Informations Personnelles-Enfants à Charge Null'
                break;

            case 'Can not calculate Identification points because Habitation is null':
                interpretation = 'Calcul Non Effectué-Informations Personnelles-Type d\'Habitation Null'
                break;

            case 'Can not calculate Instruction points because EducationLevel is null':
                interpretation = 'Calcul Non Effectué-Informations Personnelles-Niveau d\'Instruction Null'
                break;

            case 'Can not calculate ProfessionalSituation points because RecruitmentDate is null or empty':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Date de recrutement Nulle ou Vide'
                break;

            case 'Can not calculate ProfessionalSituation points because ActivityDomain is null':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Secteur d\'Activité Null'
                break;

            case 'Can not calculate ProfessionalSituation points because ContractType is null':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Type de Contrat Null'
                break;

            case 'Can not calculate ProfessionalSituation points because ClientType is null':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Situation Professionnelle Client Null'
                break;

            case 'Can not calculate ProfessionalSituation points because Salary is null':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Salaire net Null'
                break;

            case 'Can not calculate ProfessionalSituation points because netSalaryRENA is null':
                interpretation = 'Calcul Non Effectué-Situation Professionnelle-Pension de retraite  Nulle'
                break;


            case 'Can not calculate ProfessionalSituation points because OtherIncome is null':
                interpretation = 'Calcul Non Effectué-Situation Financière-Banque Autre revenus Nulle'
                break;


            case 'Can not calculate ProfessionalSituation points because CommitmentToColleagues is null':
                interpretation = 'Calcul Non Effectué-Situation Financière-Credit Autre Nul'
                break;

            case 'Invalid date format. Expected format: yyyy-MM-dd':
                interpretation = 'Format de Date Invalide'
                break;
        }

        return interpretation;
    }


}
