import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfigurationModel} from "../../../../../../core/ek-e-commerce/ek-models/configuration.model";
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {ClientsDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/clients.datasource";
import {SelectionModel} from "@angular/cdk/collections";
import {ClientModel} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {EkWilayaService, Wilaya} from "../../../../../../core/ek-e-commerce/ek-services/ek-wilaya.service";
import {fromEvent, merge, Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {ClipboardService} from "ngx-clipboard";
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {QueryParamsModel} from "../../../../../../core/_base/crud";
import {
  clientsOrdersPageRequested,
  clientsPageRequested
} from "../../../../../../core/ek-e-commerce/ek-actions/client.actions";
import {ClientsService} from "../../../../../../core/ek-e-commerce/ek-services/clients.service";

@Component({
  selector: 'kt-products-characteristics-dialog',
  templateUrl: './products-characteristics-dialog.component.html',
  styleUrls: ['./products-characteristics-dialog.component.scss']
})
export class EkProductsCharacteristicsDialogComponent implements OnInit {

  viewLoading$ = null;
  public OrdersTitle: String;
  loadingUpdatingState = false;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  filterByDateActivated = false;
  showDatePicker = true;
  loadingData = false;
  criteria: string = '';
  //mat table
  dataSource: ClientsDatasource;
  selection = new SelectionModel<ClientModel>(true, []);
  clientsResult: ClientModel[] = [];
  displayedColumns: string[] = [ 'ID', 'Prenom', 'Nom', 'creationDate', 'ClientStatus'];

  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  wilayas: Wilaya[];
  isclientOrdresPage: boolean = false;
  private subscriptions: Subscription[] = [];

  fileShippingList = [
    {name: 'Toutes', value: ''},
    {name: 'Point de Vente', value: 'POINT_OF_SALE'},
    {name: 'Yalidine', value: 'RELAY_POINT'},
    {name: 'A Domicile', value: 'RESIDENCE'}
  ]
  selectedState = new FormControl();
  currentRole : string = '';
  constructor(public formatter: NgbDateParserFormatter,
              private calendar: NgbCalendar,
              private store: Store<AppState>,
              private clipboardApi: ClipboardService,
              private activatedRoute: ActivatedRoute,
              private wilayaService: EkWilayaService,
              private router: Router,
              private dialogRef: MatDialogRef<EkProductsCharacteristicsDialogComponent>,
              private clientsService: ClientsService,
              @Inject(MAT_DIALOG_DATA) public data: ConfigurationModel
  ) {
    this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
  }

  ngOnInit(): void {

    this.clientsService.getClientsWithSameOrderedProduct(0, 100, 'desc',this.filterConfiguration().criteria, this.data.id).subscribe((res) => {
      console.log('hey', res);
    })

    this.OrdersTitle = 'Choisissez un client';

    this.getAllPaged();

    this.paginator._changePageSize(100);

   
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    this.dataSource = new ClientsDatasource(this.store);
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        debounceTime(700), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.onSearch(this.searchInput.nativeElement.value);
      })).subscribe();
    this.subscriptions.push(searchSubscription);


    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => {
          this.getAllPaged();
        })
    ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    this.fieldListener();

  }

  fieldListener() {
  }


  filterConfiguration(): any {
    return {
        criteria: this.criteria 
    };
}

onSearch(searchTerm: string): void {
  this.criteria = searchTerm; 
  this.store.dispatch(clientsOrdersPageRequested({
    page: new QueryParamsModel(
      searchTerm,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    ),
    productId: this.data.id
  }));
}


  getAllPaged() {
    const filterValues = this.filterConfiguration(); 

    const queryParams = new QueryParamsModel(
        filterValues.criteria,
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.criteria
    );

    this.store.dispatch(clientsOrdersPageRequested({
      page: queryParams,
      productId:this.data.id
    }));
  }

  formatDate(date: NgbDate) {
    var month = '' + (date.month),
        day = '' + date.day,
        year = date.year;
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.clientsResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.clientsResult.forEach(row => this.selection.select(row));
    }
  }
  close() {
    this.dialogRef.close();
  }

  navigateTo(client) {
    this.dialogRef.close();
    this.router.navigateByUrl('ek-ecommerce/folder/add/'+ client.id + '?step=1');
  }

  getItemCssClassByBlocked(clientStatus): string {
    switch (clientStatus) {
      case 'BLOCKED':
        return 'danger ';
      case 'ACTIVE' :
        return 'succes';
      case 'WAITING':
        return 'metal';
    }
    return '';
  }

  getItemBlockedString(clientStatus): string {
    switch (clientStatus) {
      case 'BLOCKED':
        return 'Bloqué';
      case 'ACTIVE':
        return 'Activé';
      case 'WAITING':
        return 'En attente';
    }
    return '';
  }
}
