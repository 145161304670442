import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectFoldersInStore,
    selectFoldersPageLoading,
    selectFoldersShowInitWaitingMessage
} from "../ek-selectors/folder.selector";

export class FoldersDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectFoldersPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectFoldersShowInitWaitingMessage)
        );


        this.store.pipe(
            select(selectFoldersInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
            this.hasItems = response.items.length > 0;
        });
    }

}