import {BaseModel} from '../../_base/crud';

export class ImageModel extends BaseModel {
    id: number;
    configurationId: number;
    file: File;
    url: string;
    ord: number;

    _isEditMode: boolean;

    clear() {
        this.url = '';
        this._isEditMode = false;
    }
}
