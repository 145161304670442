import { BaseModel } from '../../_base/crud';
import {Address} from "../../ek-e-commerce/ek-models/address.model";

export enum AuthProvider {
	Local = 'local',
	Facebook = 'facebook',
	Google = 'google',
	Github = 'github'
}
export enum CustomerType {
	ENTREPRISE='ENTREPRISE',
	PARTICULIER='PARTICULIER'
}
export class CustomerModel  extends BaseModel {
	id: number;
	firstname: string;
	lastname: string;
	email: string;
	username: string;
	gender: string;
	state: number; // 0 = Active | 1 = Suspended | Pending = 2
	type: CustomerType;
	phone: string;
	activity: string;
	registerNumber: string;
	articleNumber: string;
	taxNumber: string;
	addresses: Address[];
	password: string;
	enabled: boolean;
	provider: AuthProvider.Local;
	profileImgUrl: string;
	remark: string;
	source:string;
	ordersCount : number;
	clientStatus:string; // 0:BLOCKED | 1:ACTIVE | 2:WAITING
	reasonForBlocking:string;

	clear() {
		this.firstname = '';
		this.lastname = '';
		this.email = '';
		this.username = '';
		this.gender = '';
		this.type = null;
		this.state = 0;
		this.phone = '';
		this.activity = '';
		this.registerNumber = undefined;
		this.articleNumber = undefined;
		this.taxNumber = undefined;
		this.addresses = [];
		this.password = '';
		this.enabled = false;
		this.provider = AuthProvider.Local;
		this.profileImgUrl = '';
		this.clientStatus= 'ACTIVE';
		this.reasonForBlocking="";
	}
}
