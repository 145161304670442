import {createAction, props} from "@ngrx/store";
import {Update} from "@ngrx/entity";
import {BrandModel} from "../ek-models/brand.model";


export const BrandsPageRequested = createAction('[Brand List Page] Brands Page Requested ',props<{page: number, size: number}>());
export const BrandsPageLoadedSuccessfully = createAction('[Brand API] Brands Page Loaded Successfully ',props<{brands: BrandModel[],totalCount: number, page: number}>());
export const BrandsPageLoadFailed = createAction('[Brand API] Brands Page Request Failed ',props<{error: any}>());

export const BrandCreated = createAction('[Brand List Page] Brand Created ',props<{brand: BrandModel}>());
export const BrandCreatedSuccessfully = createAction('[Brand List Page] Brand Created Successfully ',props<{brand: BrandModel}>());
export const BrandCreationFailed = createAction('[Brand List Page] Brand Creation Failed ',props<{error: any}>());

export const BrandUpdated = createAction('[Brand List Page] Brand Updated ',props<{brand: BrandModel, partialBrand: Update<BrandModel>}>());
export const BrandUpdatedSuccessfully = createAction('[Brand List Page] Brand Updated Successfully ',props<{brand: BrandModel, partialBrand: Update<BrandModel>}>());
export const BrandUpdateFailed = createAction('[Brand List Page] Brand Update Failed ',props<{error: any}>());

export const BrandDeleted = createAction('[Brand List Page] Brand Deleted ',props<{brandId: number}>());
export const BrandDeletedSuccessfully = createAction('[Brand List Page] Brand deleted Successfully ',props<{brandId: number}>());
export const BrandDeleteFailed = createAction('[Brand List Page] Brand delete Failed ',props<{error: any}>());


