import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectCharacteristicsInStore,
    selectCharacteristicsPageLoading,
    selectCharacteristicsShowInitWaitingMessage
} from "../ek-selectors/characteristic.selector";

//todo
export class CharacteristicsDatasource extends BaseDataSource{
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectCharacteristicsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectCharacteristicsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectCharacteristicsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }

}
