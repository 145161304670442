// Angular
import {Component, Input, OnInit} from '@angular/core';
// RxJS
import {Observable, of} from 'rxjs';
// NGRX
import {Store} from '@ngrx/store';
// State
import {AppState} from '../../../../../core/reducers';
import {AuthActions, currentUser, User} from "../../../../../core/auth";
import {UserService} from "../../../../../core/services/user.service";
import {NotificationService} from "../../../../../core/e-commerce";
import {ConnectedPosition} from "@angular/cdk/overlay";
import {RolesTable} from "../../../../../core/auth/_server/roles.table";

@Component({
    selector: 'kt-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']

})
export class UserProfileComponent implements OnInit {
    // Public properties
    user$: Observable<User>;
    id: number;
    totalNotOpenedNotifications: number;
    show: boolean = false;
    @Input() avatar = true;
    @Input() greeting = true;
    @Input() badge: boolean;
    @Input() icon: boolean;

    positions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom',
        },
    ];

    /**
     * Component constructor
     *
     * @param store: Store<AppState>
     */
    constructor(private store: Store<AppState>, private userService: UserService, private notificationService: NotificationService) {
    }


    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        const user: User = JSON.parse(localStorage.getItem('currentUser'));
        this.user$ = of(user);
        this.userService.getUserByUsername(user.username).subscribe(res => {
            this.id = res.id;
        });
        this.userService._userSubject$.next(this.id);
        this.notificationService.totalNotOpened.subscribe(res => {
            this.totalNotOpenedNotifications = res;
        });
    }

    showMenu = false;
    menuItems = ['Item 1', 'Item 2', 'Item 3'];

    closeDropDown() {
        this.show = !this.show;
    }

    translateRole(role) {
        return RolesTable.roles.find(item => item.id === role).title;
    }

    /**
     * Log out
     */
    logout() {
        this.store.dispatch(AuthActions.logout());
    }
}
