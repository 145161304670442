import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {ImageModel} from "../ek-models/image.model";
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable, of, throwError} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map, mergeMap} from "rxjs/operators";


@Injectable()
export class ImagesService extends AbstractService<ImageModel> {

    url: string;
    baseUrl = 'https://gocyubtzwf.execute-api.eu-west-3.amazonaws.com/prod';

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}images`;
    }

    pushFileToStorage(image: ImageModel): Observable<HttpEvent<{

        body: {
            body: {
                url: string,
                id: number,
                configurationId: number,

            }
        }
    }>> {

        const data: FormData = new FormData();
        data.append('file', image.file);
        data.append('configurationId', image.configurationId.toString());
        const newRequest = new HttpRequest('POST', this.url, data, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(newRequest);
    }
    pushFileToStorageWithUpdater(image: ImageModel,updater:string): Observable<HttpEvent<{

        body: {
            body: {
                url: string,
                id: number,
                configurationId: number,

            }
        }
    }>> {
        const url = `${this.url}/saveWithUpdater/${updater}`;
        const data: FormData = new FormData();
        data.append('file', image.file);
        data.append('configurationId', image.configurationId.toString());

        const newRequest = new HttpRequest('POST', url, data, {
            reportProgress: true,
            responseType: 'json',


        });
        return this.http.request(newRequest);
    }

    public changeOrderImage(id: number, order: number, position: number): Observable<Response<ImageModel>> {
        const url = `${this.url}/${id}/${order}/${position}`;
        return this.http.patch<Response<ImageModel>>(url, "").pipe(
            map((response: Response<ImageModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    // uploadImage(fileName: string, image: string | ArrayBuffer): Observable<any> {
    //     const url = `${this.url}/uploadImage`
    //     return this.http.post<any>(url, {fileName, image});
    // }

    uploadImage(file : File) {
        const formData = new FormData();
        formData.append('file', file);
        const url = `${this.url}/uploadImage`
        return this.http.post(url, formData)
    }


    fetchAndUploadImage(url: string, fileName: string) {
        return this.http.post<any>(this.baseUrl + '/fetchupload-image', {'image_url': url, fileName});
    }

    public deleteImage(id: number,updater:string): Observable<Response<any>> {
        const url = `${this.url}/${id}/${updater}`;
            return this.http.patch<Response<ImageModel>>(url, "").pipe(
                mergeMap(result => result.code == 0 ?
                    of(result): throwError(result))
            )
    }


}
