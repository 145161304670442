// Angular
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// Material
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
// RxJS
import {fromEvent, merge, Subscription} from 'rxjs';
// Services and Models
import * as CharacteristicActions from '../../../../../../../../core/ek-e-commerce/ek-actions/characteristic.actions';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {SelectionModel} from "@angular/cdk/collections";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../../../core/reducers";
import {LayoutUtilsService, MessageType, QueryParamsModel} from "../../../../../../../../core/_base/crud";
import {Update} from "@ngrx/entity";
import {CharacteristicsDatasource} from "../../../../../../../../core/ek-e-commerce/ek-data-sources/characteristics.datasource";
import {CharacteristicModel} from "../../../../../../../../core/ek-e-commerce/ek-models/characteristic.model";
import {selectLastCreatedCharacteristicId} from "../../../../../../../../core/ek-e-commerce/ek-selectors/characteristic.selector";
import {CharacteristicsPageRequestedOfSearch} from "../../../../../../../../core/ek-e-commerce/ek-actions/characteristic.actions";
import {debounceTime, distinctUntilChanged, skip, tap} from "rxjs/operators";
import {CharacteristicsService} from "../../../../../../../../core/ek-e-commerce/ek-services/characteristics.service";

@Component({
  selector: 'kt-specification-edit2',
  templateUrl: './specification-edit2.component.html',
  styleUrls: ['./specification-edit2.component.scss']
})
export class EkSpecificationEdit2Component implements OnInit, OnDestroy {

    //the new params
    // Public properties

    dataSource: CharacteristicsDatasource;
    displayedColumns: string[] = [ 'id', 'Désignation', 'type', 'actions'];
    searched = true;
    private subscriptions: Subscription[] = [];
    lastQuery: QueryParamsModel;


    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    // Selection
    selection = new SelectionModel<CharacteristicModel>(true, []);
    charResult: CharacteristicModel[] = [];

    // Add and Edit
    currentRole = '';
    isSwitchedToEditMode = false;
    loadingAfterSubmit = false;
    formGroup: FormGroup;
    charForEdit: CharacteristicModel;
    charForAdd: CharacteristicModel;

    // Private properties
    private componentSubscriptions: Subscription;


    constructor(public dialogRef: MatDialogRef<EkSpecificationEdit2Component>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private store: Store<AppState>,
                private  characteristicsService: CharacteristicsService,
                private layoutUtilsService: LayoutUtilsService) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.searchInput.nativeElement.value = " ";
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        const sortSubscription = this.sort.sortChange.subscribe(() =>{

            (this.paginator.pageIndex = 0);

        });
        this.subscriptions.push(sortSubscription);
        this.paginator._changePageSize(100);
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() =>{

                this.loadCharList()})
        ).subscribe();

        this.subscriptions.push(paginatorSubscriptions);
        const searchSubscription= fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap((v) => {

                this.paginator.pageIndex = 0;

                this.loadCharList();
            })
        ).subscribe();
        this.subscriptions.push(searchSubscription);

        // this.loadCharList();
        this.createForm(null);


        this.dataSource = new CharacteristicsDatasource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.charResult = res;

        });
        this.subscriptions.push(entitiesSubscription);




    }

    loadCharList() {

           // this.selection.clear();
            const queryParams = new QueryParamsModel(
                this.filterCharacteristic(),
                null,
                null,
                this.paginator.pageIndex,
                this.paginator.pageSize
            );
            // Call request from server
            this.store.dispatch(CharacteristicsPageRequestedOfSearch({
                page: queryParams,
            }));
            //this.selection.clear();

    }

    /**
     * Returns object for filter
     */
    filterCharacteristic(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;
        filter.query = searchText.trim();
        return filter;
    }

    createForm(char: CharacteristicModel) {
        if (!char)
            this.formGroup = this.fb.group({
                editName: ['', Validators.required],
                newName: ['', Validators.required],
                editType: ['', Validators.required],
                newType: ['', Validators.required],

            });


        this.clearAddForm();
        this.clearEditForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    clearAddForm() {
        const controls = this.formGroup.controls;
        controls.newName.setValue('');
        controls.newName.markAsPristine();
        controls.newName.markAsUntouched();

        controls.newType.setValue('');
        controls.newType.markAsPristine();
        controls.newType.markAsUntouched();

        this.charForAdd = new CharacteristicModel();
        this.charForAdd.clear();

    }

    clearEditForm() {
        const controls = this.formGroup.controls;
        controls.editName.setValue('');
        controls.editType.setValue('');

        this.charForEdit = new CharacteristicModel();
        this.charForEdit.clear();


    }

    /**
     * Check if Add Form is Valid
     */
    checkAddForm() {
        const controls = this.formGroup.controls;
        if (controls.newName.invalid || controls.newType.invalid) {
            controls.newName.markAsTouched();
            controls.newType.markAllAsTouched();
            return false;
        }

        return true;
    }

    /**
     * Open Char Add Form
     */
    addCharButtonOnClick() {
        this.clearAddForm();
        this.charForAdd._isEditMode = true;
        this.isSwitchedToEditMode = true;
    }

    /**
     * Close Char Add Form
     */
    cancelAddButtonOnClick() {
        this.charForAdd._isEditMode = false;
        this.isSwitchedToEditMode = false;
    }

    /**
     *  Create new char
     */
    saveNewchar() {
        if (!this.checkAddForm()) {
            return;
        }

        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        this.charForAdd._isEditMode = false;
        this.charForAdd.name = controls.newName.value;
        this.charForAdd.type = controls.newType.value;

        this.store.dispatch(CharacteristicActions.CharacteristicCreated({characteristic: this.charForAdd}));
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedCharacteristicId)
        ).subscribe(res => {
            if (!res) {
                return;
            }

            const _saveMessage = `Characteristic has been created`;
            this.isSwitchedToEditMode = false;
            this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Create, 10000, true, true);
            this.clearAddForm();
        });
    }

    /**
     * Check is Edit Form valid
     */
    checkEditForm() {
        const controls = this.formGroup.controls;
        if (controls.editName.invalid || controls.editType.invalid) {
            return false;
        }
        return true;
    }


    /**
     * Update char
     *
     * @param _item: CharacteristicModel
     */
    editCharButtonOnClick(_item: CharacteristicModel) {
        const controls = this.formGroup.controls;
        controls.editName.setValue(_item.name);
        controls.editType.setValue(_item.type);
        _item._isEditMode = true;
        this.isSwitchedToEditMode = true;
    }

    /**
     * Cancel char
     *
     * @param _item: CharacteristicModel
     */
    cancelEditButtonOnClick(_item: CharacteristicModel) {

        _item._isEditMode = false;
        this.isSwitchedToEditMode = false;
    }

    /**
     * Save remark
     *
     * @param _item: CharacteristicModel
     */
    saveUpdateChar(_item: CharacteristicModel) {
        if (!this.checkEditForm()) {
            return;
        }

        this.loadingAfterSubmit = true;
        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        const objectForUpdate = new CharacteristicModel();
        objectForUpdate.id = _item.id;
        objectForUpdate._isEditMode = _item._isEditMode;
        objectForUpdate.name = controls.editName.value;
        objectForUpdate.type = controls.editType.value;
        objectForUpdate._isEditMode = false;
        const updateChar: Update<CharacteristicModel> = {
            id: _item.id,
            changes: objectForUpdate
        };

        this.store.dispatch(CharacteristicActions.CharacteristicUpdated({
            partialCharacteristic: updateChar,
            characteristic: objectForUpdate
        }));
        const saveMessage = `Characteristic has been updated`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, true);
    }


    //table

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        // const numSelected = this.selection.selected.length;
        // const numRows = this.dataSource.data.length;
        // return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        // this.isAllSelected() ?
        // this.selection.clear() :
        // this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: CharacteristicModel): string {
        if (!row) {
            // return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    /** ACTIONS */
    /**
     * Delete characteristic
     *
     * @param _item: CharacteristicModel
     */
    deletechar(_item: CharacteristicModel) {
        const _title = 'Characteristic Delete';
        const _description = 'Are you sure to permanently delete this characteristic?';
        const _waitDesciption = 'Characteristic is deleting...';
        const _deleteMessage = `Characteristic has been deleted`;

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(CharacteristicActions.CharacteristicDeleted({characteristicId: _item.id}));
          //  this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        });
    }
 search(){
        this.searched = false;
 }
    restoreState(queryParams: QueryParamsModel) {

        if (!queryParams.filter) {
            return;
        }


        if (queryParams.filter.model) {
            this.searchInput.nativeElement.value = queryParams.filter.model;
        }
    }

}
