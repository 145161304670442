import {BaseModel} from "../../_base/crud";
import {ShippingAddressFolder} from "./ShippingAddressFolder";
import {ClientInformationModel} from "./clientInformation.model";

export type FileShippingType = 'POINT_OF_SALE' | 'RELAY_POINT' | 'RESIDENCE';

export type feedBackStates = 'FINANCEABLE_CLIENT' | 'COMPLEMENT_REQUEST' | 'NON_FINANCEABLE_CLIENT' | 'NONE';

export type FolderStates = 'EK_FOLDER_CREATED'
    | 'EK_FOLDER_IN_PROGRESS'
    | 'EK_FOLDER_RESERVE'
    | 'EK_FOLDER_REFUSED'
    | 'EK_BANK_IN_PROGRESS'
    | 'EK_BANK_RESERVE'
    | 'EK_BANK_REFUSED'
    | 'EK_GUARTENTEES_PENDING_SIGNATURE'
    | 'EK_GUARTENTEES_SIGNED_IN_PROGRESS'
    | 'BANK_NOTIFICATION'
    | 'BANK_PAYMENT_CONFIRMATION'
    | 'CENTRAL_RISK_CONSULTATION'
    | 'CLIENT_CENTRAL_RISK_NOTIFICATION'
    | 'EK_PHYSICAL_FOLDER_PENDING'
    | 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK'
    | 'EK_PHYSICAL_FOLDER_PICKEDUP'
    | 'EK_PHYSICAL_FOLDER_IN_DELIVERY'
    | 'EK_PHYSICAL_FOLDER_DELIVERED'
    | 'EK_PHYSICAL_FOLDER_REFUSED'
    | 'EK_BANK_PHYSICAL_FOLDER_REFUSED'
    | 'EK_PHYSICAL_FOLDER_REFUSED'
    | 'EK_ADMIN_FOLDER_CANCELED'
    | 'EK_BANK_PHYSICAL_FOLDER_REFUSED'
    | 'NOT_PAID_RETURNED'
    | 'EK_PHYSICAL_FOLDER_REFUSED'
    | 'EK_WAITING_FOR_CLIENT'
    | 'EK_CLIENT_NO_RESPONSE'
    | 'EK_FOLDER_PICKUP_FAILED'
    | 'EK_PHYSICAL_FOLDER_IN_PROGRESS'
    | 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED'
    | 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY'
    | 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS'
    | 'EK_BANK_PHYSICAL_FOLDER_RESERVE'
    | 'EK_ADMIN_FOLDER_CANCELED'
    | 'FINANCIAL_FOLDER'
    | 'EK_CLIENT_FOLDER_CANCELED'
    | 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING'
    | 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS'
    ;

export class FolderModel extends BaseModel {
    id: number;
    folderPreviousState: FolderStates;
    folderState: FolderStates;
    orderSplitId: number;
    fileShippingType: FileShippingType;
    shippingAddressFolder: ShippingAddressFolder;
    documentTOSet:any;
    clientInformationTO:ClientInformationModel;
    mustVerifierByOCR: boolean;
    shippingAddressFolderId:number;
    archive:boolean;
    feedBack: feedBackStates;
    requestNumber:string;
}
