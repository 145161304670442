import {
    Component, ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,

} from "@angular/core";
import {SelectionModel} from "@angular/cdk/collections";

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {of, Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {select, Store} from "@ngrx/store";

import {Update} from "@ngrx/entity";
import {BrandsDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/brands.datasource";
import {MatPaginator, MatSort} from "@angular/material";
import {BrandModel} from "../../../../../../core/ek-e-commerce/ek-models/brand.model";
import {AppState} from "../../../../../../core/reducers";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {ProductRemarkModel} from "../../../../../../core/e-commerce";
import * as BrandActions from "../../../../../../core/ek-e-commerce/ek-actions/brand.action";
import {selectLastCreatedBrandId} from "../../../../../../core/ek-e-commerce/ek-selectors/brand.selector";
// Actions




@Component({
  selector: 'kt-brands-edit',
  templateUrl: './brands-edit.component.html',
  styleUrls: ['./brands-edit.component.scss']
})
export class EkBrandsEditComponent implements OnInit, OnDestroy {

    //the new params
    // Public properties

    dataSource: BrandsDatasource;
    displayedColumns: string[] = [ 'id', 'nom','reference', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selection = new SelectionModel<BrandModel>(true, []);
    brandResult: BrandModel[] = [];

    // Add and Edit
    isSwitchedToEditMode = false;
    loadingAfterSubmit = false;
    formGroup: FormGroup;
    brandForEdit: BrandModel;
    brandForAdd: BrandModel;

    // Private properties
    private componentSubscriptions: Subscription;


    constructor(public dialogRef: MatDialogRef<EkBrandsEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private store: Store<AppState>,
                private layoutUtilsService: LayoutUtilsService) {
    }

    /**
     * On init
     */
    ngOnInit() {

        this.dataSource = new BrandsDatasource(this.store);
        this.dataSource.entitySubject.subscribe(res => this.brandResult = res);
        this.loadBrandList();
        this.createForm(null);

    }

    loadBrandList() {
        // Call request from server
        this.store.dispatch(BrandActions.BrandsPageRequested({
            page: 0,
            size: 9999,
        }));
    }

    createForm(brand: BrandModel) {
        if (!brand)
            this.formGroup = this.fb.group({
                editName: ['', Validators.required],
                newName: ['', Validators.required],
                editReference: ['', Validators.required],
                newReference: ['', Validators.required],

            });


        this.clearAddForm();
        this.clearEditForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    clearAddForm() {
        const controls = this.formGroup.controls;
        controls.newName.setValue('');
        controls.newName.markAsPristine();
        controls.newName.markAsUntouched();

        controls.newReference.setValue('');
        controls.newReference.markAsPristine();
        controls.newReference.markAsUntouched();

        this.brandForAdd = new BrandModel();
        this.brandForAdd.clear();

    }

    clearEditForm() {
        const controls = this.formGroup.controls;
        controls.editName.setValue('');
        controls.editReference.setValue('');


        this.brandForEdit = new BrandModel();
        this.brandForEdit.clear();


    }

    /**
     * Check if Add Form is Valid
     */
    checkAddForm() {
        const controls = this.formGroup.controls;
        if (controls.newName.invalid) {
            controls.newName.markAsTouched();
            return false;
        }
        if (controls.newReference.invalid) {
            controls.newReference.markAsTouched();
            return false;
        }

        return true;
    }

    /**
     * Open Brand Add Form
     */
    addBrandButtonOnClick() {
        this.clearAddForm();
        this.brandForAdd._isEditMode = true;
        this.isSwitchedToEditMode = true;
    }

    /**
     * Close Remark Add Form
     */
    cancelAddButtonOnClick() {
        this.brandForAdd._isEditMode = false;
        this.isSwitchedToEditMode = false;
    }

    /**
     *  Create new remark
     */
    saveNewRemark() {
        if (!this.checkAddForm()) {
            return;
        }

        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        this.brandForAdd._isEditMode = false;
        this.brandForAdd.name = controls.newName.value;
        this.brandForAdd.reference = controls.newReference.value;


        this.store.dispatch(BrandActions.BrandCreated({brand: this.brandForAdd}));
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedBrandId)
        ).subscribe(res => {
            if (!res) {
                return;
            }

            const _saveMessage = `Brand has been created`;
            this.isSwitchedToEditMode = false;
            this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Create, 10000, true, true);
            this.clearAddForm();
        });
    }

    /**
     * Check is Edit Form valid
     */
    checkEditForm() {
        const controls = this.formGroup.controls;
        if (controls.editName.invalid) {
            return false;
        };
        if (controls.editReference.invalid) {
            return false;
        }
        return true;
    }


    /**
     * Update remark
     *
     * @param _item: BrandModel
     */
    editBrandButtonOnClick(_item: BrandModel) {
        const controls = this.formGroup.controls;
        controls.editName.setValue(_item.name);
        controls.editReference.setValue(_item.reference);
        const updateBrand: Update<BrandModel> = {
            id: _item.id,
            changes: {
                _isEditMode: true
            }
        };
        this.store.dispatch(BrandActions.BrandUpdated({brand: _item, partialBrand: updateBrand}));
        this.isSwitchedToEditMode = true;
    }

    /**
     * Cancel remark
     *
     * @param _item: ProductRemarkModel
     */
    cancelEditButtonOnClick(_item: BrandModel) {
        const updateBrand: Update<BrandModel> = {
            id: _item.id,
            changes: {
                _isEditMode: false
            }
        };
        this.store.dispatch(BrandActions.BrandUpdated({partialBrand: updateBrand, brand: _item}));
        this.isSwitchedToEditMode = false;
    }

    /**
     * Save remark
     *
     * @param _item: BrandModel
     */
    saveUpdateBrand(_item: BrandModel) {
        if (!this.checkEditForm()) {
            return;
        }

        this.loadingAfterSubmit = true;
        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        const objectForUpdate = new BrandModel();
        objectForUpdate.id = _item.id;
        objectForUpdate._isEditMode = _item._isEditMode;
        objectForUpdate.name = controls.editName.value;
        objectForUpdate.reference = controls.editReference.value;

        objectForUpdate._isEditMode = false;
        const updateBrand: Update<BrandModel> = {
            id: _item.id,
            changes: objectForUpdate
        };

        this.store.dispatch(BrandActions.BrandUpdated({
            partialBrand: updateBrand,
            brand: objectForUpdate
        }));
        const saveMessage = `Remark has been updated`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, true);
    }


    //table

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        // const numSelected = this.selection.selected.length;
        // const numRows = this.dataSource.data.length;
        // return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        // this.isAllSelected() ?
        // this.selection.clear() :
        // this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: BrandModel): string {
        if (!row) {
            // return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    /** ACTIONS */
    /**
     * Delete remark
     *
     * @param _item: BrandModel
     */
    deleteBrand(_item: ProductRemarkModel) {
        const _title = 'Brand Delete';
        const _description = 'Are you sure to permanently delete this brand?';
        const _waitDesciption = 'Brand is deleting...';
        const _deleteMessage = `Brand has been deleted`;

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(BrandActions.BrandDeleted({brandId: _item.id}));
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        });
    }


}
