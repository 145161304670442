import {MiniAnalyst} from './../../_base/crud/models/mini-credit-analystr.model';
import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {PointOfSaleModel} from "../ek-models/point-of-sale.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class PointOfSaleService extends AbstractService<PointOfSaleModel> {
    url: string;
    posSbj$ = new BehaviorSubject<PointOfSaleModel[]>([])
    public posObs$ = this.posSbj$.asObservable()

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}EK/pointSale`;
    }

    public getPosList(state:string = 'ACTIVE'): Observable<PointOfSaleModel[]> {

        const url = `${this.url}/pos/list/state`;

        let params = new HttpParams();
        params = params.append('state', state);
        const options = {params};

        return this.http.get<Response<PointOfSaleModel[]>>(url,options).pipe(map(({body}) => body));
    }
    public getPosListForFilter(): Observable<PointOfSaleModel[]> {

        const url = `${this.url}/find-all-with-region`;

        return this.http.get<Response<PointOfSaleModel[]>>(url, {}).pipe(map(({body}) => body));
    }

    public getAllPOS(filter: any, sorting: string = 'id', direction: string = 'desc', pageNumber: number = 0, pageSize: number = 100, regionId: number = 0): Observable<Response<Page<PointOfSaleModel>>> {
        let params = new HttpParams();
        params = filter.query ? params.append('fields', filter.query) : params;
        params = filter.status ? params.append('state', filter.status.toString()) : params;
        params = regionId ? params.append('regionId', regionId.toString()) : params;
        params = params.append('direction', direction);
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('sortBy', sorting);
        const options = {params};
        const url = `${this.url}/pages/with-region`;
        return this.http.get<Response<Page<PointOfSaleModel>>>(url, options);
    }

    //retrieve the list of Point Of Sale by id ws user
    public getAllPOSByUser(filter: any, sorting: string = 'id', direction: string = 'desc', pageNumber: number = 0, pageSize: number = 100): Observable<Response<Page<PointOfSaleModel>>> {

        let params = new HttpParams();
        params = filter.query ? params.append('fields', filter.query) : params;
        params = filter.status ? params.append('state', filter.status.toString()) : params;
        params = params.append('direction', direction);
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('sortBy', sorting);

        const options = {params};
        const url = `${this.url}/wsUser/Page`;
        return this.http.get<Response<Page<PointOfSaleModel>>>(url, options);
    }

    public createPointOfSale(Pos: PointOfSaleModel): Observable<Response<PointOfSaleModel>> {
        const url = `${this.url}/CreateByAdmin`;
        return this.http.post<Response<PointOfSaleModel>>(url, Pos)
    }

    public updatePointOfSale(Pos: PointOfSaleModel): Observable<Response<PointOfSaleModel>> {
        const url = `${this.url}/updateByAdmin`;
        return this.http.put<Response<PointOfSaleModel>>(url, Pos)
    }


    //retrieve the list of Point Of Sale by id user
    public getAllPOSByIdUser(idUser: number): Observable<PointOfSaleModel[]> {
        const url = `${this.url}/wsUser/${idUser}`;
        return this.http.get<PointOfSaleModel[]>(url).pipe(
            map((response: any) => {
                if (response) {
                    return response.body;
                } else {
                    throw new Error();
                }
            })
        );
    }


    addPosByIdUser(pointOfSaleId: number, wsUserId: number) {
        let params = new HttpParams();
        params = params.append('pointOfSaleId', pointOfSaleId.toString());
        params = params.append('wsUserId', wsUserId.toString());

        const options = {params};
        const url = `${this.url}/wsUser/add`;
        return this.http.get<PointOfSaleModel[]>(url, options);
    }

    deletePosByIdUser(pointOfSaleId: number, wsUserId: number) {

        let params = new HttpParams();
        params = params.append('pointOfSaleId', pointOfSaleId.toString());
        params = params.append('wsUserId', wsUserId.toString());

        const options = {params};
        const url = `${this.url}/wsUser/remove`;
        return this.http.get<PointOfSaleModel[]>(url, options);
    }


    public updatePOSStatus(posId: number, status: string): Observable<PointOfSaleModel> {
        const url = `${this.url}/stateModify/${status}/${posId}`;
        const entity = {
            id: posId,
            state: status
        }
        let params = new HttpParams();
        params = params.append('id', posId.toString());
        params = params.append('state', status);
        const options = {params};
        return this.http.patch<any>(url, null);
    }

    public updatePOS(pos: PointOfSaleModel): Observable<Response<PointOfSaleModel>> {
        return this.http.put<Response<PointOfSaleModel>>(this.url, pos).pipe(
            map((response: any) => {
                if (response) {
                    return response.body;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public deletePOS(id: number): Observable<Response<PointOfSaleModel>> {
        const url = `${this.url}/${id}`;
        return this.http.delete<Response<PointOfSaleModel>>(url).pipe(
            map((response: any) => {
                if (response) {
                    return response.body;
                } else {
                    throw new Error();
                }
            })
        )
    }

    public getPOSById(id: number): Observable<Response<PointOfSaleModel>> {
        const url = this.url + `/${id}`;
        return this.http.get<Response<PointOfSaleModel>>(url).pipe(
            map((response: any) => {
                if (response) {
                    return response.body;
                } else {
                    throw new Error();
                }
            }));
    }


    public getPointOfSale(): Observable<Response<PointOfSaleModel[]>> {
        const options = {
            params: new HttpParams()
                .append('state', 'ACTIVE')
        };
        const url = `${this.url}/list/state`;
        return this.http.get<Response<PointOfSaleModel[]>>(url, options);/*.pipe(
      map((response: Response<PointOfSale[]>) => {
        if (response.body) {
          return response.body;
        } else {
          throw new Error();
        }
      })
    );*/
    }

    getCreditAnalysts(): Observable<MiniAnalyst[]> {
        const options = {
            params: new HttpParams()
                .append('role', 'ROLE_CREDIT_ANALYST_EK')
        };
        const url = `${ADMIN_CONFIG.apiEndpoint}ws-users/list/usersByRole`
        return this.http.get<MiniAnalyst[]>(url, options)
    }

    getPos(): Observable<MiniAnalyst[]> {
        const options = {
            params: new HttpParams()
                .append('role', 'ROLE_POS_EK')
        };
        const url = `${ADMIN_CONFIG.apiEndpoint}ws-users/list/usersByRole`
        return this.http.get<MiniAnalyst[]>(url, options)
    }

    getCommercialPos(): Observable<MiniAnalyst[]> {
        const options = {
            params: new HttpParams()
                .append('role', 'ROLE_COMMERCIAL_POS_EK')
        };
        const url = `${ADMIN_CONFIG.apiEndpoint}ws-users/list/usersByRole`
        return this.http.get<MiniAnalyst[]>(url, options)
    }
}
