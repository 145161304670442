// Angular
import {Injectable} from '@angular/core';
// RxJS
import {catchError, map, switchMap} from 'rxjs/operators';
// NGRX
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// State
import {AppState} from '../../../core/reducers';
// Actions
import {of} from 'rxjs';
import {EkProductsService} from "../ek-services/ek-product.service";
import * as EkProductActions from "../ek-actions/ek-product.actions";
import {LayoutUtilsService, MessageType} from "../../_base/crud";

@Injectable()
export class EkProductEffects {


    constructor(private actions$: Actions, private productService: EkProductsService, private layoutUtilsService: LayoutUtilsService, private store: Store<AppState>) {
    }

    EkProductPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EkProductActions.EkProductsPageRequested),
            switchMap((action) =>
                this.productService.findByFilterSorted(action.page.pageNumber, action.page.pageSize, action.page.sortField, action.page.sortOrder, action.page.filter)
                    .pipe(
                        map(productPage => {
                            return EkProductActions.EkProductsPageLoadedSuccessfully(
                                {
                                    products: productPage.content,
                                    page: productPage.totalElements,
                                    totalCount: productPage.totalElements
                                })
                        }),
                        catchError(error => of(EkProductActions.EkProductsPageLoadFailed({error})))
                    ))));

    ProductCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EkProductActions.ProductCreated),
            switchMap((action) =>
                this.productService.add(action.product)
                    .pipe(
                        map(data => this.messageSaveProduct(data)),
                        catchError(error => of(EkProductActions.ProductCreationFailed({error})))
                    ))));

    ProductUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EkProductActions.ProductUpdated),
            switchMap((action) =>
                this.productService.update(action.product)
                    .pipe(
                        map(product => EkProductActions.ProductUpdatedSuccessfully({
                            product,
                            partialProduct: action.partialProduct
                        })),
                        catchError(error => of(EkProductActions.ProductUpdateFailed({error})))
                    ))));
    ProductUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EkProductActions.ProductUpdatedActivate),
            switchMap((action) =>
                this.productService.changeActivateProduct(action.product.id, action.activate, action.updater)
                    .pipe(
                        map(res => this.message(res.message, action.product, action.partialProduct, action.activate, action.updater)
                        ),
                        catchError(error => of(EkProductActions.ProductUpdateActivateFailed({error})))
                    ))));

    ProductDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EkProductActions.ProductDeleted),
            switchMap((action) =>
                this.productService.delete(action.productId)
                    .pipe(
                        map(() => EkProductActions.ProductDeletedSuccessfully({productId: action.productId})),
                        catchError(error => of(EkProductActions.ProductDeleteFailed({error})))
                    ))));

    message(message, product, partialProduct, activate, updater) {

        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
        return EkProductActions.ProductUpdatedActivateSuccessfully({
            product: product,
            partialProduct: partialProduct,
            activate: activate,
            updater: updater,

        })

    }

    messageSaveProduct(data) {
        if (data) {
            this.layoutUtilsService.showActionNotification('Produit Sauvegarder avec Succès!', MessageType.Update, 10000, true, false);
            return EkProductActions.ProductCreatedSuccessfully({product: data})
        } else {
            this.layoutUtilsService.showActionNotification('Configuration non crée, le produit a était supprimé !\n' +
                '\n' +
                'Merci de ressaisir le produit', MessageType.Create, 10000, true, false);
            throw new Error('Erreur');
        }
    }
}
