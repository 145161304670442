import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'kt-scoring-cart-statistic',
    templateUrl: './scoring-cart-statistic.component.html',
    styleUrls: ['./scoring-cart-statistic.component.scss']
})
export class ScoringCartStatisticComponent implements OnInit {

    @Input() name: string = "";
    @Input() iconUrl: string = "";
    @Input() data: any;

    value = 14;
    state: string = "Moyenne";

    constructor() {
    }

    ngOnInit(): void {
    }

}
