import {Component, OnInit} from '@angular/core';
import {FoldersService} from "../../../../../core/ek-e-commerce/ek-services/folders.service";
import {CountFolderState, Statistics} from "../../../../../core/ek-e-commerce/ek-models/count-statistics.model";
import {BehaviorSubject} from "rxjs";
import {ClientsListPopUpComponent} from "../folders/clients-list-pop-up/clients-list-pop-up.component";
import {MatDialog} from "@angular/material/dialog";
import {MenuConfigService} from "../../../../../core/_base/layout";

@Component({
    selector: 'kt-order-statistics',
    templateUrl: './order-statistics.component.html',
    styleUrls: ['./order-statistics.component.scss']
})
export class OrderStatisticsComponent implements OnInit {


    statisticsSubject$ = new BehaviorSubject<Statistics>(null);
    countStatistics$ = this.statisticsSubject$.asObservable();

    diameter = 200;
    foldersSubject$: BehaviorSubject<CountFolderState[]>  = new BehaviorSubject<CountFolderState[]>([])
    angle = 3;
    currentRole: string = '';

    constructor(private folderService: FoldersService, public dialog: MatDialog, private menuConfigService: MenuConfigService) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
        this.menuConfigService.statisticsSubject$.subscribe((data) => {
            this.foldersSubject$.next(data) ;
        })


        this.folderService.FoldersStatistics().subscribe({
            next: (res) => {
                this.statisticsSubject$.next(res);                
            }
        });

    }

    openClientPopUp() {
        this.dialog.open(ClientsListPopUpComponent, {
            width: '92rem',
        });
    }

}
