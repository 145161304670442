// Angular
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
// RxJS
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {AppState} from '../../../../core/reducers';
// Layout
import {LayoutConfigService, SubheaderService} from '../../../../core/_base/layout';
import {LayoutUtilsService, MessageType} from '../../../../core/_base/crud';
// Services and Models
import {
    Role,
    selectError,
    selectLastCreatedUserId,
    selectUserById,
    selectUsersActionLoading,
    User
} from '../../../../core/auth';
//Actions
import * as UserActions from '../../../../core/auth/_actions/user.actions';
import {NgxPermissionsService} from "ngx-permissions";
import {UserService} from "../../../../core/services/user.service";
import {PointOfSaleModel} from "../../../../core/ek-e-commerce/ek-models/point-of-sale.model";
import {PointOfSaleService} from "../../../../core/ek-e-commerce/ek-services/point-of-sale.service";
import {RolesTable} from "../../../../core/auth/_server/roles.table";
import {each, find, remove} from 'lodash';
import {Actions, ofType} from "@ngrx/effects";
import {
    AddUserFailed,
    UserAddedSuccessfully,
    UserUpdatedSuccessfully,
    UserUpdateFailed
} from "../../../../core/auth/_actions/user.actions";


@Component({
    selector: 'kt-ek-users-edit',
    templateUrl: './ek-users-edit.component.html',
    styleUrls: ['./ek-users-edit.component.scss']
})
export class EkUsersEditComponent implements OnInit {

// Public properties
    user: User;
    userId$: Observable<number>;
    oldUser: User;
    selectedTab = 0;
    loading$: Observable<boolean>;
    rolesSubject = new BehaviorSubject<string>('');
    userForm: FormGroup;
    hasFormErrors = false;
    // Private properties
    private subscriptions: Subscription[] = [];
    userPosList: PointOfSaleModel[] = [];
    pointOfSaleList: PointOfSaleModel[] = []
    RolesList: Role[] = [];
    prevAssignedRole: Role = null;
    allRoles: Role[] = [];
    unassignedRoles: Role[] = [];

    allUserRoles$: Observable<Role[]>;
    errorMsg = "Oh snap! Change a few things up and try submitting again.";
    currentRole: string;
    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param userFB
     * @param subheaderService
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     * @param ngxPermissionService
     * @param userService
     * @param cdr
     * @param _actions$
     * @param pointOfSaleService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userFB: FormBuilder,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private ngxPermissionService: NgxPermissionsService,
                private userService: UserService,
                private cdr: ChangeDetectorRef,
                private _actions$: Actions,
                private pointOfSaleService: PointOfSaleService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

        this.loading$ = this.store.pipe(select(selectUsersActionLoading));

        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(select(selectUserById(id))).subscribe(res => {
                    if (res == undefined) {
                        this.loadUserFromService(id);
                        return;
                    }

                    this.loadUser(res);
                });
            } else {
                this.user = new User();
                this.user.clear();
                this.rolesSubject.next(this.user.roles);
                this.oldUser = Object.assign({}, this.user);
                this.initUser();
            }
        });
        this.subscriptions.push(routeSubscription);


        this.pointOfSaleService.getAll().subscribe((res) => {
            this.pointOfSaleList = res;
        });

        this.ngxPermissionService.hasPermission("ROLE_SUPERADMIN").then(permission => {

            //if super admin display all roles
            if (permission) {
                this.allUserRoles$ = of(RolesTable.roles);
            } else {
                this.allUserRoles$ = of(RolesTable.roles.filter(role => role.id != 'ROLE_SUPERADMIN'));
            }

            //get all roles list
            this.allUserRoles$.subscribe((res: Role[]) => {
                each(res, (_role: Role) => {
                    this.allRoles.push(_role);
                    this.RolesList.push(_role);
                });

                each(this.rolesSubject.value, (roleId: string) => {
                    const role = find(this.allRoles, (_role: Role) => {
                        return _role.id === roleId;
                    });
                    if (role) {
                        this.prevAssignedRole = role;
                        remove(this.RolesList, (el) => el.id === role.id);
                    }
                });
            });
        });
    }

    phoneNumberValidator(): ValidatorFn {
        const PHONE_REGEX = /^(00213|\+213|0)(5|6|7|9)(\s*?[0-9]\s*?){5,8}$/;
      
        return (control: AbstractControl): { [key: string]: any } | null => {
          const valid = PHONE_REGEX.test(control.value);
          return valid ? null : { 'invalidPhoneNumber': { value: control.value } };
        };
    }

    loadUser(_user, fromService: boolean = false) {
        if (!_user) {
            return;
        }
        this.user = _user;
        this.rolesSubject.next(_user.roles);
        this.userId$ = of(_user.id);
        this.oldUser = Object.assign({}, _user);
        this.initUser();
        if (fromService) {
            this.cdr.detectChanges();
        }
    }


    loadUserFromService(userId) {
        this.userService.findUserById(userId).subscribe(res => {
            this.loadUser(res, true);
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    /**
     * Init user
     */
    initUser() {
        this.createForm();
        if (!this.user.id) {
            this.subheaderService.setTitle('Create user');
            this.subheaderService.setBreadcrumbs([
                {title: 'User Management', page: `ek-user-management`},
                {title: 'Users', page: `ek-user-management/users`},
                {title: 'Create user', page: `ek-user-management/users/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit user');
        this.subheaderService.setBreadcrumbs([
            {title: 'User Management', page: `ek-user-management`},
            {title: 'Users', page: `ek-user-management/users`},
            {title: 'Edit user', page: `ek-user-management/users/edit`, queryParams: {id: this.user.id}}
        ]);
    }

    /**
     * Create form
     */
    createForm() {
        this.userForm = this.userFB.group({
            username: [this.user.username, Validators.required],
            firstname: [this.user.firstname, Validators.required],
            lastname: [this.user.lastname, Validators.required],
            address: [this.user.address, Validators.required],
            email: [this.user.email, Validators.email],
            phone: [this.user.phone, [this.phoneNumberValidator()]],
            codification: [this.user.codification , Validators.compose([Validators.required, this.forbiddenCodification()])],
            role : [this.user.roles]
        });
    }

    /**
     * Redirect to list
     *
     */
    goBackWithId() {
        const url = `/ek-user-management/users`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }

    /**
     * Refresh user
     *
     * @param isNew: boolean
     * @param id: number
     */
    refreshUser(isNew: boolean = false, id = 0) {
        let url = this.router.url;
        if (!isNew) {
            this.router.navigate([url], {relativeTo: this.activatedRoute});
            return;
        }

        url = `/ek-user-management/users/edit/${id}`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }

    /**
     * Reset
     */
    reset() {
        this.user = Object.assign({}, this.oldUser);
        this.createForm();
        this.hasFormErrors = false;
        this.userForm.markAsPristine();
        this.userForm.markAsUntouched();
        this.userForm.updateValueAndValidity();
    }

    /**
     * Save data
     *
     * @param withBack
     */
    onSumbit(withBack: boolean = false) {


        this.hasFormErrors = false;
        const controls = this.userForm.controls;
        /** check form */

        if (this.userForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            this.selectedTab = 0;
            return;
        }

        const editedUser = this.prepareUser();

        if (editedUser.id > 0) {
            this.updateUser(editedUser, withBack);
            return;
        }

        this.addUser(editedUser, withBack);
    }

    /**
     * Returns prepared data for save
     */
    prepareUser(): User {

        let role = this.rolesSubject.value;

        //update current user roles in local storage ::::
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.roles = role;
        // localStorage.setItem('currentUser', JSON.stringify(currentUser));


        const controls = this.userForm.controls;
        const _user = new User();
        _user.clear();
      //  _user.roles = role;
        _user.address = controls.address.value;
        _user.pic = this.user.pic;
        _user.id = this.user.id;
        _user.username = controls.username.value;
        _user.email = controls.email.value;
        _user.firstname = controls.firstname.value;
        _user.lastname = controls.lastname.value;
        _user.phone = controls.phone.value;
        _user.password = this.user.password;
        _user.pointOfSales = this.userPosList;
        _user.codification = controls.codification.value;
        _user.roles = this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER'? 'ROLE_POS_EK' :
            controls.role.value;
        return _user;
    }

    /**
     * Add User
     *
     * @param _user: User
     * @param withBack: boolean
     */
    addUser(_user: User, withBack: boolean = false) {

        this.store.dispatch(UserActions.UserOnServerCreated({user: _user}));

        //order added error ....
        this._actions$
            .pipe(ofType(AddUserFailed))
            .subscribe((data: any) => {
                if (data && data.error.status === 400) {
                    this.errorMsg = `Longueur de codification invalide. La codification doit être exactement de 21 caractères ?`;
                    this.layoutUtilsService.showActionNotification(
                        this.errorMsg,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                } else {
                    this.errorMsg = `there is an error in adding user!`;
                    this.layoutUtilsService.showActionNotification(
                        this.errorMsg,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }
            });

        const addSubscription = this.store.pipe(select(selectLastCreatedUserId)).subscribe(newId => {

            if (newId) {
                const message = `New user successfully has been added.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
            }
            if (newId) {
                if (withBack) {
                    this.goBackWithId();
                } else {
                    this.refreshUser(true, newId);
                }
            }
        });
        const errorSubscription = this.store.pipe(select(selectError)).subscribe(error => {
            if (error)
                this.hasFormErrors = true;
        });
        this.subscriptions.push(errorSubscription);
        this.subscriptions.push(addSubscription);
    }

    /**
     * Update user
     *
     * @param _user
     * @param withBack
     */
    updateUser(_user: User, withBack: boolean = false) {

        // Update User
        // tslint:disable-next-line:prefer-const

        const updatedUser: Update<User> = {
            id: _user.id,
            changes: _user
        };
        this.store.dispatch(UserActions.UserUpdated({partialUser: updatedUser, user: _user}));

        //user updated successfully
        this._actions$
            .pipe(ofType(UserUpdatedSuccessfully))
            .subscribe((data: any) => {
                this.errorMsg = `User successfully has been saved.`;
                this.layoutUtilsService.showActionNotification(this.errorMsg, MessageType.Update, 5000, true, true);
                if (withBack) {
                    this.goBackWithId();
                } else {
                    this.refreshUser(false);
                }
            });

        //user updated error
        this._actions$
            .pipe(ofType(UserUpdateFailed))
            .subscribe((data: any) => {
                if (data && data.error.status === 400) {
                    this.errorMsg = `Longueur de codification invalide. La codification doit être exactement de 21 caractères ?`;
                    this.layoutUtilsService.showActionNotification(
                        this.errorMsg,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                } else {
                    this.errorMsg = `there is an error in updating user!`;
                    this.layoutUtilsService.showActionNotification(
                        this.errorMsg,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }
            });
    }

    /**
     * Returns component title
     */
    getComponentTitle() {
        let result = 'Create user';
        if (!this.user || !this.user.id) {
            return result;
        }

        result = `Edit user - ${this.user.firstname}`;
        return result;
    }

    /**
     * Close Alert
     *
     * @param $event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    addUserPos(event: PointOfSaleModel[]) {
        this.userPosList = event;
    }

    forbiddenCodification(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return control?.value?.length === 21 ? null : {CodificationValid: true};
        };
    }

}
