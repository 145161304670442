import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {CreditModel} from "../../../../../../../core/ek-e-commerce/ek-models/credit.model";
import {OrderSplitService} from "../../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {ActivatedRoute} from "@angular/router";
import {FoldersService} from "../../../../../../../core/ek-e-commerce/ek-index";
import {DocumentModel} from "../../../../../../../core/ek-e-commerce/ek-models/document.model";
import {FolderVerificationService} from "../../../../../../../core/services/folder-verification.service";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'kt-folder-step-five',
    templateUrl: './folder-step-five.component.html',
    styleUrls: ['./folder-step-five.component.scss']
})
export class FolderStepFiveComponent implements OnInit {
    displayedColumns: string[] = ['Mnt_des_remboursements', 'Capacité_de_crédit', 'capacité_dendettement', 'Mnt_échéance', 'Mnt_de_financement', 'Durée_de_crédit', 'Date_de_début', 'Date_de_fin'];
    dataSource: MatTableDataSource<CreditModel> = new MatTableDataSource();
    orderId: number;
    credit: CreditModel;
    cartCapacity = null;
    REPAYMENT_SCHEDULE_Documents: DocumentModel [] = [];
    @Input() folderState = '';
    currentRole = '';
    constructor(private orderService: OrderSplitService,
                private route: ActivatedRoute,
                private folderVerificationService: FolderVerificationService,
                private folderService: FoldersService) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.orderId = Number(params.get('idOrder'));
        });

        this.cartCapacity = JSON.parse(localStorage.getItem('cartCapacity'));

        this.orderService.getCredit(this.orderId).subscribe((cr) => {
            this.credit = cr;
            const data = this.dataSource.data;
            data.push(cr);
            this.dataSource.data = data;
        });
        this.getDocument();

        this.folderService.folderStateSubj$.subscribe({
            next: (state) => {
                this.folderState = state;
            }
        });

    }

    getDocument() {
        this.folderVerificationService.documents$.subscribe(documents => {
            if (documents) {
                this.REPAYMENT_SCHEDULE_Documents = documents.filter(
                    (document: DocumentModel) =>
                        document.documentsType === "REPAYMENT_SCHEDULE"
                );
            }
        });
    }

    generateRepaymentScheduleTable() {

        if (this.currentRole !== 'ROLE_BANKER_EK' && this.currentRole !== 'ROLE_BANK_API') {
            this.orderService.downloadTableMargePdf(this.orderId).subscribe((response: any) => {
                if (response.message == 'success' && response.body) {
                    const byteCharacters = atob(response.body);

                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers [i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const nav = (window.navigator as any);
                    let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    if (nav && nav.msSaveOrOpenBlob) {
                        nav.msSaveOrOpenBlob(blob);
                        return;
                    }
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.download = "tableau d'échéanciers de remboursement" + this.orderId + new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') + '.pdf';
                    anchor.href = url;

                    anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                    // this.isExporting = false;
                    setTimeout(function () {
                        window.URL.revokeObjectURL(url);
                        anchor.remove();
                    }, 100)

                }
            });
        }

    }

    showRepaymentScheduleTableBox(): boolean {

        const forbiddenStates = ['EK_FOLDER_CREATED', 'EK_FOLDER_IN_PROGRESS', 'EK_FOLDER_RESERVE', 'EK_FOLDER_REFUSED', 'CENTRAL_RISK_CONSULTATION', 'EK_BANK_IN_PROGRESS', 'BANK_NOTIFICATION', 'EK_BANK_RESERVE', 'EK_BANK_REFUSED', 'BANK_PAYMENT_CONFIRMATION'];

        if (forbiddenStates.includes(this.folderState)) {
            return false;
        } else {
            return true;
        }

    }
}
