import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectConfigurationCharacteristicsInStore,
    selectConfigurationCharacteristicsPageLoading,
    selectConfigurationCharacteristicsShowInitWaitingMessage
} from "../ek-selectors/configuration-characteristic.selectors";


export class ConfigurationCharacteristicsDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectConfigurationCharacteristicsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectConfigurationCharacteristicsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectConfigurationCharacteristicsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }

}
