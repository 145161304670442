export const BANK_LENDER= [
    {name: 'Banque Nationale D\'Algerie B N A', value: 'BNA'},
    {name: 'Banque Exterieur D\'Algerie B E A', value: 'BEA'},
    {name: 'Banque Algerienne Develeppement Rural B A D R', value: 'BADR'},
    {name: 'Credit Populaire D\'Algerie C P A', value: 'CPA'},
    {name: 'Banque Developpement Local B D L', value: 'BDL'},
    {name: 'El Baraka Banque', value: 'ELBARAKA'},
    {name: 'Centre Des Cheques Postaux C C P', value: 'CCP'},
    {name: 'Tresor Central', value: 'TRESOR'},
    {name: 'CNEP', value: 'CNEP'},
    {name: 'CITY Bank', value: 'CITYBANK'},
    {name: 'ABC CORPA', value: 'ABC_CORP'},
    {name: 'Natexis', value: 'NATEXIS'},
    {name: 'Societe Generale Algerie', value: 'SOCIETE_GENERALE'},
    {name: 'Arab Bank PLC', value: 'ARAB_BANK'},
    {name: 'B N P', value: 'BNP'},
    {name: 'TRUST BANK', value: 'TRUST_BANK'},
    {name: 'Housing Bank AG', value: 'HOUSING_BANK'},
    {name: 'ALGERIA GULF BANK', value: 'ALGERIA_GULF_BANK'},
    {name: 'FRANSA BANK', value: 'FRANSABANK'},
    {name: 'CALYON', value: 'CALYON'},
    {name: 'HSBC ALGERIA', value: 'HSBC_ALGERIA'},
    {name: 'AL SALAM BANK ALGERIA', value: 'ALSALAM_BANK'},
    {name: 'Banque D\'Algerie B A', value: 'BANQUE_ALGERIE'},
]
