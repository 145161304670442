import { Response } from './../../_base/crud/models/response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { configurationQuantity } from '../ek-models/configuration-quantity.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationQuantityService extends AbstractService<configurationQuantity> {
    url: string;
    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}configurationQuantity`;
    }

    getConfigQuanByConfigId(configId: number) {
        const url = `${this.url}/configuration/${configId}`
        return this.http.get<Response<configurationQuantity[]>>(url)
    }

    getConfigQuanOrderByConfigId(configId: number) {
        const url = `${this.url}/configuration/active/${configId}`
        return this.http.get<Response<configurationQuantity[]>>(url)
    }

    getConfigActivatedStocksByIdPOS(configId: number, pointOfSaleId:number) {
        const url = `${this.url}/configuration/active/${configId}/${pointOfSaleId}`
        return this.http.get<configurationQuantity[]>(url)
    }

    changeState(configurationId: number, stockId: number, depotId: number, active: boolean) {
        const params  = new HttpParams()
            .set('configurationId', configurationId.toString())
            .set('stockId', stockId.toString())
            .set('depotId', depotId.toString())
            .set('active', active.toString())
        const url = `${this.url}/state`
        return this.http.patch<Response<any>>(url, params)
    }
}