import {createAction, props} from "@ngrx/store";
import {ConfigurationModel} from "../ek-models/configuration.model";
import {Update} from "@ngrx/entity";

export const ConfigurationsPageRequested = createAction('[Configuration List Page] Configurations Page Requested ',props<{ productId: number}>());
export const ConfigurationsPageLoadedSuccessfully = createAction('[Configuration API] Configurations Page Loaded Successfully ',props<{configurations: ConfigurationModel[]}>());
export const ConfigurationsPageLoadFailed = createAction('[Configuration API] Configurations Page Request Failed ',props<{error: any}>());

export const ConfigurationCreated = createAction('[Configuration List Page] Configuration Created ',props<{configuration: ConfigurationModel}>());
export const ConfigurationCreatedSuccessfully = createAction('[Configuration List Page] Configuration Created Successfully ',props<{configuration: ConfigurationModel}>());
export const ConfigurationCreationFailed = createAction('[Configuration List Page] Configuration Creation Failed ',props<{error: any}>());

export const ConfigurationUpdated = createAction('[Configuration List Page] Configuration Updated ',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>}>());
export const ConfigurationUpdatedSuccessfully = createAction('[Configuration List Page] Configuration Updated Successfully ',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>}>());
export const ConfigurationUpdateFailed = createAction('[Configuration List Page] Configuration Update Failed ',props<{error: any}>());

export const ConfigurationDeleted = createAction('[Configuration List Page] Configuration Deleted ',props<{configurationId: number}>());
export const ConfigurationDeletedSuccessfully = createAction('[Configuration List Page] Configuration deleted Successfully ',props<{configurationId: number}>());
export const ConfigurationDeleteFailed = createAction('[Configuration List Page] Configuration delete Failed ',props<{error: any}>());


