// NGRX
import {Action, createReducer, on} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
// Actions
import * as CustomerAddressActions from '../_actions/customer-address.actions'
// Models
import { QueryParamsModel } from '../../_base/crud';
import {Address} from "../../ek-e-commerce/ek-models/address.model";
import {CustomersState} from "./customer.reducers";

export interface CustomerAddressState extends EntityState<Address> {
    customerId: number;
    loading: boolean;
    totalCount: number;
    lastCreatedAddressId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
}

export const adapter: EntityAdapter<Address> = createEntityAdapter<Address>();

export const initialCustomerAddressState: CustomerAddressState = adapter.getInitialState({
    loading: false,
    totalCount: 0,
    customerId: undefined,
    lastCreatedAddressId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: null,
});

const customerAddressReducer = createReducer(
    initialCustomerAddressState,
    on(CustomerAddressActions.AddressesRequested,
       (state, action) => ({...state, customerId: action.customerId, loading: true, showInitWaitingMessage: true}) ),
    on(CustomerAddressActions.AddressesLoadedSuccessfully,
        (state, action)=>(adapter.addAll(action.addresses,{... state, loading: false, showInitWaitingMessage: false}))),
    on(CustomerAddressActions.AddressesLoadingFailed,
        (state, action)=>({...state, error: action.error, loading: false, showInitWaitingMessage: false})),
    on(CustomerAddressActions.AddressCreated,
        (state, action) =>({...state, loading: true})),
    on(CustomerAddressActions.AddressCreatedSuccessfully,
        (state, action) =>(adapter.addOne(action.address, {...state, loading: false}))),
    on(CustomerAddressActions.AddressCreationFailed,
        (state, action) =>({...state, loading: false, error: action.error}))

);

export function customerAddressesReducer(state: CustomerAddressState| undefined, action: Action) {
    return customerAddressReducer(state, action)
}
