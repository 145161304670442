import {BaseModel} from "../../_base/crud";
import {DocumentModel} from "./document.model";

export class CreditModel extends BaseModel {
    id: number;
    numberOfMonths: number;
    documents: DocumentModel[];
    endFunding: string;
    creditCapacity: number;
    annuityByOrderSplit: number;
    refundAmount: number;
    total: number;
    debtCapacity: number;
    totalWithoutTax: number;
    monthlyPayment?:number;
    reimbursementAmount?:number;
}
