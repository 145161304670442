import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FoldersService } from "../../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {
  feedBackStates,
} from "../../../../../../../core/ek-e-commerce/ek-models/folder.model";
import {FolderVerificationService} from "../../../../../../../core/services/folder-verification.service";
import {DocumentModel} from "../../../../../../../core/ek-e-commerce/ek-models/document.model";

@Component({
  selector: "kt-feedback-step",
  templateUrl: "./feedback-step.component.html",
  styleUrls: ["./feedback-step.component.scss"],
})
export class FeedbackStepComponent implements OnInit {
  feedbackForm: FormGroup;

  @Input() folderFeedback: feedBackStates;

  feedbackOptions: string[] = [
    "Non financable",
    "Financable",
    "Demande complément d’information",
    "Rejetée",
  ];

  bankSupplementDocuments : DocumentModel [] = [];

  @Output() feedbackSubmitted = new EventEmitter<string>();
  currentRole: string = "";
  isReadOnly: boolean = true;

  constructor(private fb: FormBuilder, public folderService: FoldersService,
              private folderVerificationService: FolderVerificationService,
  ) {
    this.currentRole = JSON.parse(localStorage.getItem("currentUser")).roles;
  }

  ngOnInit(): void {
    this.feedbackForm = this.fb.group({
      feedback: [
        this.folderFeedback ? this.getFeedBack(this.folderFeedback) : "",
        Validators.required,
      ],
    });

    //get bank supplement documents
    this.getDocument();

    if (
      this.currentRole !== "ROLE_BANKER_EK" &&
      this.currentRole !== "ROLE_ADMIN" &&
      this.currentRole !== "ROLE_SUPERADMIN"
    ) {
      this.isReadOnly = true;
    } else {
      this.isReadOnly = false;
    }
    // Subscribe to the valueChanges observable of the 'feedback' form control
    this.feedbackForm.get("feedback")?.valueChanges.subscribe((newValue) => {
      switch (newValue) {
        case "Financable": {
          this.folderService.bankFeedback$.next("FINANCEABLE_CLIENT");
          this.feedbackSubmitted.emit("FINANCEABLE_CLIENT");
          break;
        }
        case "Non financable": {
          this.folderService.bankFeedback$.next("NON_FINANCEABLE_CLIENT");
          this.feedbackSubmitted.emit("NON_FINANCEABLE_CLIENT");
          break;
        }
        case "Demande complément d’information": {
          this.folderService.bankFeedback$.next("COMPLEMENT_REQUEST");
          this.feedbackSubmitted.emit("COMPLEMENT_REQUEST");
          break;
        }
        case "Rejetée": {
          this.folderService.bankFeedback$.next("REJECTION");
          this.feedbackSubmitted.emit("REJECTION");
          break;
        }
      }

      // You can emit this value to the parent component or perform any other action here
    });
  }


  canEditFeedBack(){

    const folderState = this.folderService.folderStateSubj$.value;

    const forbiddenStates = ['EK_FOLDER_CREATED','EK_FOLDER_IN_PROGRESS','EK_FOLDER_RESERVE','EK_FOLDER_REFUSED'];

    if (forbiddenStates.includes(folderState)){
      return false;
    }else {
      return true;
    }
  }

  getDocument(){
    this.folderVerificationService.documents$.subscribe(documents => {
      if (documents) {
        this.bankSupplementDocuments = documents.filter(
            (document: DocumentModel) =>
                document.documentsType === "BANK_SUPPLEMENT"
        )
      }
    });
  }

  getFeedBack(feedBackStates): string {
    switch (feedBackStates) {
      case "FINANCEABLE_CLIENT": {
        this.folderService.bankFeedback$.next("FINANCEABLE_CLIENT");
        return "Financable";
        break;
      }
      case "COMPLEMENT_REQUEST": {
        this.folderService.bankFeedback$.next("COMPLEMENT_REQUEST");
        this.feedbackSubmitted.emit("COMPLEMENT_REQUEST");
        return "Demande complément d’information";
        break;
      }
      case "NON_FINANCEABLE_CLIENT": {
        this.folderService.bankFeedback$.next("NON_FINANCEABLE_CLIENT");
        return "Non financable";
        break;
      }
      case "REJECTION": {
        this.folderService.bankFeedback$.next("REJECTION");
        return "Rejetée";
        break;
      }
      case "NONE": {
        return "";
        break;
      }
    }
  }

  // onSelectionChange(event : any) {
  //   if (this.feedbackForm.valid) {
  //     const feedbackValue = this.feedbackForm.value.feedback;
  //     this.feedbackSubmitted.emit(feedbackValue);
  //   }
  // }
}
