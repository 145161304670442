import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    FolderStateChangeDatasource
} from "../../../../../../../core/ek-e-commerce/ek-data-sources/folderStateChange.datasource";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {MatSort} from "@angular/material/sort";
import {
    FolderStateChangePageRequested
} from "../../../../../../../core/ek-e-commerce/ek-actions/FolderStateChange.actions";

@Component({
    selector: 'kt-folder-state-history-details',
    templateUrl: './folder-state-history-details.component.html',
    styleUrls: ['./folder-state-history-details.component.scss']
})
export class FolderStateHistoryDetailsComponent implements OnInit, OnDestroy {

    clientName: string;
    dataSource: FolderStateChangeDatasource;
    displayedColumns: string[] = ['update-date', 'by-user', 'prev-state', 'last-state'];
    //sorting table
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    private subscriptions: Subscription[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { folderId: number, clientFirstName: string, clientLastName: string, requestNumber: string },
        public dialogRef: MatDialogRef<FolderStateHistoryDetailsComponent>,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private store: Store<AppState>,
    ) {
    }

    ngOnInit(): void {

        //init dataSource
        this.dataSource = new FolderStateChangeDatasource(this.store);
        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.getAllPaged();
        });

    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
    }

    getAllPaged() {

        this.store.dispatch(FolderStateChangePageRequested({
            idFolder: this.data.folderId,
        }));

        // this.store.dispatch(ChangeHistoryPageRequested({
        //     page: queryParams,
        //     folderState: this.selectedFolderState.value,
        //     orderState: this.selectedOrderState.value,
        //     fileShippingType: this.selectedShippingType.value,
        //     firstDate: this.fromDate ? this.formatDate(this.fromDate) : null,
        //     lastDate: this.toDate ? this.formatDate(this.toDate) : null,
        //     wilaya: this.selectedWilaya.value,
        //     archived: this.selectedArchive.value,
        // }));
    }
}
