import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {ConfigurationCharacteristicModel} from "../ek-models/configuration-characteristic.model";
import {Observable, of, throwError} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map, mergeMap} from "rxjs/operators";


@Injectable()
export class ConfigurationCharacteristicsService extends AbstractService<ConfigurationCharacteristicModel>{

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}products/characteristics`;
    }

    public findByConfigurationId(id: number): Observable<ConfigurationCharacteristicModel[]> {
        const url = `${this.url}/productId/${id}`;
        return this.http.get<Response<ConfigurationCharacteristicModel[]>>(url).pipe(map(({body}) => body));
    }
    public deleteConfigurationCharacteristic(id: number,updater:string): Observable<Response<any>> {
        const url = `${this.url}/${id}/${updater}`;
        return this.http.patch<Response<ConfigurationCharacteristicModel>>(url, "").pipe(
            mergeMap(result => result.code == 0 ?
                of(result): throwError(result))
        )
    }
    public saveWithUpdater(entity: ConfigurationCharacteristicModel,updater:string): Observable<ConfigurationCharacteristicModel> {
        const url = `${this.url}/saveWithUpdater/${updater}`;
        const res = this.http.post<Response<ConfigurationCharacteristicModel>>(url, entity).pipe(map(({body}) => body));
        return res;
    }
    public updateAndSaveUpdater(entity: ConfigurationCharacteristicModel,updater:string): Observable<ConfigurationCharacteristicModel> {
        const url = `${this.url}/saveWithUpdater/${updater}`;
        return this.http.put<Response<ConfigurationCharacteristicModel>>(url, entity).pipe(map(({body}) => body));
    }
    public changeOrderConfigurationCharacteristic(id: number, order: number, position: number): Observable<Response<ConfigurationCharacteristicModel>> {
        const url = `${this.url}/${id}/${order}/${position}`;
        return this.http.patch<Response<ConfigurationCharacteristicModel>>(url, "").pipe(
            map((response: Response<ConfigurationCharacteristicModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }
}
