import {DocumentModel} from './../ek-e-commerce/ek-models/document.model';
import {DocumentErrorMessages} from './../ek-e-commerce/ek-models/ocr-validations';
import {ClientOcrInformationService} from './../ek-e-commerce/ek-services/clientOcrInformation.service';
import {SocialProfessional} from './../ek-e-commerce/ek-models/social-professional';
import {Injectable} from '@angular/core';

import {ClientModel} from '../ek-e-commerce/ek-index';
import {OneByOneValidations, VerificationFullObject} from '../ek-e-commerce/ek-models/ocr-validations';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FolderVerificationService {
    //Validation values
    oneByOneValidations: OneByOneValidations = {
        ficheFamilliale: true,
        extraitDeNaissance: true,
        residence: true,
        chifa: true,
        FichePaie1: true,
        FichePaie2: true,
        FichePaie3: true,
        cheque: true,
        cni: true,
        cni_verso: true,
        ccp: true,
        attestationDeTravail: true,
        retraite: true,
        faces: true,
        rena: true,
        radiation: true,
        releveDeCompte: true,
        incomeMilitary: true
    };

    public ocrMessages$: BehaviorSubject<DocumentErrorMessages>;
    public needSomeChanges$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public documents$: BehaviorSubject<DocumentModel[]> = new BehaviorSubject<DocumentModel[]>(null as any);
    ocrMessages: DocumentErrorMessages = {
        cni: {
            cni_inf_10_ans: {error: false, message: ''},
            right_document: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        cni_verso: {
            right_document: {error: false, message: ''},
            illisible: {error: false, message: ''},
        },
        extrait_naissance: {
            acte_valid: {error: false, message: ''},
            right_document: {error: false, message: ''},
            extrait_naissance: {error: false, message: ''},
            illisible: {error: false, message: ''},
            age_inf_70ans: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        fiche_familiale: {
            fiche_inf_1mois: {error: false, message: ''},
            right_document: {error: false, message: ''},
            fiche_familiale: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        residence: {
            right_document: {error: false, message: ''},
            residence: {error: false, message: ''},
            fiche_inf_6mois: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        chifa: {
            right_document: {error: false, message: ''},
            chifa: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        cheque: {
            right_document: {error: false, message: ''},
            cheque: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        ccp: {
            duree_sup_3_mois_delivrance_min_1_mois: {error: false, message: ''},
            right_document: {error: false, message: ''},
            ccp: {error: false, message: ''},
            illisible: {error: false, message: ''},
            verify_calcul: {error: false, message: ''},
            document_modifie: {error: false, message: ''},
            taxe_206: {error: false, message: ''},
            Taxe_300_DZD_Detecte: {error: false, message: ''},
        },
        fiche_paie_1: {
            verify_delivrance: {error: false, message: ''},
            right_document: {error: false, message: ''},
            fiche_paie_1: {error: false, message: ''},
            verify_calcul: {error: false, message: ''},
            salaire_superieur_a_25000: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        fiche_paie_2: {
            verify_delivrance: {error: false, message: ''},
            right_document: {error: false, message: ''},
            fiche_paie_2: {error: false, message: ''},
            verify_calcul: {error: false, message: ''},
            salaire_superieur_a_25000: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        fiche_paie_3: {
            verify_delivrance: {error: false, message: ''},
            right_document: {error: false, message: ''},
            fiche_paie_3: {error: false, message: ''},
            verify_calcul: {error: false, message: ''},
            salaire_superieur_a_25000: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        attestation: {
            right_document: {error: false, message: ''},
            attestation: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        retraite: {
            right_document: {error: false, message: ''},
            retraite: {error: false, message: ''},
            verify_calcul: {error: false, message: ''},
            salaire_sup_25000: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        rena: {
            right_document: {error: false, message: ''},
            rena: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        radiation: {
            right_document: {error: false, message: ''},
            radiation: {error: false, message: ''},
            illisible: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        },
        attestation_revenue_militaire: {
            right_document: {error: false, message: ''},
            attestation_revenu_militaire: {error: false, message: ''},
            illisible: {error: false, message: ''},
            verify_delivrance: {error: false, message: ''},
            document_modifie: {error: false, message: ''}
        }
    }
    client!: ClientModel;
    needSomeChanges: boolean = null as any;

    public clientInformationSosioProf!: SocialProfessional;

    constructor(private clientInformationService: ClientOcrInformationService) {
        this.ocrMessages$ = new BehaviorSubject<DocumentErrorMessages>(this.ocrMessages);
    }

    setAllValuesToTrue(): void {
        this.oneByOneValidations = {
            ficheFamilliale: true,
            extraitDeNaissance: true,
            residence: true,
            chifa: true,
            FichePaie1: true,
            FichePaie2: true,
            FichePaie3: true,
            cheque: true,
            cni: true,
            cni_verso: true,
            ccp: true,
            attestationDeTravail: true,
            retraite: true,
            faces: true,
            rena: true,
            radiation: true,
            releveDeCompte: true,
            incomeMilitary: true
        };
    }

    verifyDocuments(verification: VerificationFullObject): OneByOneValidations {
        this.setAllValuesToTrue()
        this.clientInformationService.loadingSocialProfessionalSubject.subscribe(change => {
            if (change) {
                this.clientInformationSosioProf = change
            }
        });
        if (verification) {
            this.setAllValuesToTrue();
            this.needSomeChanges$.next(null)
            this.documents$.subscribe(res => {
                if (res) {
                    Object.keys(verification).forEach((key) => {
                        switch (key) {
                            case 'cni': {
                                if (
                                    ((verification?.cni.document_modifie == false || null) &&
                                        (verification?.cni.erreur == null) &&
                                        (verification?.cni.right_document != false) &&
                                        (verification?.cni.cni_inf_10_ans != false)) ||
                                    res.find(
                                        (document) =>
                                            document.documentsGender === 'FRONT_IDENTITY_CARD'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.cni = true;
                                } else {
                                    this.oneByOneValidations.cni = false;
                                }
                                break;
                            }
                            case 'cni_verso': {
                                if (
                                    (verification.cni_verso?.erreur == null &&
                                        verification.cni_verso?.right_document != false) ||
                                    res.find(
                                        (document) =>
                                            document.documentsGender === 'BACK_IDENTITY_CARD'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.cni_verso = true;
                                } else {
                                    this.oneByOneValidations.cni_verso = false;
                                }
                                break;
                            }
                            // ext_nom_prenom_en_français manquante
                            case 'extrait_naissance': {
                                if (
                                    ((verification?.extrait_naissance.document_modifie == false || null) &&
                                        (verification?.extrait_naissance.erreur == null) &&
                                        (verification?.extrait_naissance.age_inf_70ans != false) &&
                                        (verification?.extrait_naissance.right_document != false) &&
                                        (verification?.extrait_naissance.acte_valid != false) &&
                                        (verification?.verification?.extrait_naissance != false)) ||
                                    res.find((document) => document.documentsGender === 'BIRTH_ACT')
                                        ?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.extraitDeNaissance = true;
                                } else {
                                    this.oneByOneValidations.extraitDeNaissance = false;
                                }
                                break;
                            }
                            case 'fiche_familiale': {
                                if (
                                    ((verification?.fiche_familiale.right_document != false) &&
                                        (verification?.fiche_familiale.fiche_inf_1mois != false) &&
                                        (verification?.verification?.fiche_familiale != false) &&
                                        (verification?.fiche_familiale.erreur == null) &&
                                        (verification?.fiche_familiale.document_modifie != true)) ||
                                    this.client?.socialClientCategory == 'SINGLE' ||
                                    res.find(
                                        (document) => document.documentsGender === 'FAMILY_SHEET'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.ficheFamilliale = true;
                                } else {
                                    this.oneByOneValidations.ficheFamilliale = false;
                                }
                                break;
                            }
                            case 'residence': {
                                if (
                                    ((verification?.residence.right_document != false) &&
                                        verification?.residence.erreur == null &&
                                        verification?.residence.document_modifie != true &&
                                        verification?.residence.fiche_inf_6mois != false &&
                                        (verification?.verification?.residence != false)) ||
                                    res.find((document) => document.documentsGender === 'RESIDENCE')
                                        ?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.residence = true;
                                } else {
                                    this.oneByOneValidations.residence = false;
                                }
                                break;
                            }
                            case 'carte_chifa': {
                                if (
                                    (verification?.carte_chifa.erreur == null &&
                                        (verification?.verification?.chifa != false) &&
                                        (verification?.carte_chifa?.document_modifie != true) &&
                                        (verification?.carte_chifa.right_document != false)) ||
                                    res.find(
                                        (document) => document.documentsGender === 'CARD_CHIFA'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.chifa = true;
                                } else {
                                    this.oneByOneValidations.chifa = false;
                                }
                                break;
                            }
                            case 'cheque': {
                                if (
                                    ((verification?.verification?.cheque != false) &&
                                        (verification?.cheque.document_modifie == false || null) &&
                                        verification?.cheque.erreur == null &&
                                        (verification?.cheque.right_document != false)) ||
                                    res.find(
                                        (document) => document.documentsGender === 'CROSSED_CHECK'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.cheque = true;
                                } else {
                                    this.oneByOneValidations.cheque = false;
                                }
                                break;
                            }
                            case 'ccp': {
                                if (
                                    ((verification?.ccp.duree_sup_3_mois_delivrance_min_1_mois != false) &&
                                        (verification?.verification?.ccp != false) &&
                                        (verification?.ccp.verify_calcul != false) &&
                                        (verification?.ccp.document_modifie == false || null) &&
                                        verification?.ccp.erreur == null &&
                                        verification?.ccp.taxe_206 != false &&
                                        verification?.ccp.Taxe_300_DZD_Detecte != false &&
                                        (verification?.ccp.right_document != false)) ||
                                    res.find(
                                        (document) => document.documentsGender === 'BANK_STATEMENT'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.ccp = true;
                                } else {
                                    this.oneByOneValidations.ccp = false;
                                }
                                break;
                            }
                            case 'fiche_de_paie_1': {
                                if (
                                    ((verification?.fiche_de_paie_1.verify_delivrance == true ||
                                            null) &&
                                        (verification?.verification?.fiche_paie_1 != false) &&
                                        (verification?.fiche_de_paie_1.verify_calcul != false) &&
                                        (verification?.fiche_de_paie_1.salaire_superieur_a_25000 != false) &&
                                        (verification?.fiche_de_paie_1.document_modifie == false || null) &&
                                        verification?.fiche_de_paie_1.erreur == null &&
                                        (verification?.fiche_de_paie_1.right_document != false)) ||
                                    this.client?.professionalClientCategory == 'RETRIED' ||
                                    res.find(
                                        (document) => document.documentsGender === 'BANK_STATEMENT'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.FichePaie1 = true;
                                } else {
                                    this.oneByOneValidations.FichePaie1 = false;
                                }
                                break;
                            }
                            case 'fiche_de_paie_2': {
                                if (
                                    ((verification?.fiche_de_paie_2.verify_delivrance == true ||
                                            null) &&
                                        (verification?.verification?.fiche_paie_2 != false) &&
                                        (verification?.fiche_de_paie_2.verify_calcul != false) &&
                                        (verification?.fiche_de_paie_2.salaire_superieur_a_25000 != false) &&
                                        (verification?.fiche_de_paie_2.document_modifie == false || null) &&
                                        verification?.fiche_de_paie_2.erreur == null &&
                                        (verification?.fiche_de_paie_2.right_document != false)) ||
                                    this.client?.professionalClientCategory == 'RETRIED' ||
                                    res.find(
                                        (document) => document.documentsGender === 'PAYSLIP_TWO'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.FichePaie2 = true;
                                } else {
                                    this.oneByOneValidations.FichePaie2 = false;
                                }
                                break;
                            }
                            case 'fiche_de_paie_3': {
                                if (
                                    ((verification?.fiche_de_paie_3.verify_delivrance == true ||
                                            null) &&
                                        (verification?.verification?.fiche_paie_2 != false) &&
                                        (verification?.fiche_de_paie_3.verify_calcul != false) &&
                                        (verification?.fiche_de_paie_3.salaire_superieur_a_25000 != false) &&
                                        (verification?.fiche_de_paie_3.document_modifie == false || null) &&
                                        verification?.fiche_de_paie_3.erreur == null &&
                                        (verification?.fiche_de_paie_3.right_document != false)) ||
                                    this.client?.professionalClientCategory == 'RETRIED' ||
                                    res.find(
                                        (document) => document.documentsGender === 'PAYSLIP_THREE'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.FichePaie3 = true;
                                } else {
                                    this.oneByOneValidations.FichePaie3 = false;
                                }
                                break;
                            }
                            case 'attestation': {
                                // fiche_inf_6mois manquante
                                if (
                                    ((verification?.verification?.attestation_de_travail == true ||
                                            null) &&
                                        (verification?.attestation.erreur == null) &&
                                        (verification?.attestation.document_modifie != false) &&
                                        (verification?.attestation.right_document != false)) ||
                                    this.client?.professionalClientCategory != 'EMPLOYEE' ||
                                    res.find(
                                        (document) => document.documentsGender === 'WORK_CERTIFICATE'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.attestationDeTravail = true;
                                } else {
                                    this.oneByOneValidations.attestationDeTravail = false;
                                }
                                break;
                            }
                            case 'retraite': {
                                //retraite_etabli_depuis_un_moi manquante
                                if (
                                    ((verification?.verification?.retraite != false) &&
                                        (verification?.retraite.right_document != false) &&
                                        (verification?.retraite.verify_calcul != false) &&
                                        (verification?.retraite.salaire_sup_25000 != false) &&
                                        (verification?.retraite.document_modifie == false || null) &&
                                        verification?.retraite.erreur == null) ||
                                    this.clientInformationSosioProf?.professionalClientCategory !=
                                    'RETRIED' ||
                                    res.find(
                                        (document) =>
                                            document.documentsGender === 'INCOME_CERTIFICATE'
                                    )?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.retraite = true;
                                } else {
                                    this.oneByOneValidations.retraite = false;
                                }
                                break;
                            }
                            case 'rena': {
                                if (
                                    (verification?.verification?.rena != false &&
                                        verification?.rena?.right_document != false &&
                                        verification?.rena?.document_modifie != true &&
                                        verification?.rena?.erreur == null) ||
                                    this.clientInformationSosioProf?.professionalClientCategory !=
                                    'RETRIED' ||
                                    res.find((document) => document.documentsGender === 'RENA')
                                        ?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.rena = true;
                                } else {
                                    this.oneByOneValidations.rena = false;
                                }
                                break;
                            }
                            case 'radiation': {
                                if (
                                    (verification?.verification?.radiation != false &&
                                        verification?.radiation?.right_document != false &&
                                        verification?.radiation?.document_modifie != true &&
                                        verification?.radiation?.erreur == null) ||
                                    this.clientInformationSosioProf?.professionalClientCategory !=
                                    'MILITARY_RETIRED' ||
                                    res.find((document) => document.documentsGender === 'TERMINATION_CERTIFICATE')
                                        ?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.radiation = true;
                                } else {
                                    this.oneByOneValidations.radiation = false;
                                }
                                break;
                            }
                            case 'attestation_revenue_militaire': {
                                if (
                                    (verification?.verification?.attestation_revenu_militaire != false &&
                                        verification?.attestation_revenue_militaire?.right_document != false &&
                                        verification?.attestation_revenue_militaire?.erreur == null &&
                                        verification?.attestation_revenue_militaire?.document_modifie != true &&
                                        verification?.attestation_revenue_militaire.verify_delivrance != false) ||
                                    (this.clientInformationSosioProf?.professionalClientCategory ==
                                        'EMPLOYEE') ||
                                    (this.clientInformationSosioProf?.professionalClientCategory ==
                                        'RETRIED') ||
                                    res.find((document) => document.documentsGender === 'INCOME_CERTIFICATE_MILITARY')
                                        ?.documentsState !== 'PENDING'
                                ) {
                                    this.oneByOneValidations.incomeMilitary = true;
                                } else {
                                    this.oneByOneValidations.incomeMilitary = false;
                                }
                                break;
                            }
                        }
                        //verify if all documents are checked
                        if (this.areAllChecked(this.oneByOneValidations)) {
                            this.needSomeChanges$.next(false)
                            this.needSomeChanges = false;
                        } else {
                            this.needSomeChanges = true;
                            this.needSomeChanges$.next(true)
                        }
                    });
                }
            });

        }
        return this.oneByOneValidations;
    }

    areAllChecked(obj: any): boolean {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] !== true) {
                return false;
            }
        }
        return true;
    }


    getMessages(verification: VerificationFullObject) {
        this.initializeMessage();
        this.clientInformationService.loadingSocialProfessionalSubject.subscribe(change => {
            if (change) {
                this.clientInformationSosioProf = change
            }
        });
        if (verification) {
            this.documents$.subscribe(documents => {
                if (documents) {
                    Object.keys(verification).forEach((key) => {
                        switch (key) {
                            case 'cni': {
                                if (documents.find((document) => document.documentsGender === 'FRONT_IDENTITY_CARD')?.documentsState === 'PENDING') {
                                    if (verification?.cni?.cni_inf_10_ans == false) {
                                        this.ocrMessages.cni!.cni_inf_10_ans.error = true;
                                        this.ocrMessages.cni!.cni_inf_10_ans.message =
                                            "Cette CNI est expirée.";
                                    }
                                    if (verification.cni?.right_document == false) {
                                        this.ocrMessages.cni!.right_document.error = true;
                                        this.ocrMessages.cni!.right_document.message =
                                            "Cette document n'est pas une CNI.";
                                    }
                                    if (verification?.cni?.erreur == 'Document illisible') {
                                        this.ocrMessages.cni!.illisible.error = true;
                                        this.ocrMessages.cni!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification?.cni.document_modifie == true) {
                                        this.ocrMessages.cni!.document_modifie.error = true;
                                        this.ocrMessages.cni!.document_modifie.message =
                                            'Cette CNI a été modifié.';
                                    }
                                }
                                break;
                            }
                            case 'cni_verso': {
                                if (documents.find((document) => document.documentsGender === 'BACK_IDENTITY_CARD')?.documentsState === 'PENDING') {
                                    if (verification.cni_verso?.right_document == false) {
                                        this.ocrMessages.cni_verso!.right_document.error = true;
                                        this.ocrMessages.cni_verso!.right_document.message =
                                            "Ce document n'est pas une CNI.";
                                    }
                                    if (verification?.cni_verso?.erreur == 'Document illisible') {
                                        this.ocrMessages.cni_verso!.illisible.error = true;
                                        this.ocrMessages.cni_verso!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                }
                                break;
                            }
                            case 'extrait_naissance': {
                                if (documents.find((document) => document.documentsGender === 'BIRTH_ACT')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.socialClientCategory !== 'SINGLE'
                                ) {
                                    if (verification?.extrait_naissance?.acte_valid == false) {
                                        this.ocrMessages.extrait_naissance!.acte_valid.error = true;
                                        this.ocrMessages.extrait_naissance!.acte_valid.message =
                                            'Votre extrait de naissance est superieur à 1 an.';
                                    }
                                    if (verification?.extrait_naissance?.right_document == false) {
                                        this.ocrMessages.extrait_naissance!.right_document.error = true;
                                        this.ocrMessages.extrait_naissance!.right_document.message =
                                            "Ce document n'est pas un Extrait de naissance.";
                                    }
                                    if (verification?.verification?.extrait_naissance == false) {
                                        this.ocrMessages.extrait_naissance!.right_document.error = true;
                                        this.ocrMessages.extrait_naissance!.right_document.message =
                                            "Cet extrait de naissance n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification?.extrait_naissance.document_modifie == true) {
                                        this.ocrMessages.extrait_naissance!.document_modifie.error = true;
                                        this.ocrMessages.extrait_naissance!.document_modifie.message =
                                            "Cet extrait de naissance a été modifié.";
                                    }
                                    if (verification?.extrait_naissance.age_inf_70ans == false) {
                                        this.ocrMessages.extrait_naissance!.age_inf_70ans.error = true;
                                        this.ocrMessages.extrait_naissance!.age_inf_70ans.message =
                                            "L'age n'est pas inférieur à 70 ans.";
                                    }
                                }
                                if (
                                    verification?.extrait_naissance?.erreur == 'Document illisible'
                                ) {
                                    this.ocrMessages.extrait_naissance!.illisible.error = true;
                                    this.ocrMessages.extrait_naissance!.illisible.message =
                                        'Ce document est illisible.';
                                }
                                break;
                            }
                            case 'fiche_familiale': {
                                if (documents.find((document) => document.documentsGender === 'FAMILY_SHEET')?.documentsState === 'PENDING') {
                                    if (verification?.fiche_familiale?.fiche_inf_1mois == false) {
                                        this.ocrMessages.fiche_familiale!.fiche_inf_1mois.error = true;
                                        this.ocrMessages.fiche_familiale!.fiche_inf_1mois.message =
                                            'Votre fiche familiale est superieur à 1 mois.';
                                    }
                                    if (verification?.fiche_familiale?.right_document == false) {
                                        this.ocrMessages.fiche_familiale!.right_document.error = true;
                                        this.ocrMessages.fiche_familiale!.right_document.message =
                                            "Ce document n'est pas une fiche familiale.";
                                    }
                                    if (verification.verification?.fiche_familiale == false) {
                                        this.ocrMessages.fiche_familiale!.right_document.error = true;
                                        this.ocrMessages.fiche_familiale!.right_document.message =
                                            "Cette fiche familiale n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.fiche_familiale?.erreur == 'Document illisible') {
                                        this.ocrMessages.fiche_familiale!.illisible.error = true;
                                        this.ocrMessages.fiche_familiale!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.fiche_familiale?.document_modifie == true) {
                                        this.ocrMessages.fiche_familiale!.document_modifie.error = true;
                                        this.ocrMessages.fiche_familiale!.document_modifie.message =
                                            'Cette fiche familiale a été modifié.';
                                    }
                                }
                                break;
                            }
                            case 'residence': {
                                if (documents.find((document) => document.documentsGender === 'RESIDENCE')?.documentsState === 'PENDING') {
                                    if (verification?.residence?.right_document == false) {
                                        this.ocrMessages.residence!.right_document.error = true;
                                        this.ocrMessages.residence!.right_document.message =
                                            "Ce document n'est pas une residence.";
                                    }
                                    if (verification.verification?.residence == false) {
                                        this.ocrMessages.residence!.residence.error = true;
                                        this.ocrMessages.residence!.residence.message =
                                            "Cette residence n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.residence?.erreur == 'Document illisible') {
                                        this.ocrMessages.residence!.illisible.error = true;
                                        this.ocrMessages.residence!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.residence?.document_modifie == true) {
                                        this.ocrMessages.residence!.document_modifie.error = true;
                                        this.ocrMessages.residence!.document_modifie.message =
                                            'Cette residence a été modifié.';
                                    }
                                    if (verification.residence?.fiche_inf_6mois == false) {
                                        this.ocrMessages.residence!.fiche_inf_6mois.error = true;
                                        this.ocrMessages.residence!.fiche_inf_6mois.message =
                                            "Cette residence n'est pas inferieur à 6 mois.";
                                    }
                                }
                                break;
                            }
                            case 'carte_chifa': {
                                if (documents.find((document) => document.documentsGender === 'CARD_CHIFA')?.documentsState === 'PENDING') {
                                    if (verification?.carte_chifa?.right_document == false) {
                                        this.ocrMessages.chifa!.right_document.error = true;
                                        this.ocrMessages.chifa!.right_document.message =
                                            "Ce document n'est pas une carte chifa.";
                                    }
                                    if (verification.verification?.chifa == false) {
                                        this.ocrMessages.chifa!.chifa.error = true;
                                        this.ocrMessages.chifa!.chifa.message =
                                            "Cette carte chifa n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.carte_chifa?.erreur == 'Document illisible') {
                                        this.ocrMessages.chifa!.illisible.error = true;
                                        this.ocrMessages.chifa!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.carte_chifa?.document_modifie == true) {
                                        this.ocrMessages.chifa!.document_modifie.error = true;
                                        this.ocrMessages.chifa!.document_modifie.message =
                                            'Cette carte chifa a été modifié.';
                                    }
                                }
                                break;
                            }
                            case 'cheque': {
                                if (documents.find((document) => document.documentsGender === 'CROSSED_CHECK')?.documentsState === 'PENDING') {
                                    if (verification?.cheque?.right_document == false) {
                                        this.ocrMessages.cheque!.right_document.error = true;
                                        this.ocrMessages.cheque!.right_document.message =
                                            "Ce document n'est pas un cheque barré.";
                                    }
                                    if (verification.verification?.cheque == false) {
                                        this.ocrMessages.cheque!.cheque.error = true;
                                        this.ocrMessages.cheque!.cheque.message =
                                            "Ce cheque n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.carte_chifa?.erreur == 'Document illisible') {
                                        this.ocrMessages.cheque!.illisible.error = true;
                                        this.ocrMessages.cheque!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.cheque?.document_modifie == true) {
                                        this.ocrMessages.cheque!.document_modifie.error = true;
                                        this.ocrMessages.cheque!.document_modifie.message =
                                            'Ce cheque a été modifié.';
                                    }
                                }
                                break;
                            }
                            case 'ccp': {
                                if (documents.find((document) => document.documentsGender === 'BANK_STATEMENT')?.documentsState === 'PENDING') {
                                    if (verification?.ccp?.right_document == false) {
                                        this.ocrMessages.ccp!.right_document.error = true;
                                        this.ocrMessages.ccp!.right_document.message =
                                            "Ce document n'est pas un relevé bancaire ou CCP.";
                                    }
                                    if (verification.verification?.ccp == false) {
                                        this.ocrMessages.ccp!.ccp.error = true;
                                        this.ocrMessages.ccp!.ccp.message =
                                            "Ce relevé bancaire ou CCP n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.ccp?.erreur == 'Document illisible') {
                                        this.ocrMessages.ccp!.illisible.error = true;
                                        this.ocrMessages.ccp!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.ccp?.verify_calcul == false) {
                                        this.ocrMessages.ccp!.verify_calcul.error = true;
                                        this.ocrMessages.ccp!.verify_calcul.message =
                                            "Le calcul n'est pas correcte.";
                                    }
                                    if (verification.ccp?.document_modifie == true) {
                                        this.ocrMessages.ccp!.document_modifie.error = true;
                                        this.ocrMessages.ccp!.document_modifie.message =
                                            'Ce relevé bancaire a été modifié.';
                                    }
                                    if (
                                        verification.ccp?.duree_sup_3_mois_delivrance_min_1_mois == false
                                    ) {
                                        this.ocrMessages.ccp!.duree_sup_3_mois_delivrance_min_1_mois.error =
                                            true;
                                        this.ocrMessages.ccp!.duree_sup_3_mois_delivrance_min_1_mois.message =
                                            'Ce relevé bancaire ou CCP est expiré.';
                                    }
                                        if (
                                        verification.ccp?.taxe_206 == true
                                    ) {
                                        this.ocrMessages.ccp!.taxe_206.error =
                                            true;
                                        this.ocrMessages.ccp!.taxe_206.message =
                                            'Code 206 Détecté.';
                                    }
                                    if (
                                        verification.ccp?.Taxe_300_DZD_Detecte == true
                                    ) {
                                        this.ocrMessages.ccp!.Taxe_300_DZD_Detecte.error =
                                            true;
                                        this.ocrMessages.ccp!.Taxe_300_DZD_Detecte.message =
                                            ' Taxe de 300 DZD Détectée.';
                                    }

                                }
                                break;
                            }
                            case 'fiche_de_paie_1': {
                                if (documents.find((document) => document.documentsGender === 'PAY_SLIP')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'EMPLOYEE'
                                ) {
                                    if (verification?.fiche_de_paie_1?.right_document == false) {
                                        this.ocrMessages.fiche_paie_1!.right_document.error = true;
                                        this.ocrMessages.fiche_paie_1!.right_document.message =
                                            "Ce documentn'est pas une fiche de paie.";
                                    }
                                    if (verification.verification?.fiche_paie_1 == false) {
                                        this.ocrMessages.fiche_paie_1!.fiche_paie_1.error = true;
                                        this.ocrMessages.fiche_paie_1!.fiche_paie_1.message =
                                            "Cette fiche de paie n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.fiche_de_paie_1?.erreur == 'Document illisible') {
                                        this.ocrMessages.fiche_paie_1!.illisible.error = true;
                                        this.ocrMessages.fiche_paie_1!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.fiche_de_paie_1?.verify_delivrance == false) {
                                        this.ocrMessages.fiche_paie_1!.verify_delivrance.error = true;
                                        this.ocrMessages.fiche_paie_1!.verify_delivrance.message =
                                            'Cette fiche de paie est expirée.';
                                    }
                                    if (verification.fiche_de_paie_1?.verify_calcul == false) {
                                        this.ocrMessages.fiche_paie_1!.verify_calcul.error = true;
                                        this.ocrMessages.fiche_paie_1!.verify_calcul.message =
                                            "Le calcul n'est pas corercte.";
                                    }
                                    if (verification.fiche_de_paie_1?.salaire_superieur_a_25000 == false) {
                                        this.ocrMessages.fiche_paie_1!.salaire_superieur_a_25000.error = true;
                                        this.ocrMessages.fiche_paie_1!.salaire_superieur_a_25000.message =
                                            "Le salaire n'est pas superieur & 25000 DZD.";
                                    }
                                    if (verification.fiche_de_paie_1?.document_modifie == true) {
                                        this.ocrMessages.fiche_paie_1!.document_modifie.error = true;
                                        this.ocrMessages.fiche_paie_1!.document_modifie.message =
                                            "Cette fiche de paie a été modifié.";
                                    }
                                }
                                break;
                            }
                            case 'fiche_de_paie_2': {
                                if (documents.find((document) => document.documentsGender === 'PAYSLIP_TWO')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'EMPLOYEE'
                                ) {
                                    if (verification?.fiche_de_paie_2?.right_document == false) {
                                        this.ocrMessages.fiche_paie_2!.right_document.error = true;
                                        this.ocrMessages.fiche_paie_2!.right_document.message =
                                            "Ce documentn'est pas une fiche de paie.";
                                    }
                                    if (verification.verification?.fiche_paie_2 == false) {
                                        this.ocrMessages.fiche_paie_2!.fiche_paie_2.error = true;
                                        this.ocrMessages.fiche_paie_2!.fiche_paie_2.message =
                                            "Cette fiche de paie n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.fiche_de_paie_2?.erreur == 'Document illisible') {
                                        this.ocrMessages.fiche_paie_2!.illisible.error = true;
                                        this.ocrMessages.fiche_paie_2!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.fiche_de_paie_2?.verify_delivrance == false) {
                                        this.ocrMessages.fiche_paie_2!.verify_delivrance.error = true;
                                        this.ocrMessages.fiche_paie_2!.verify_delivrance.message =
                                            'Cette fiche de paie est expirée.';
                                    }
                                    if (verification.fiche_de_paie_2?.verify_calcul == false) {
                                        this.ocrMessages.fiche_paie_2!.verify_calcul.error = true;
                                        this.ocrMessages.fiche_paie_2!.verify_calcul.message =
                                            "Le calcul n'est pas corercte.";
                                    }
                                    if (verification.fiche_de_paie_2?.salaire_superieur_a_25000 == false) {
                                        this.ocrMessages.fiche_paie_2!.salaire_superieur_a_25000.error = true;
                                        this.ocrMessages.fiche_paie_2!.salaire_superieur_a_25000.message =
                                            "Le salaire n'est pas superieur & 25000 DZD.";
                                    }
                                    if (verification.fiche_de_paie_2?.document_modifie == true) {
                                        this.ocrMessages.fiche_paie_2!.document_modifie.error = true;
                                        this.ocrMessages.fiche_paie_2!.document_modifie.message =
                                            "Cette fiche de paie a été modifié.";
                                    }
                                }
                                break;
                            }
                            case 'fiche_de_paie_3': {
                                if (documents.find((document) => document.documentsGender === 'PAYSLIP_THREE')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'EMPLOYEE'
                                ) {
                                    if (verification?.fiche_de_paie_3?.right_document == false) {
                                        this.ocrMessages.fiche_paie_3!.right_document.error = true;
                                        this.ocrMessages.fiche_paie_3!.right_document.message =
                                            "Ce documentn'est pas une fiche de paie.";
                                    }
                                    if (verification.verification?.fiche_paie_3 == false) {
                                        this.ocrMessages.fiche_paie_3!.fiche_paie_3.error = true;
                                        this.ocrMessages.fiche_paie_3!.fiche_paie_3.message =
                                            "Cette fiche de paie n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.fiche_de_paie_3?.erreur == 'Document illisible') {
                                        this.ocrMessages.fiche_paie_3!.illisible.error = true;
                                        this.ocrMessages.fiche_paie_3!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.fiche_de_paie_3?.verify_delivrance == false) {
                                        this.ocrMessages.fiche_paie_3!.verify_delivrance.error = true;
                                        this.ocrMessages.fiche_paie_3!.verify_delivrance.message =
                                            'Cette fiche de paie est expirée.';
                                    }
                                    if (verification.fiche_de_paie_3?.verify_calcul == false) {
                                        this.ocrMessages.fiche_paie_3!.verify_calcul.error = true;
                                        this.ocrMessages.fiche_paie_3!.verify_calcul.message =
                                            "Le calcul n'est pas corercte.";
                                    }
                                    if (verification.fiche_de_paie_3?.salaire_superieur_a_25000 == false) {
                                        this.ocrMessages.fiche_paie_3!.salaire_superieur_a_25000.error = true;
                                        this.ocrMessages.fiche_paie_3!.salaire_superieur_a_25000.message =
                                            "Le salaire n'est pas superieur & 25000 DZD.";
                                    }
                                    if (verification.fiche_de_paie_3?.document_modifie == true) {
                                        this.ocrMessages.fiche_paie_3!.document_modifie.error = true;
                                        this.ocrMessages.fiche_paie_3!.document_modifie.message =
                                            "Cette fiche de paie a été modifié.";
                                    }
                                }
                                break;
                            }
                            case 'attestation': {
                                if (documents.find((document) => document.documentsGender === 'WORK_CERTIFICATE')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'EMPLOYEE'
                                ) {
                                    if (verification?.attestation?.right_document == false) {
                                        this.ocrMessages.attestation!.right_document.error = true;
                                        this.ocrMessages.attestation!.right_document.message =
                                            "Ce documentn'est pas une attestation de travail.";
                                    }
                                    if (verification.verification?.attestation_de_travail == false) {
                                        this.ocrMessages.attestation!.attestation.error = true;
                                        this.ocrMessages.attestation!.attestation.message =
                                            "cette attestation de travail n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification.attestation?.erreur == 'Document illisible') {
                                        this.ocrMessages.attestation!.illisible.error = true;
                                        this.ocrMessages.attestation!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification.attestation?.document_modifie == true) {
                                        this.ocrMessages.attestation!.document_modifie.error = true;
                                        this.ocrMessages.attestation!.document_modifie.message =
                                            "Cette attestation de travail a été modifié.";
                                    }
                                }
                                break;
                            }
                            case 'retraite': {
                                if (documents.find((document) => document.documentsGender === 'INCOME_CERTIFICATE')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'RETRIED'
                                ) {
                                    if (verification?.retraite?.right_document == false) {
                                        this.ocrMessages.retraite!.right_document.error = true;
                                        this.ocrMessages.retraite!.right_document.message =
                                            "Ce document n'est pas une attestation de Revenue.";
                                    }
                                    if (verification?.retraite?.document_modifie == true) {
                                        this.ocrMessages.retraite!.document_modifie.error = true;
                                        this.ocrMessages.retraite!.document_modifie.message =
                                            "Cette attestation des revenus a été modifié.";
                                    }
                                    if (verification?.retraite?.verify_calcul == false) {
                                        this.ocrMessages.retraite!.verify_calcul.error = true;
                                        this.ocrMessages.retraite!.verify_calcul.message =
                                            "Le calcul est incorrect.";
                                    }
                                    if (verification?.retraite?.salaire_sup_25000 == false) {
                                        this.ocrMessages.retraite!.salaire_sup_25000.error = true;
                                        this.ocrMessages.retraite!.salaire_sup_25000.message =
                                            "Le salaire n'est pas superieur à 25000 DZD.";
                                    }
                                    if (verification?.verification?.retraite == false) {
                                        this.ocrMessages.retraite!.retraite.error = true;
                                        this.ocrMessages.retraite!.retraite.message =
                                            "cette attestation de Revenue n'appartient pas à la même personne que la CNI.";
                                    }
                                    if (verification?.retraite?.erreur == 'Document illisible') {
                                        this.ocrMessages.retraite!.illisible.error = true;
                                        this.ocrMessages.retraite!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                }
                                break;
                            }
                            case 'rena': {
                                if (documents.find((document) => document.documentsGender === 'RENA')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'RETRIED'
                                ) {
                                    if (verification?.rena?.right_document == false) {
                                        this.ocrMessages.rena!.right_document.error = true;
                                        this.ocrMessages.rena!.right_document.message =
                                            "Ce document n'est pas une RENA.";
                                    }
                                    if (verification?.verification?.rena == false) {
                                        this.ocrMessages.rena!.rena.error = true;
                                        this.ocrMessages.rena!.rena.message = "Cette RENA n'appartient pas à la même personne que la CNI."
                                    }
                                    if (verification?.rena?.erreur == 'Document illisible') {
                                        this.ocrMessages.rena!.illisible.error = true;
                                        this.ocrMessages.rena!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification?.rena?.document_modifie == true) {
                                        this.ocrMessages.rena!.document_modifie.error = true;
                                        this.ocrMessages.rena!.document_modifie.message =
                                            "Cette RENA a été modifié.";
                                    }
                                }
                                break;
                            }
                            case 'radiation': {
                                if (documents.find((document) => document.documentsGender === 'TERMINATION_CERTIFICATE')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'MILITARY_RETIRED'
                                ) {
                                    if (verification?.radiation?.right_document == false) {
                                        this.ocrMessages.radiation!.right_document.error = true;
                                        this.ocrMessages.radiation!.right_document.message =
                                            "Ce document n'est pas une attestation de radiation.";
                                    }
                                    if (verification?.verification?.radiation == false) {
                                        this.ocrMessages.radiation!.radiation.error = true;
                                        this.ocrMessages.radiation!.radiation.message = "Ce document n'appartient pas à la même personne que la CNI."
                                    }
                                    if (verification?.radiation?.erreur == 'Document illisible') {
                                        this.ocrMessages.radiation!.illisible.error = true;
                                        this.ocrMessages.radiation!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification?.radiation?.document_modifie == true) {
                                        this.ocrMessages.radiation!.document_modifie.error = true;
                                        this.ocrMessages.radiation!.document_modifie.message =
                                            "Cette attestation de radiation a été modifié.";
                                    }
                                }
                                break;
                            }
                            case 'attestation_revenue_militaire': {
                                if (documents.find((document) => document.documentsGender === 'INCOME_CERTIFICATE_MILITARY')?.documentsState === 'PENDING' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'MILITARY_RETIRED' ||
                                    this.clientInformationSosioProf?.professionalClientCategory === 'MILITARY'
                                ) {
                                    if (verification?.attestation_revenue_militaire?.right_document == false) {
                                        this.ocrMessages.attestation_revenue_militaire!.right_document.error = true;
                                        this.ocrMessages.attestation_revenue_militaire!.right_document.message =
                                            "Ce document n'est pas une attestation de revenus.";
                                    }
                                    if (verification?.attestation_revenue_militaire?.verify_delivrance == false) {
                                        this.ocrMessages.attestation_revenue_militaire!.verify_delivrance.error = true;
                                        this.ocrMessages.attestation_revenue_militaire!.verify_delivrance.message = "Cette attestation de revenu est expirée."
                                    }
                                    if (verification?.verification?.attestation_revenu_militaire == false) {
                                        this.ocrMessages.attestation_revenue_militaire!.verify_delivrance.error = true;
                                        this.ocrMessages.attestation_revenue_militaire!.verify_delivrance.message = "Cette attestation de revenu n'appartient pas à la même personne que la CNI."
                                    }
                                    if (verification?.attestation_revenue_militaire?.erreur == 'Document illisible') {
                                        this.ocrMessages.attestation_revenue_militaire!.illisible.error = true;
                                        this.ocrMessages.attestation_revenue_militaire!.illisible.message =
                                            'Ce document est illisible.';
                                    }
                                    if (verification?.attestation_revenue_militaire?.document_modifie == true) {
                                        this.ocrMessages.attestation_revenue_militaire!.document_modifie.error = true;
                                        this.ocrMessages.attestation_revenue_militaire!.document_modifie.message =
                                            "Cette attestation des revenus a été modifié.";
                                    }
                                }
                                break;
                            }
                        }
                    });
                    this.ocrMessages$.next(this.ocrMessages);
                }
            })
        }
    }

    initializeMessage() {
        this.ocrMessages = {
            cni: {
                cni_inf_10_ans: {error: false, message: ''},
                right_document: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            cni_verso: {
                right_document: {error: false, message: ''},
                illisible: {error: false, message: ''},
            },
            extrait_naissance: {
                acte_valid: {error: false, message: ''},
                right_document: {error: false, message: ''},
                extrait_naissance: {error: false, message: ''},
                age_inf_70ans: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            fiche_familiale: {
                fiche_inf_1mois: {error: false, message: ''},
                right_document: {error: false, message: ''},
                fiche_familiale: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            residence: {
                right_document: {error: false, message: ''},
                residence: {error: false, message: ''},
                fiche_inf_6mois: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            chifa: {
                right_document: {error: false, message: ''},
                chifa: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            cheque: {
                right_document: {error: false, message: ''},
                cheque: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            ccp: {
                duree_sup_3_mois_delivrance_min_1_mois: {error: false, message: ''},
                right_document: {error: false, message: ''},
                ccp: {error: false, message: ''},
                illisible: {error: false, message: ''},
                verify_calcul: {error: false, message: ''},
                document_modifie: {error: false, message: ''},
                taxe_206: {error: false, message: ''},
                Taxe_300_DZD_Detecte: {error: false, message: ''},
            },
            fiche_paie_1: {
                verify_delivrance: {error: false, message: ''},
                right_document: {error: false, message: ''},
                fiche_paie_1: {error: false, message: ''},
                illisible: {error: false, message: ''},
                verify_calcul: {error: false, message: ''},
                salaire_superieur_a_25000: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            fiche_paie_2: {
                verify_delivrance: {error: false, message: ''},
                right_document: {error: false, message: ''},
                fiche_paie_2: {error: false, message: ''},
                illisible: {error: false, message: ''},
                verify_calcul: {error: false, message: ''},
                salaire_superieur_a_25000: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            fiche_paie_3: {
                verify_delivrance: {error: false, message: ''},
                right_document: {error: false, message: ''},
                fiche_paie_3: {error: false, message: ''},
                illisible: {error: false, message: ''},
                verify_calcul: {error: false, message: ''},
                salaire_superieur_a_25000: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            attestation: {
                right_document: {error: false, message: ''},
                attestation: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            retraite: {
                right_document: {error: false, message: ''},
                retraite: {error: false, message: ''},
                illisible: {error: false, message: ''},
                verify_calcul: {error: false, message: ''},
                salaire_sup_25000: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            rena: {
                right_document: {error: false, message: ''},
                rena: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            radiation: {
                right_document: {error: false, message: ''},
                radiation: {error: false, message: ''},
                illisible: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            },
            attestation_revenue_militaire: {
                right_document: {error: false, message: ''},
                illisible: {error: false, message: ''},
                attestation_revenu_militaire: {error: false, message: ''},
                verify_delivrance: {error: false, message: ''},
                document_modifie: {error: false, message: ''}
            }
        };
        this.ocrMessages$.next(null as any)
    }
}
