import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker, MatDialogRef } from '@angular/material';
import {MonthlyPaymentPipe} from "../../../../../core/_base/layout/pipes/monthlyPayment.pipe"; 

@Component({
  selector: 'kt-simulator-modal',
  templateUrl: './simulator-modal.component.html',
  styleUrls: ['./simulator-modal.component.scss'],
  providers: [MonthlyPaymentPipe]
})
export class SimulatorModalComponent implements OnInit {
  @ViewChild('datePicker00') datePicker00!: MatDatepicker<Date>;

  paymentValue = 12;
  paymentOptions = {
    floor: 12,
    ceil: 60,
    showSelectionBar: true,
  };
  
  limitePanier: number = 0;
  salary = 0;
  monthlyPayement= 0;
  total = 0;
  
  clientForm: FormGroup = this.fb.group({
    date: new FormControl ('',),
    itemPrice: new FormControl ('',),
    salary: new FormControl ( '',
      Validators.compose([, Validators.min(25000)])) });

  constructor(
    public dialogRef: MatDialogRef<SimulatorModalComponent>,
    private fb: FormBuilder,
    private monthlyPay: MonthlyPaymentPipe
  ) { }

  ngOnInit(): void {
    let validatorsUpdated = false;

    this.clientForm.get('itemPrice').valueChanges.subscribe(value => {
      this.total = value;
      console.log('Total : ', this.total);
    
      if (value <= this.limitePanier) {
        this.sim();
      } else if (value > this.limitePanier && !validatorsUpdated) {
        validatorsUpdated = true; // Set flag before updating validators
        this.clientForm.get('itemPrice').setValidators([Validators.max(this.limitePanier)]);
        this.clientForm.get('itemPrice').updateValueAndValidity();
        validatorsUpdated = false; // Reset flag after updating
      }
    });
    this.clientForm.get('salary').valueChanges.subscribe(value => {
      this.salary = value
      if(value > 25000 && value * 0.28 * this.paymentValue < 1000000) {
        this.limitePanier = value * 0.28 * this.paymentValue
      } else if(value * 0.28 * this.paymentValue >= 1000000)  this.limitePanier = 1000000
    })
  }

  formatLabel(value: number) {
    return value + ' months';
  }

  radioChange(event : any) {
    this.paymentValue = event
    if(this.salary * 0.28 * this.paymentValue <= 1000000) {
      this.limitePanier = this.salary * 0.28 * this.paymentValue
      this.sim()
    } else {
      this.limitePanier = 1000000
    }
  }

  sim() {
    this.monthlyPayement = +(this.monthlyPay.transform(Number(this.total) , this.paymentValue)).toFixed(2);
  }

}
