import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from "@angular/material/datepicker";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { GlobalVariableService } from "../../../../../core/ek-e-commerce/ek-services/global-variable.service";
import { GlobalVariableModel } from "../../../../../core/ek-e-commerce/ek-models/globalVariable.model";
import {EkSnackBarService} from "../../../../../core/services/ek-snackBar.service";

@Component({
  selector: 'kt-global-variable',
  templateUrl: './global-variable.component.html',
  styleUrls: ['./global-variable.component.scss']
})
export class GlobalVariableComponent implements OnInit {

  constructor(private fb: FormBuilder, private globalVariableService: GlobalVariableService,private ekSnackbarService: EkSnackBarService) { }

  @ViewChild('startDate') startDate!: MatDatepicker<Date>;
  @ViewChild('endDate') endDate!: MatDatepicker<Date>;
  formArray: FormArray = this.fb.array([]);
  newFormAdded: boolean = true;
  showAddSave: boolean = false;

  ngOnInit(): void {
    this.getAllGlobalVariables();
  }

  openDatePickerStart(datepicker: MatDatepicker<any>): void {
    if (datepicker) {
      datepicker.open();
    }
  }

  openDatePickerEnd(datepicker: MatDatepicker<any>): void {
    if (datepicker) {
      datepicker.open();
    }
  }


  addForm(): void {
    this.showAddSave = true;
    this.newFormAdded = false;
    const formGroup = this.fb.group({
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        rate: ['', Validators.required]
      });
      this.formArray.push(formGroup);
  }

  onSubmit(index: number): void {

    const formGroup = this.formArray.at(index);
    if (formGroup.valid) {
      const formValue = formGroup.value;
      const globalVariable = new GlobalVariableModel();
      globalVariable.annualRate = formValue.rate;
      globalVariable.startDate = formValue.startDate;
      globalVariable.endDate = formValue.endDate;

      this.globalVariableService.createGlobalVariable(globalVariable).subscribe(() => {

        const msg= 'ajouté avec succès';
        this.ekSnackbarService.openSnackBar(msg, ' ', 'success-snackbar');
        this.newFormAdded = true;
        this.showAddSave = false;
      });

    } else {

      const msg= 'Une erreur lors de l\'ajout';
      this.ekSnackbarService.openSnackBar(msg, ' ', 'error-snackbar');
    }
  }


  getAllGlobalVariables() {

    this.globalVariableService.getAllGlobalVariable().subscribe({
      next: (res) => {
        const data = res.body;
        data.forEach((item) => {
          const formGroup = this.fb.group({
            startDate: [this.formatDate(item.startDate), Validators.required],
            endDate: [this.formatDate(item.endDate), Validators.required],
            rate: [item.annualRate, Validators.required]
          });
          this.formArray.push(formGroup);
        });
      },
      error: (error: any) => {
        console.error('Error fetching global variables:', error);
      }
    });

  }

  formatDate(inputDateString: string): string {
    if (inputDateString) {
      return inputDateString.split('T')[0];
    } else {
      return '';
    }
  }


}
