import {CreditAnalystModel} from './../ek-e-commerce/ek-models/credit-analyst.model';
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../auth";
import {ADMIN_CONFIG} from "../../../environments/environment";
import {Response} from "../_base/crud/models/response";
import {map} from "rxjs/operators";
import {Page} from "../_base/crud/models/page";

@Injectable()
export class UserService {
    url = "ws-users/";

    _userId: number;
    basicUrl = '';

    public _userSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    set userId(id: number) {
        this._userId = id
    }

    get userId(): number {
        return this._userId
    }

    selectedUserRolesSubject = new BehaviorSubject<string>('');
    readonly userRoles$ = this.selectedUserRolesSubject.asObservable();

    constructor(private http: HttpClient) {

        this.basicUrl = `${ADMIN_CONFIG.apiEndpoint}ws-users`;

    }

    getUserByEmail(): Observable<User> {

        const user$ = this.http
            .get<Response<User>>(
                ADMIN_CONFIG.apiEndpoint + this.url + "email"
            )
            .pipe(map(({body}) => body));

        return user$;
    }

    getCreditAnalystList() {
        const url = `${this.basicUrl}/creditAnalyst`
        return this.http.get<Response<CreditAnalystModel[]>>(url).pipe(map(({body}) => body))
    }

    getUserByUsername(username: string): Observable<User> {
        const user$ = this.http
            .get<Response<User>>(
                ADMIN_CONFIG.apiEndpoint + this.url + "username/" + username
            )
            .pipe(map(({body}) => body));
        user$.subscribe((res) => {
            this._userSubject$.next(res.id);
            this.userId = res.id
        });
        return user$;
    }

    findAll(): Observable<User[]> {
        const users$ = this.http
            .get<Response<User[]>>(ADMIN_CONFIG.apiEndpoint + this.url)
            .pipe(map(({body}) => body));
        return users$;
    }


    findAllEkUsers(pageSize: number = 20, pageNumber: number = 0, searchKey: string, role: string, direction: string) {

        const url = `${this.basicUrl}/page/searchUser`;

        let params = new HttpParams();
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = params.append('searchKey', searchKey.toString());
        params = params.append('direction', direction.toString());
        params = role ? params.append('role', role.toString()) : params;

        const options = {params};

        return this.http.get<Response<Page<User>>>(url, options).pipe(map(({body}) => body));

    }

    getAllCommercialRegionManager() {
        const url = `${this.basicUrl}/commercialManagers`;
        return this.http.get<Response<User[]>>(url).pipe(map(({body}) => body));
    }

    addUser(user: User): Observable<User> {
        const user$ = this.http
            .post<Response<User>>(ADMIN_CONFIG.apiEndpoint + this.url, user)
            .pipe(map(({body}) => body));
        return user$;
    }

    updateUser(user: User): Observable<User> {
        const user$ = this.http
            .put<Response<User>>(ADMIN_CONFIG.apiEndpoint + this.url, user)
            .pipe(map(({body}) => body));
        return user$;
    }

    public deleteUserById(id: number): Observable<Response<any>> {
        const url = `${this.basicUrl}/${id}`;
        return this.http.delete<Response<void>>(url);
    }

    findById(id: number) {
        return this.http
            .get<Response<User>>(ADMIN_CONFIG.apiEndpoint + this.url + id)
            .pipe(map(({body}) => body.username));
    }

    findUserById(id: number) {
        return this.http
            .get<Response<User>>(ADMIN_CONFIG.apiEndpoint + this.url + id)
            .pipe(map(({body}) => body));
    }

    updateEkUser(user: User): Observable<User> {
        const url = `${this.basicUrl}/update`;
        return this.http.put<Response<User>>(url, user).pipe(map(({body}) => body));
    }
    updatePwdEkUser(user: User): Observable<User> {
        const url = `${this.basicUrl}/info-update`;
        return this.http.put<Response<User>>(url, user).pipe(map(({body}) => body));
    }

    saveEkUser(user: User): Observable<User> {
        const url = `${this.basicUrl}/save`;
        return this.http.post<Response<User>>(url, user).pipe(map(({body}) => body));
    }

}
