import {FoldersService} from './../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'kt-ek-stepper',
    templateUrl: './ek-stepper.component.html',
    styleUrls: ['./ek-stepper.component.scss']
})
export class EkStepperComponent implements OnInit {

    @Input() step = 1;
    @Input() folderState = '';

    currentRole: string = '';
    canDownload: boolean;

    constructor(private router: Router, private foldersService: FoldersService) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
        this.foldersService.folderStateSubj$.subscribe(state => {
            if (
                state === 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING' ||
                state === 'EK_GUARTENTEES_PENDING_SIGNATURE' ||
                state === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS' ||
                state === 'EK_PHYSICAL_FOLDER_PENDING' ||
                state === 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK' ||
                state === 'EK_WAITING_FOR_CLIENT' ||
                state === 'EK_PHYSICAL_FOLDER_PICKEDUP' ||
                state === 'EK_PHYSICAL_FOLDER_IN_DELIVERY' ||
                state === 'EK_PHYSICAL_FOLDER_DELIVERED' ||
                state === 'EK_PHYSICAL_FOLDER_IN_PROGRESS' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED' ||
                state === 'EK_BANK_PHYSICAL_FOLDER_RESERVE' ||
                state === 'EK_BANK_REFUSED' ||
                state === 'EK_FOLDER_REFUSED' ||
                state === 'EK_ADMIN_FOLDER_CANCELED' ||
                state === 'EK_CLIENT_NO_RESPONSE' ||
                state === 'EK_FOLDER_PICKUP_FAILED' ||
                state === 'FINANCIAL_FOLDER' ) {
                this.canDownload = true
            } else {
                this.canDownload = false
            }
        })
    }

    goToSix(toStep: number) {
        //banker , admin and super admin can navigate directly
        if (this.currentRole === 'ROLE_BANKER_EK' || this.currentRole === 'ROLE_SUPERADMIN' || this.currentRole === 'ROLE_ADMIN') {
            this.router.navigate([], {queryParams: {step: toStep}});
            return;
        }
        if (this.step > 6) {
            this.router.navigate([], {queryParams: {step: 6}});
        }
    }

    goBack(toStep: number) {
        //banker , admin and super admin  can navigate directly
        if (this.currentRole === 'ROLE_BANKER_EK' || this.currentRole === 'ROLE_SUPERADMIN' || this.currentRole === 'ROLE_ADMIN') {
            this.router.navigate([], {queryParams: {step: toStep}});
            return;
        } else if (toStep < this.step) {
            this.router.navigate([], {queryParams: {step: toStep}});
        }
    }

    // goBack(toStep: number) {

    //     if (this.currentRole === "ROLE_POS_EK" && toStep === 5 && toStep < this.step) {
    //         this.router.navigate([], {queryParams: {step: toStep}});
    //         return;
    //     }

    //     if (toStep === 9 && this.step >= 5) {
    //         this.router.navigate([], {queryParams: {step: toStep}});
    //     }

    //     if (toStep < this.step && (toStep == 1 || toStep == 2 || toStep == 3 || toStep == 4)) {
    //         this.router.navigate([], {queryParams: {step: toStep}});
    //     }

    //     if (toStep === 5 && toStep < this.step && this.step !== 9) {
    //         this.router.navigate([], {queryParams: {step: toStep}});
    //         return;
    //     }
    // }
}
