import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {User} from '../../../../../core/auth';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {Update} from '@ngrx/entity';
import * as UserActions from '../../../../../core/auth/_actions/user.actions';
import {UserService} from '../../../../../core/services/user.service';
import {Actions, ofType} from "@ngrx/effects";
import {
    UserPwdUpdatedSuccessfully, UserPwdUpdateFailed,
} from "../../../../../core/auth/_actions/user.actions";

@Component({
    selector: 'kt-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    currentUser: User;
    user: User;
    oldUser: User;
    loading$: Observable<boolean>;
    userForm: FormGroup;
    hasFormErrors = false;
    private PHONE_REGEX = /^(00213|\+213|0)(5|6|7)(\s*?[0-9]\s*?){5,8}$/;
    errorMsg = "Oh snap! Change a few things up and try submitting again.";
    showPassword: boolean = false;

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
        const newPasswordControl = this.userForm.get('newPassword');
        const confirmationPasswordControl = this.userForm.get('confirmationPassword');
        if (this.showPassword) {
            newPasswordControl.get('type').setValue('text');
            confirmationPasswordControl.get('type').setValue('text');
        } else {
            newPasswordControl.get('type').setValue('password');
            confirmationPasswordControl.get('type').setValue('password');
        }
    }


    constructor(
        private userFB: FormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private store: Store<AppState>,
        private userService: UserService,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private _actions$: Actions,
    ) {
    }

    ngOnInit(): void {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        this.userService.getUserByEmail().subscribe(res => {

            this.user = Object.assign({}, res);
            this.createForm();

            this.zone.run(() => {
                this.user = Object.assign({}, res);
                this.cd.detectChanges();
            });


        })

    }


    /**
     * Create form
     */
    createForm() {
        this.userForm = this.userFB.group({
            username: [this.user.username, Validators.required],
            firstname: [this.user.firstname, Validators.required],
            lastname: [this.user.lastname, Validators.required],
            address: [this.user.address, Validators.required],
            email: [this.user.email, Validators.email],
            phone: [this.user.phone, Validators.compose([Validators.minLength(10), Validators.pattern(this.PHONE_REGEX)])],
            newPassword: ['', Validators.required],
            confirmationPassword: ['', Validators.required]
        }, {
            validator: MustMatch('newPassword', 'confirmationPassword')

        });
    }

    /**
     * Returns prepared data for save
     */
    prepareUser(): User {
        const controls = this.userForm.controls;
        const _user = new User();
        _user.clear();
        _user.address = controls.address.value;
        // _user.pic = this.user.pic;
        _user.id = this.currentUser.id;
        _user.username = controls.username.value;
        _user.email = controls.email.value;
        _user.firstname = controls.firstname.value;
        _user.lastname = controls.lastname.value;
        _user.phone = controls.phone.value;
        _user.roles = this.currentUser.roles;
        if (controls.newPassword.value !== '') {
            _user.password = controls.newPassword.value;
        } else {
            _user.password = undefined;
        }
        return _user;
    }


    /**
     * Save data
     *
     * @param withBack
     */
    onSumbit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.userForm.controls;
        /** check form */
        if (
            this.userForm && this.userForm.invalid
        ) {
            this.hasFormErrors = true;
            Object.keys(controls).forEach((controlName) =>
                this.userForm.controls[controlName].markAsTouched()
            );
            return;
        }

        if (controls.newPassword.value !== '' && controls.confirmationPassword.invalid) {
            this.hasFormErrors = true;
            return;
        }

        const editedUser = this.prepareUser();

        this.updateUser(editedUser, withBack);


    }


    /**
     * Update user
     *
     * @param _user
     * @param withBack
     */
    updateUser(_user: User, withBack: boolean = false) {
        // Update User
        // tslint:disable-next-line:prefer-const

        const updatedUser: Update<User> = {
            id: _user.id,
            changes: _user
        };
        this.store.dispatch(UserActions.UserPwdUpdated({partialUser: updatedUser, user: _user}));
        this._actions$
            .pipe(ofType(UserPwdUpdatedSuccessfully))
            .subscribe((data: any) => {
                this.errorMsg = `User successfully has been saved.`;
                this.layoutUtilsService.showActionNotification(this.errorMsg, MessageType.Update, 5000, true, true);
            });
        this._actions$
            .pipe(ofType(UserPwdUpdateFailed))
            .subscribe((data: any) => {
                if (data && data.error.status === 400) {
                    this.errorMsg = `Longueur de codification invalide. La codification doit être exactement de 21 caractères`;
                    this.layoutUtilsService.showActionNotification(
                        this.errorMsg,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                } else {
                    this.errorMsg = `there is an error in updating user!`;
                    this.layoutUtilsService.showActionNotification(
                        this.errorMsg,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                }
            });
        }

    /**
     * Close Alert
     *
     * @param $event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    /**
     * get String role names
     */
    getStringRoles(role: string): string {
        let roleName = '';
        switch (role) {
            case 'ROLE_SUPERADMIN' :
                roleName = 'Super Administrateur';
                break;
            case 'ROLE_POS_EK' :
                roleName = 'POS (Point de vente)';
                break;
            case 'ROLE_BANKER_EK' :
                roleName = 'Banquier';
                break;
            case 'ROLE_CREDIT_ANALYST_EK' :
                roleName = 'Analyste crédit';
                break;
            case 'ROLE_ADMIN' :
                roleName = 'Administrateur';
                break;
            case 'ROLE_BANK_API' :
                roleName = 'Banque API';
                break;
            case 'ROLE_GUEST' :
                roleName = 'INVITÉ';
                break;
            case 'ROLE_COMMERCIAL_POS_EK':
                roleName =  'Commercial';
                break;
            default :
                roleName = role;
                break;
        }
        return roleName;
    }



}
// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    }
}
