import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'professionTranslate'
})
export class ProfessionTranslatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch(value) {
      case "RETIREES":
      return "Retraités";
    case "UNEMPLOYED":
      return "Sans emploi";
    case "ASSOCIATES":
      return "Associés";
    case "LEGISLATIVE_MEMBERS":
      return "Membres législatifs";
    case "SENIOR_ADMINISTRATION":
      return "Administration supérieure";
    case "POLITICAL_LEADERS":
      return "Leaders politiques";
    case "DIRECTORS":
      return "Directeurs";
    case "SPECIALIZED_DIRECTORS":
      return "Directeurs spécialisés";
    case "EXECUTIVES":
      return "Cadres";
    case "PHYSICAL_SCIENCE_RESEARCHERS":
      return "Chercheurs en sciences physiques";
    case "MATHEMATICS_STATISTICS_RESEARCHERS":
      return "Chercheurs en mathématiques et statistiques";
    case "IT_SPECIALISTS":
      return "Spécialistes en informatique";
    case "OTHER_ENGINEERS":
      return "Autres ingénieurs";
    case "LIFE_SCIENCE_SPECIALISTS":
      return "Spécialistes en sciences de la vie";
    case "MEDICAL_PROFESSIONALS":
      return "Professionnels de la santé";
    case "UNIVERSITY_PROFESSORS":
      return "Professeurs d'université";
    case "SECONDARY_EDUCATION_PROFESSORS":
      return "Professeurs de l'enseignement secondaire";
    case "OTHER_EDUCATION_SPECIALISTS":
      return "Autres spécialistes de l'éducation";
    case "ADMINISTRATIVE_COMMERCIAL_SPECIALISTS":
      return "Spécialistes administratifs et commerciaux";
    case "LEGAL_PROFESSIONALS":
      return "Professionnels du droit";
    case "ARCHIVISTS_LIBRARIANS":
      return "Archivistes et bibliothécaires";
    case "SOCIAL_HUMAN_SCIENCES_SPECIALISTS":
      return "Spécialistes des sciences sociales et humaines";
    case "WRITERS_ARTISTS":
      return "Écrivains et artistes";
    case "CLERGY_MEMBERS":
      return "Membres du clergé";
    case "PHYSICAL_TECHNICAL_SCIENCES_TECHNICIANS":
      return "Techniciens en sciences physiques et techniques";
    case "OTHER_IT_OPERATORS":
      return "Autres opérateurs en technologies de l'information";
    case "OPTICAL_ELECTRONIC_EQUIPMENT_TECHNICIANS":
      return "Techniciens en équipement optique et électronique";
    case "AIR_TRANSPORT_TECHNICIANS":
      return "Techniciens en transport aérien";
    case "SAFETY_HEALTH_QUALITY_INSPECTORS":
      return "Inspecteurs en sécurité, santé et qualité";
    case "TECHNICAL_NAVIGATING_PERSONNEL":
      return "Personnel technique de navigation";
    case "LIFE_SCIENCE_HEALTH_WORKERS":
      return "Travailleurs en sciences de la vie et de la santé";
    case "MODERN_MEDICINE_INTERMEDIATES":
      return "Intermédiaires en médecine moderne";
    case "NURSES":
      return "Infirmières";
    case "HEALTH_SUPERVISORS_MIDWIVES":
      return "Superviseurs de santé et sages-femmes";
    case "TRADITIONAL_MEDICINE_PRACTITIONERS":
      return "Praticiens de la médecine traditionnelle";
    case "PRIMARY_EDUCATION_INTERMEDIATES":
      return "Intermédiaires en éducation primaire";
    case "PRESCHOOL_EDUCATION_INTERMEDIATES":
      return "Intermédiaires en éducation préscolaire";
    case "SPECIAL_EDUCATION_INTERMEDIATES":
      return "Intermédiaires en éducation spécialisée";
    case "OTHER_EDUCATION_INTERMEDIATES":
      return "Autres intermédiaires en éducation";
    case "FINANCE_SALES_INTERMEDIATES":
      return "Intermédiaires en finance et ventes";
    case "COMMERCIAL_AGENTS_BROKERS":
      return "Agents commerciaux et courtiers";
    case "ADMINISTRATIVE_MANAGEMENT_INTERMEDIATES":
      return "Intermédiaires en gestion administrative";
    case "PUBLIC_ADMINISTRATION_INSPECTORS":
      return "Inspecteurs de l'administration publique";
    case "CRIMINAL_POLICE_INSPECTORS_DETECTIVES":
      return "Inspecteurs de police criminelle et détectives";
    case "SOCIAL_WORK_INTERMEDIATES":
      return "Intermédiaires en travail social";
    case "CREATIVE_PERFORMING_ARTS_INTERMEDIATES":
      return "Intermédiaires en arts créatifs et du spectacle";
    case "LAY_CLERGY":
      return "Clergé laïc";
    case "RADIO_TV_PRESENTATORS":
      return "Présentateurs radio et télévision";
    case "STENOGRAPHIC_SECRETARIES":
      return "Secrétaires sténographes";
    case "ACCOUNTING_FINANCE_SERVICES_EMPLOYEES":
      return "Employés des services comptables et financiers";
    case "STOCK_MANAGEMENT_ORDONNANCE_AGENTS_TRANSPORT_ADMINISTRATIVE_EMPLOYEES":
      return "Agents de gestion des stocks, agents d'ordonnancement et employés administratifs du transport";
    case "DOCUMENTATION_MAIL_EMPLOYEES":
      return "Employés de la documentation et du courrier";
    case "OTHER_OFFICE_EMPLOYEES":
      return "Autres employés de bureau";
    case "CASHIERS":
      return "Caissiers";
    case "CUSTOMER_SERVICE_INFORMATION_PERSONNEL":
      return "Personnel du service client et de l'information";
    case "TOURISM_GUIDES":
      return "Guides touristiques";
    case "RESTAURANT_SERVICE_EMPLOYEES":
      return "Employés de service en restaurant";
    case "CHILD_CARE_AIDES_HEALTH_ASSISTANTS":
      return "Aides à la garde d'enfants et assistants de santé";
    case "OTHER_DIRECT_SERVICES_TO_INDIVIDUALS":
      return "Autres services directs aux particuliers";
    case "ASTROLOGERS_FORTUNE_TELLERS":
      return "Astrologues et diseurs de bonne aventure";
    case "PROTECTION_SECURITY_PERSONNEL":
      return "Personnel de protection et de sécurité";
    case "COMMERCIAL_NAVIGATING_PERSONNEL":
      return "Personnel navigant commercial";
    case "MODELS":
      return "Mannequins";
    case "STORE_SALES_DEMONSTRATORS":
      return "Démonstrateurs de vente en magasin";
    case "RETAIL_STALL_MARKET_SALES":
      return "Ventes au détail sur étal de marché";
    case "AGRICULTURE_MARKET_CROPS_WORKERS":
      return "Travailleurs du marché de l'agriculture et des cultures";
    case "LIVESTOCK_MARKET_WORKERS":
      return "Travailleurs du marché du bétail";
    case "POLYCULTURE_LIVESTOCK_MARKET_WORKERS":
      return "Travailleurs du marché de la polyculture et de l'élevage";
    case "FORESTRY_PROFESSIONALS":
      return "Professionnels de la foresterie";
    case "FISHERS_HUNTERS_TRAPPERS":
      return "Pêcheurs, chasseurs et trappeurs";
    case "SUBSISTENCE_AGRICULTURE_FISHERY_WORKERS":
      return "Travailleurs de l'agriculture vivrière et de la pêche";
    case "MINERS_QUARRIERS_MINING_WORKERS":
      return "Mineurs, carriers et travailleurs de l'extraction minière";
    case "BUILDING_CONSTRUCTION_WORKERS":
      return "Travailleurs de la construction de bâtiments";
    case "BUILDING_FINISHING_WORKERS":
      return "Travailleurs de la finition des bâtiments";
    case "PAINTERS_BUILDING_RENOVATORS":
      return "Peintres et rénovateurs de bâtiments";
    case "FOUNDRY_MOULDERS_WELDERS":
      return "Fondeurs, mouleurs et soudeurs";
    case "FORGERS_TOOLMAKERS":
      return "Forgerons et outilleurs";
    case "MECHANICS_WORKERS":
      return "Mécaniciens";
    case "ELECTRICAL_ELECTRONIC_APPLIANCE_MECHANICS":
      return "Mécaniciens d'appareils électriques et électroniques";
    case "METAL_PRECISION_WORKERS":
      return "Travailleurs de la métallurgie de précision";
    case "POTTERS_GLASS_BLOWERS_SIMILAR_WORKERS":
      return "Potiers et souffleurs de verre et travailleurs similaires";
    case "MANUAL_WORKERS_WOOD_TEXTILE_LEATHER_MATERIALS":
      return "Travailleurs manuels du bois, des textiles et du cuir";
    case "PRINTING_INDUSTRY_WORKERS_SIMILAR":
      return "Travailleurs de l'industrie de l'impression et travailleurs similaires";
    case "FOOD_INDUSTRY_WORKERS":
      return "Travailleurs de l'industrie alimentaire";
    case "CABINETMAKERS_JOINERS_WOOD_TREATMENT_WORKERS":
      return "Ébénistes, menuisiers et travailleurs du traitement du bois";
    case "TEXTILE_CLOTHING_WORKERS":
      return "Travailleurs du textile et de l'habillement";
    case "LEATHER_SKIN_SHOE_WORKERS":
      return "Travailleurs du cuir, de la peau et de la chaussure";
    case "MINERAL_EXTRACTION_TREATMENT_OPERATORS":
      return "Opérateurs de traitement de l'extraction de minéraux";
    case "METAL_TRANSFORMATION_OPERATORS":
      return "Opérateurs de transformation des métaux";
    case "GLASS_CERAMIC_MATERIALS_OPERATORS":
      return "Opérateurs de matériaux en verre et céramique";
    case "WOOD_PAPER_MANUFACTURING_OPERATORS":
      return "Opérateurs de fabrication du bois et du papier";
    case "CHEMICAL_PLANT_OPERATORS":
      return "Opérateurs d'installations chimiques";
    case "ENERGY_PRODUCTION_OPERATORS_SIMILAR":
      return "Opérateurs de production d'énergie et travailleurs similaires";
    case "AUTOMATED_ASSEMBLY_ROBOT_OPERATORS":
      return "Opérateurs d'assemblage automatisé et de robots";
    case "CONCRETE_MACHINERY_TOOL_OPERATORS_SIMILAR":
      return "Opérateurs de machines et d'outils en béton et travailleurs similaires";
    case "CHEMICAL_PRODUCT_MANUFACTURING_MACHINE_OPERATORS":
      return "Opérateurs de machines de fabrication de produits chimiques";
    case "RUBBER_PLASTIC_PRODUCT_MANUFACTURING_MACHINE_OPERATORS":
      return "Opérateurs de machines de fabrication de produits en caoutchouc et en plastique";
    case "WOOD_PRODUCT_MANUFACTURING_MACHINE_OPERATORS":
      return "Opérateurs de machines de fabrication de produits en bois";
    case "PRINTING_PAPER_CARD_BOARD_MACHINE_OPERATORS":
      return "Opérateurs de machines d'impression, de papier et de carton";
    case "TEXTILE_MACHINE_OPERATORS":
      return "Opérateurs de machines textiles";
    case "FOOD_INDUSTRY_MACHINE_OPERATORS":
      return "Opérateurs de machines de l'industrie alimentaire";
    case "ASSEMBLY_WORKERS":
      return "Ouvriers d'assemblage";
    case "OTHER_FIXED_MACHINE_OPERATORS_ASSEMBLY_WORKERS":
      return "Autres opérateurs de machines fixes et ouvriers d'assemblage";
    case "LOCOMOTIVE_OPERATORS_RAIL_VEHICLE_MANEUVERING_WORKERS":
      return "Conducteurs de locomotives et travailleurs en manœuvres de véhicules sur rail";
    case "MOTOR_VEHICLE_OPERATORS":
      return "Conducteurs de véhicules à moteur";
    case "EARTH_MOVING_AGRICULTURAL_MOBILE_EQUIPMENT_OPERATORS":
      return "Opérateurs d'équipements mobiles de terrassement et agricoles";
    case "DECK_CREW_SIMILAR_WORKERS":
      return "Équipage de pont et travailleurs similaires";
    case "STREET_VENDORS_SIMILAR":
      return "Vendeurs de rue et travailleurs similaires";
    case "SHOESHINERS_STREET_PERFORMERS_SIMILAR":
      return "Cireurs de chaussures, artistes de rue et travailleurs similaires";
    case "HOUSEHOLD_CLEANING_PERSONNEL_SIMILAR":
      return "Personnel de nettoyage ménager et travailleurs similaires";
    case "BUILDING_MAINTENANCE_WINDOW_CLEANERS_SIMILAR":
      return "Personnel d'entretien des bâtiments et laveurs de vitres et travailleurs similaires";
    case "COURIERS_PORTERS_GUARDS_SIMILAR":
      return "Courriers, portiers et gardes et travailleurs similaires";
    case "GARBAGE_COLLECTORS_SWEEPERS_SIMILAR":
      return "Éboueurs, balayeurs et travailleurs similaires";
    case "UNSKILLED_AGRICULTURE_FISHERY_FORESTRY_MANEUVERING_SIMILAR":
      return "Travailleurs non qualifiés de l'agriculture, de la pêche et de la foresterie et travailleurs similaires";
    case "MINING_CONSTRUCTION_MANEUVERING_SIMILAR":
      return "Manœuvres de l'extraction minière, de la construction et travailleurs similaires";
    case "MANUFACTURING_INDUSTRY_MANEUVERING_SIMILAR":
      return "Manœuvres de l'industrie manufacturière et travailleurs similaires";
    case "TRANSPORT_MANEUVERING_SIMILAR":
      return "Manœuvres du transport et travailleurs similaires";
    
    case "Retraités":
      return "RETIREES";
    case "Sans emploi":
      return "UNEMPLOYED";
    case "Associés":
      return "ASSOCIATES";
    case "Membres législatifs":
      return "LEGISLATIVE_MEMBERS";
    case "Administration supérieure":
      return "SENIOR_ADMINISTRATION";
    case "Leaders politiques":
      return "POLITICAL_LEADERS";
    case "Directeurs":
      return "DIRECTORS";
    case "Directeurs spécialisés":
      return "SPECIALIZED_DIRECTORS";
    case "Cadres":
      return "EXECUTIVES";
    case "Chercheurs en sciences physiques":
      return "PHYSICAL_SCIENCE_RESEARCHERS";
    case "Chercheurs en mathématiques et statistiques":
      return "MATHEMATICS_STATISTICS_RESEARCHERS";
    case "Spécialistes en informatique":
      return "IT_SPECIALISTS";
    case "Autres ingénieurs":
      return "OTHER_ENGINEERS";
    case "Spécialistes en sciences de la vie":
      return "LIFE_SCIENCE_SPECIALISTS";
    case "Professionnels de la santé":
      return "MEDICAL_PROFESSIONALS";
    case "Professeurs d'université":
      return "UNIVERSITY_PROFESSORS";
    case "Professeurs de l'enseignement secondaire":
      return "SECONDARY_EDUCATION_PROFESSORS";
    case "Autres spécialistes de l'éducation":
      return "OTHER_EDUCATION_SPECIALISTS";
    case "Spécialistes administratifs et commerciaux":
      return "ADMINISTRATIVE_COMMERCIAL_SPECIALISTS";
    case "Professionnels du droit":
      return "LEGAL_PROFESSIONALS";
    case "Archivistes et bibliothécaires":
      return "ARCHIVISTS_LIBRARIANS";
    case "Spécialistes des sciences sociales et humaines":
      return "SOCIAL_HUMAN_SCIENCES_SPECIALISTS";
    case "Écrivains et artistes":
      return "WRITERS_ARTISTS";
    case "Membres du clergé":
      return "CLERGY_MEMBERS";
    case "Techniciens en sciences physiques et techniques":
      return "PHYSICAL_TECHNICAL_SCIENCES_TECHNICIANS";
    case "Autres opérateurs en technologies de l'information":
      return "OTHER_IT_OPERATORS";
    case "Techniciens en équipement optique et électronique":
      return "OPTICAL_ELECTRONIC_EQUIPMENT_TECHNICIANS";
    case "Techniciens en transport aérien":
      return "AIR_TRANSPORT_TECHNICIANS";
    case "Inspecteurs en sécurité, santé et qualité":
      return "SAFETY_HEALTH_QUALITY_INSPECTORS";
    case "Personnel technique de navigation":
      return "TECHNICAL_NAVIGATING_PERSONNEL";
    case "Travailleurs en sciences de la vie et de la santé":
      return "LIFE_SCIENCE_HEALTH_WORKERS";
    case "Intermédiaires en médecine moderne":
      return "MODERN_MEDICINE_INTERMEDIATES";
    case "Infirmières":
      return "NURSES";
    case "Superviseurs de santé et sages-femmes":
      return "HEALTH_SUPERVISORS_MIDWIVES";
    case "Praticiens de la médecine traditionnelle":
      return "TRADITIONAL_MEDICINE_PRACTITIONERS";
    case "Intermédiaires en éducation primaire":
      return "PRIMARY_EDUCATION_INTERMEDIATES";
    case "Intermédiaires en éducation préscolaire":
      return "PRESCHOOL_EDUCATION_INTERMEDIATES";
    case "Intermédiaires en éducation spécialisée":
      return "SPECIAL_EDUCATION_INTERMEDIATES";
    case "Autres intermédiaires en éducation":
      return "OTHER_EDUCATION_INTERMEDIATES";
    case "Intermédiaires en finance et ventes":
      return "FINANCE_SALES_INTERMEDIATES";
    case "Agents commerciaux et courtiers":
      return "COMMERCIAL_AGENTS_BROKERS";
    case "Intermédiaires en gestion administrative":
      return "ADMINISTRATIVE_MANAGEMENT_INTERMEDIATES";
    case "Inspecteurs de l'administration publique":
      return "PUBLIC_ADMINISTRATION_INSPECTORS";
    case "Inspecteurs de police criminelle et détectives":
      return "CRIMINAL_POLICE_INSPECTORS_DETECTIVES";
    case "Intermédiaires en travail social":
      return "SOCIAL_WORK_INTERMEDIATES";
    case "Intermédiaires en arts créatifs et du spectacle":
      return "CREATIVE_PERFORMING_ARTS_INTERMEDIATES";
    case "Clergé laïc":
      return "LAY_CLERGY";
    case "Présentateurs radio et télévision":
      return "RADIO_TV_PRESENTATORS";
    case "Secrétaires sténographes":
      return "STENOGRAPHIC_SECRETARIES";
    case "Employés des services comptables et financiers":
      return "ACCOUNTING_FINANCE_SERVICES_EMPLOYEES";
    case "Agents de gestion des stocks, agents d'ordonnancement et employés administratifs du transport":
      return "STOCK_MANAGEMENT_ORDONNANCE_AGENTS_TRANSPORT_ADMINISTRATIVE_EMPLOYEES";
    case "Employés de la documentation et du courrier":
      return "DOCUMENTATION_MAIL_EMPLOYEES";
    case "Autres employés de bureau":
      return "OTHER_OFFICE_EMPLOYEES";
    case "Caissiers":
      return "CASHIERS";
    case "Personnel du service client et de l'information":
      return "CUSTOMER_SERVICE_INFORMATION_PERSONNEL";
    case "Guides touristiques":
      return "TOURISM_GUIDES";
    case "Employés de service en restaurant":
      return "RESTAURANT_SERVICE_EMPLOYEES";
    case "Aides à la garde d'enfants et assistants de santé":
      return "CHILD_CARE_AIDES_HEALTH_ASSISTANTS";
    case "Autres services directs aux particuliers":
      return "OTHER_DIRECT_SERVICES_TO_INDIVIDUALS";
    case "Astrologues et diseurs de bonne aventure":
      return "ASTROLOGERS_FORTUNE_TELLERS";
    case "Personnel de protection et de sécurité":
      return "PROTECTION_SECURITY_PERSONNEL";
    case "Personnel navigant commercial":
      return "COMMERCIAL_NAVIGATING_PERSONNEL";
    case "Mannequins":
      return "MODELS";
    case "Démonstrateurs de vente en magasin":
      return "STORE_SALES_DEMONSTRATORS";
    case "Ventes au détail sur étal de marché":
      return "RETAIL_STALL_MARKET_SALES";
    case "Travailleurs du marché de l'agriculture et des cultures":
      return "AGRICULTURE_MARKET_CROPS_WORKERS";
    case "Travailleurs du marché du bétail":
      return "LIVESTOCK_MARKET_WORKERS";
    case "Travailleurs du marché de la polyculture et de l'élevage":
      return "POLYCULTURE_LIVESTOCK_MARKET_WORKERS";
    case "Professionnels de la foresterie":
      return "FORESTRY_PROFESSIONALS";
    case "Pêcheurs, chasseurs et trappeurs":
      return "FISHERS_HUNTERS_TRAPPERS";
    case "Travailleurs de l'agriculture vivrière et de la pêche":
      return "SUBSISTENCE_AGRICULTURE_FISHERY_WORKERS";
    case "Mineurs, carriers et travailleurs de l'extraction minière":
      return "MINERS_QUARRIERS_MINING_WORKERS";
    case "Travailleurs de la construction de bâtiments":
      return "BUILDING_CONSTRUCTION_WORKERS";
    case "Travailleurs de la finition des bâtiments":
      return "BUILDING_FINISHING_WORKERS";
    case "Peintres et rénovateurs de bâtiments":
      return "PAINTERS_BUILDING_RENOVATORS";
    case "Fondeurs, mouleurs et soudeurs":
      return "FOUNDRY_MOULDERS_WELDERS";
    case "Forgerons et outilleurs":
      return "FORGERS_TOOLMAKERS";
    case "Mécaniciens":
      return "MECHANICS_WORKERS";
    case "Mécaniciens d'appareils électriques et électroniques":
      return "ELECTRICAL_ELECTRONIC_APPLIANCE_MECHANICS";
    case "Travailleurs de la métallurgie de précision":
      return "METAL_PRECISION_WORKERS";
    case "Potiers et souffleurs de verre et travailleurs similaires":
      return "POTTERS_GLASS_BLOWERS_SIMILAR_WORKERS";
    case "Travailleurs manuels du bois, des textiles et du cuir":
      return "MANUAL_WORKERS_WOOD_TEXTILE_LEATHER_MATERIALS";
    case "Travailleurs de l'industrie de l'impression et travailleurs similaires":
      return "PRINTING_INDUSTRY_WORKERS_SIMILAR";
    case "Travailleurs de l'industrie alimentaire":
      return "FOOD_INDUSTRY_WORKERS";
    case "Ébénistes, menuisiers et travailleurs du traitement du bois":
      return "CABINETMAKERS_JOINERS_WOOD_TREATMENT_WORKERS";
    case "Travailleurs du textile et de l'habillement":
      return "TEXTILE_CLOTHING_WORKERS";
    case "Travailleurs du cuir, de la peau et de la chaussure":
      return "LEATHER_SKIN_SHOE_WORKERS";
    case "Opérateurs de traitement de l'extraction de minéraux":
      return "MINERAL_EXTRACTION_TREATMENT_OPERATORS";
    case "Opérateurs de transformation des métaux":
      return "METAL_TRANSFORMATION_OPERATORS";
    case "Opérateurs de matériaux en verre et céramique":
      return "GLASS_CERAMIC_MATERIALS_OPERATORS";
    case "Opérateurs de fabrication du bois et du papier":
      return "WOOD_PAPER_MANUFACTURING_OPERATORS";
    case "Opérateurs d'installations chimiques":
      return "CHEMICAL_PLANT_OPERATORS";
    case "Opérateurs de production d'énergie et travailleurs similaires":
      return "ENERGY_PRODUCTION_OPERATORS_SIMILAR";
    case "Opérateurs d'assemblage automatisé et de robots":
      return "AUTOMATED_ASSEMBLY_ROBOT_OPERATORS";
    case "Opérateurs de machines et d'outils en béton et travailleurs similaires":
      return "CONCRETE_MACHINERY_TOOL_OPERATORS_SIMILAR";
    case "Opérateurs de machines de fabrication de produits chimiques":
      return "CHEMICAL_PRODUCT_MANUFACTURING_MACHINE_OPERATORS";
    case "Opérateurs de machines de fabrication de produits en caoutchouc et en plastique":
      return "RUBBER_PLASTIC_PRODUCT_MANUFACTURING_MACHINE_OPERATORS";
    case "Opérateurs de machines de fabrication de produits en bois":
      return "WOOD_PRODUCT_MANUFACTURING_MACHINE_OPERATORS";
    case "Opérateurs de machines d'impression, de papier et de carton":
      return "PRINTING_PAPER_CARD_BOARD_MACHINE_OPERATORS";
    case "Opérateurs de machines textiles":
      return "TEXTILE_MACHINE_OPERATORS";
    case "Opérateurs de machines de l'industrie alimentaire":
      return "FOOD_INDUSTRY_MACHINE_OPERATORS";
    case "Ouvriers d'assemblage":
      return "ASSEMBLY_WORKERS";
    case "Autres opérateurs de machines fixes et ouvriers d'assemblage":
      return "OTHER_FIXED_MACHINE_OPERATORS_ASSEMBLY_WORKERS";
    case "Conducteurs de locomotives et travailleurs en manœuvres de véhicules sur rail":
      return "LOCOMOTIVE_OPERATORS_RAIL_VEHICLE_MANEUVERING_WORKERS";
    case "Conducteurs de véhicules à moteur":
      return "MOTOR_VEHICLE_OPERATORS";
    case "Opérateurs d'équipements mobiles de terrassement et agricoles":
      return "EARTH_MOVING_AGRICULTURAL_MOBILE_EQUIPMENT_OPERATORS";
    case "Équipage de pont et travailleurs similaires":
      return "DECK_CREW_SIMILAR_WORKERS";
    case "Vendeurs de rue et travailleurs similaires":
      return "STREET_VENDORS_SIMILAR";
    case "Cireurs de chaussures, artistes de rue et travailleurs similaires":
      return "SHOESHINERS_STREET_PERFORMERS_SIMILAR";
    case "Personnel de nettoyage ménager et travailleurs similaires":
      return "HOUSEHOLD_CLEANING_PERSONNEL_SIMILAR";
    case "Personnel d'entretien des bâtiments et laveurs de vitres et travailleurs similaires":
      return "BUILDING_MAINTENANCE_WINDOW_CLEANERS_SIMILAR";
    case "Courriers, portiers et gardes et travailleurs similaires":
      return "COURIERS_PORTERS_GUARDS_SIMILAR";
    case "Éboueurs, balayeurs et travailleurs similaires":
      return "GARBAGE_COLLECTORS_SWEEPERS_SIMILAR";
    case "Travailleurs non qualifiés de l'agriculture, de la pêche et de la foresterie et travailleurs similaires":
      return "UNSKILLED_AGRICULTURE_FISHERY_FORESTRY_MANEUVERING_SIMILAR";
    case "Manœuvres de l'extraction minière, de la construction et travailleurs similaires":
      return "MINING_CONSTRUCTION_MANEUVERING_SIMILAR";
    case "Manœuvres de l'industrie manufacturière et travailleurs similaires":
      return "MANUFACTURING_INDUSTRY_MANEUVERING_SIMILAR";
    case "Manœuvres du transport et travailleurs similaires":
      return "TRANSPORT_MANEUVERING_SIMILAR";
    default : return ''
  }
}

}
