import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChangeHistoryDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/changeHistory.datasource";
import {NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {fromEvent, merge, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {QueryParamsModel} from "../../../../../../core/_base/crud";
import {ChangeHistoryPageRequested} from "../../../../../../core/ek-e-commerce/ek-actions/change-history.actions";
import {ChangeHistoryModel} from "../../../../../../core/ek-e-commerce/ek-models/change-history-model";
import {
    FolderStateHistoryDetailsComponent
} from "./folder-state-history-details/folder-state-history-details.component";
import {MatDialog} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {FOLDER_STATES} from "../../../Shared/Constants/folderStates";
import * as UserActions from "../../../../../../core/auth/_actions/user.actions";
import {Actions, ofType} from "@ngrx/effects";
import {User} from "../../../../../../core/auth";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'kt-change-history',
    styleUrls: ['./change-history.component.scss'],
    templateUrl: './change-history.component.html',
})
export class ChangeHistoryComponent implements OnInit {

    viewLoading$ = null;
    listingTitle = "";
    dataSource: ChangeHistoryDatasource;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    //paging table
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    //sorting table
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    displayedColumns: string[] = ['id', 'firstName', 'updatedAt', 'updater', 'folderPreviousState', 'state', 'action'];

    filterByDateActivated = false;
    selectedFolderState = new FormControl();
    selectedUser = new FormControl();
    dateRange = new FormControl({begin: null, end: null});

    private subscriptions: Subscription[] = [];
    folderStateList = FOLDER_STATES;
    users: User[] = [];
    currentRole: string = '';

    constructor(public dialog: MatDialog,
                public formatter: NgbDateParserFormatter,
                private activatedRoute: ActivatedRoute,
                private actions$: Actions,
                private datePipe: DatePipe,
                private store: Store<AppState>,) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        //init dataSource
        this.dataSource = new ChangeHistoryDatasource(this.store);

        this.paginator._changePageSize(50);


        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.listingTitle = this.activatedRoute.snapshot.data['title'];
            this.getAllPaged();
        });

        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            map(() => {
                this.paginator.pageIndex = 0;
                this.getAllPaged();
            })).subscribe();
        this.subscriptions.push(searchSubscription);


        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                this.getAllPaged();
            })
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.fieldListener();

        this.loadUsers();
    }

    fieldListener() {

        this.selectedFolderState.valueChanges.subscribe(value => {
            this.getAllPaged();
        });

        this.dateRange.valueChanges.subscribe(value => {
            this.getAllPaged();
        });

        this.selectedUser.valueChanges.subscribe(value => {
            this.getAllPaged();
        });

    }

    loadUsers() {

        const queryParams = new QueryParamsModel(
            "",
            "",
            "",
            0,
            1000
        );

        this.store.dispatch(
            UserActions.AllUsersRequested({
                page: queryParams,
                role: "",
                key: ""
            }));

        this.actions$.pipe(ofType(UserActions.AllUsersLoaded)).subscribe((data) => {
            if (data) {
                this.users = data.users;
            }
        });
    }

    getAllPaged() {

        const user = this.users.find(i => i.id === this.selectedUser.value);

        const queryParams = new QueryParamsModel(
            this.searchInput.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );

        this.store.dispatch(ChangeHistoryPageRequested({
            page: queryParams,
            firstDate: this.dateRange.value?.begin ? this.formatDate(this.dateRange.value.begin) : null,
            lastDate: this.dateRange.value?.end ? this.formatDate(this.dateRange.value.end) : null,
            folderState: this.selectedFolderState.value ? this.selectedFolderState.value : "",
            email: user ? user.email : "",
        }));

    }

    openFolderDetails(item: ChangeHistoryModel) {

        let dialogRef = this.dialog.open(FolderStateHistoryDetailsComponent, {
            data: {
                folderId: item.folderId,
                clientFirstName: item.clientFirstName,
                clientLastName: item.clientLastName,
                requestNumber:item.requestNumber,
            },
            width: "80rem",
            // panelClass: 'history-details'
        });
    }

    formatDate(date_: Date) {
        const date = this.datePipe.transform(date_, 'yyyy-MM-dd');
        return date;
    }


}
