// Angular
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DragDropModule} from '@angular/cdk/drag-drop';
// Translate Module
import {TranslateModule} from '@ngx-translate/core';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// UI
import {PartialsModule} from '../../../partials/partials.module';
// Core
// Auth
import {ModuleGuard} from '../../../../core/auth';
// Core => Services
// import {
//     DiscountCustomerEffects,
//     customerDiscountReducers,
//     BestSellerEffects,
//     bestSellersReducer,
//     BrandEffects,
//     brandsReducer,
//     categoriesReducer, CollaboratorEffects, collaboratorsReducer, CommentEffects, commentReducer1,
//     customerAddressesReducer,categoriesPopularReducer,
//     CustomerEffects,
//     customersReducer,
//     CustomersService,
//     NewArrivalEffects,
//     ProductEffects,
//     productsReducer,
//     ProductsService, PromoCodeEffects, PromoCodeService, promoCodesReducer,
//     discountsReducer, DiscountEffect, CategoryPopularEffects
// } from '../../../../core/e-commerce';
// Core => Utils
import {HttpUtilsService, InterceptService, LayoutUtilsService, TypesUtilsService} from '../../../../core/_base/crud';
// Shared
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../partials/content/crud';
// Components
import {ECommerceComponent} from './e-commerce.component';
// Customers
// Products

// Orders

// Material
import {
    MAT_DATE_LOCALE,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,

} from '@angular/material';
import {NgbDatepickerModule, NgbDropdownModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPermissionsModule} from 'ngx-permissions';
import { MatSlideToggleModule} from '@angular/material/slide-toggle'
import {ConfigurationEffects} from '../../../../core/ek-e-commerce/ek-effects/configuration.effects';
import {configurationCharacteristicsReducer} from '../../../../core/ek-e-commerce/ek-reducers/configuration-characteristic.reducers';
import {ConfigurationCharacteristicEffects} from '../../../../core/ek-e-commerce/ek-effects/configuration-characteristic.effects';
import {characteristicsReducer} from '../../../../core/ek-e-commerce/ek-reducers/characteristic.reducers';
import {CharacteristicEffects} from '../../../../core/ek-e-commerce/ek-effects/characteristic.effects';
import {imagesReducer} from '../../../../core/ek-e-commerce/ek-reducers/image.reducers';
import {ImageEffects} from '../../../../core/ek-e-commerce/ek-effects/image.effects';

import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {ordersReducer} from '../../../../core/e-commerce/_reducers/order.reducers';
import {OrderEffects} from '../../../../core/e-commerce/_effects/order.effects';
import {MatDividerModule} from '@angular/material/divider';
import {BestSellerService} from '../../../../core/e-commerce/_services/bestSeller.service';
import {NewArrivalService} from '../../../../core/e-commerce/_services/newArrival.service';
import {CdkTableModule} from '@angular/cdk/table';
import {MatTableExporterModule} from 'mat-table-exporter';
import {bestSellsReducer} from '../../../../core/e-commerce/_reducers/bestSells.reducer';
import {BestSellsEffects} from '../../../../core/e-commerce/_effects/bestSells.effects';
import {ClipboardModule} from 'ngx-clipboard';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ResizableModule} from 'angular-resizable-element';
import {MatChipsModule} from '@angular/material/chips';
// tslint:disable-next-line:class-name
import { AngularEditorModule } from '@kolkov/angular-editor';
import {CoreModule} from '../../../../core/core.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FooterFileService} from '../../../../core/e-commerce/_services/footer-file.service';
import {MatListModule} from "@angular/material/list";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {trendingConfigurationsReducer} from "../../../../core/e-commerce/_reducers/product-trending.reducer";
import {ProductTrendingEffects} from "../../../../core/e-commerce/_effects/product-trending.effects";
import {bestSellerConfigurationsReducer} from "../../../../core/e-commerce/_reducers/products-best-seller.reducer";
import {ProductsBestSellerEffects} from "../../../../core/e-commerce/_effects/products-best-seller.effects";

const routes: Routes = [];

// @ts-ignore
@NgModule({
    imports: [

        MatInputModule,
        MatSortModule,
        MatTableModule,
        AutocompleteLibModule,
        GalleryModule,
        LightboxModule,
        GallerizeModule,
        MatSlideToggleModule,
        DragDropModule,
        CoreModule,
        ResizableModule,
        CommonModule,
        AngularEditorModule,
        PartialsModule,
        HttpClientModule,
        NgxPermissionsModule.forChild(),
        RouterModule.forChild(routes),
        NgbProgressbarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatDialogModule,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatChipsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDividerModule,
        // StoreModule.forFeature('products', productsReducer),
        // EffectsModule.forFeature([ProductEffects]),
        // StoreModule.forFeature('customers', customersReducer),
        // EffectsModule.forFeature([CustomerEffects]),
        // StoreModule.forFeature('customerAddresses', customerAddressesReducer),
        // EffectsModule.forFeature([CustomerAddressEffects]),
        // StoreModule.forFeature('categories', categoriesReducer),
        // EffectsModule.forFeature([CategoryEffects]),
        // StoreModule.forFeature('brands', brandsReducer),
        // EffectsModule.forFeature([BrandEffects]),
        // StoreModule.forFeature('promoCodes', promoCodesReducer),
        // EffectsModule.forFeature([PromoCodeEffects]),
        // StoreModule.forFeature('collaborators', collaboratorsReducer),
        // EffectsModule.forFeature([CollaboratorEffects]),
        StoreModule.forFeature('bestSells', bestSellsReducer),
        EffectsModule.forFeature([BestSellsEffects]),

        // StoreModule.forFeature('configuration', configurationssReducer),
        // EffectsModule.forFeature([ConfigurationsEffects]),

        StoreModule.forFeature('characteristics', characteristicsReducer),
        EffectsModule.forFeature([CharacteristicEffects]),
        StoreModule.forFeature('configurationCharacteristics', configurationCharacteristicsReducer),
        EffectsModule.forFeature([ConfigurationCharacteristicEffects]),
        StoreModule.forFeature('images', imagesReducer),
        EffectsModule.forFeature([ImageEffects]),
        StoreModule.forFeature('orders', ordersReducer),
        EffectsModule.forFeature([OrderEffects]),
        // StoreModule.forFeature('bestSeller', bestSellersReducer),
        // EffectsModule.forFeature([BestSellerEffects]),
        // StoreModule.forFeature('newArrival', newArrivalsReducer),
        // EffectsModule.forFeature([NewArrivalEffects]),
        //
        // StoreModule.forFeature('carousels', carouselsReducer),
        // EffectsModule.forFeature([CarouselEffects]),
        // StoreModule.forFeature('comment', commentReducer1),
        // EffectsModule.forFeature([CommentEffects]),
        //
        // StoreModule.forFeature('discount', discountsReducer),
        // EffectsModule.forFeature([DiscountEffect]),
        //
        // StoreModule.forFeature('discountCustomers', customerDiscountReducers),
        // EffectsModule.forFeature([DiscountCustomerEffects]),
        StoreModule.forFeature('trending-configuration', trendingConfigurationsReducer),
        EffectsModule.forFeature([ProductTrendingEffects]),

        StoreModule.forFeature('best-seller-configuration', bestSellerConfigurationsReducer),
        EffectsModule.forFeature([ProductsBestSellerEffects]),
        // StoreModule.forFeature('categories-popular', categoriesPopularReducer),
        // EffectsModule.forFeature([CategoryPopularEffects]),
        CdkTableModule,
        MatTableExporterModule,
        NgbDatepickerModule,
        ClipboardModule,
        ResizableModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        MatListModule,
        MatButtonToggleModule,
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        DatePipe,

        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        // CustomersService,
        // ProductsService,
        BestSellerService,
        NewArrivalService,
        TypesUtilsService,
        LayoutUtilsService,
        FooterFileService,

    ],
    entryComponents: [
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
    ],

    declarations: [
        // Address
        ECommerceComponent,
    ]
})
export class ECommerceModule {
}
