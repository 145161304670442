import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommentFolderModel} from "../../../../../../../../core/ek-e-commerce/ek-models/CommentFolder.model";
import {FolderModel, FolderStates} from "../../../../../../../../core/ek-e-commerce/ek-models/folder.model";
import {BehaviorSubject} from "rxjs";
import {CommentFolderService} from "../../../../../../../../core/ek-e-commerce/ek-services/comment-folder.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {FoldersService} from "../../../../../../../../core/ek-e-commerce/ek-services/folders.service";


@Component({
  selector: 'kt-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: ['./edit-comment.component.scss']
})
export class EditCommentComponent implements OnInit {

  folderId!: number;
  comment: CommentFolderModel = new CommentFolderModel();
  clientId: number | undefined;
  private user!: string;
  currentDate = new Date();
  idfolder: number;
  comments : CommentFolderModel[]=[] ;

  @Input() idFolder : number;
  @Input() folder: FolderModel;
  @Input() isNewOrder: boolean = false;

  private folderStateSubj$ = new BehaviorSubject<FolderStates>(null);
  constructor(  private commentService : CommentFolderService ,
                public dialogRef: MatDialogRef<EditCommentComponent>,
                private route: ActivatedRoute,
                private folderService : FoldersService,
                @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit(): void {

    this.comment = this.data.comment ;
    this.idfolder = this.data.comment.idfolder;


  }

  close() {
    this.dialogRef.close() ;
  }

  editComment() {

    if(this.comment.content) {


      this.comment.title = 'Modifier  Commantaire ';

      this.comment.username  =JSON.parse(localStorage.getItem('currentUser')).username ;
      this.comment.userId  =JSON.parse(localStorage.getItem('currentUser')).id ;

      this.comment.updatedAt = new Date().toISOString();

      this.commentService.updateComment(this.comment).subscribe(res => {
        if (res){
          this.dialogRef.close(res);
        }
      });
    }
  }

}

