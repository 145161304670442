import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as ConfigurationActions from "../ek-actions/configuration.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {ConfigurationService} from "../ek-services/confguration.service";


@Injectable()
export class ConfigurationEffects {


    constructor(private actions$: Actions, private configurationService: ConfigurationService, private store: Store<AppState>) {
    }

    ConfigurationPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationActions.ConfigurationsPageRequested),
            switchMap((action) =>
                this.configurationService.getByProductId(action.productId)
                    .pipe(
                        map(configurations => ConfigurationActions.ConfigurationsPageLoadedSuccessfully(
                            {
                                configurations: configurations,
                            })),
                        catchError(error => of(ConfigurationActions.ConfigurationsPageLoadFailed({error})))
                    ))));

    // ConfigurationPageRequested1$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(ConfigurationActions.ConfigurationsPageRequested1),
    //         switchMap((action) =>
    //             this.configurationService.searchsorted(action.page.sortOrder,action.page.pageNumber,action.page.pageSize,action.page.filter)
    //                 .pipe(
    //                     map(configurations => ConfigurationActions.ConfigurationsPageLoadedSuccessfully1(
    //                         {
    //                             configurations: configurations.content,
    //                             page: configurations.totalElements,
    //                             totalCount: configurations.totalElements
    //                         })),
    //                     catchError(error => of(ConfigurationActions.ConfigurationsPageLoadFailed({error})))
    //                 ))));

    ConfigurationCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationActions.ConfigurationCreated),
            switchMap((action) =>
                this.configurationService.add(action.configuration)
                    .pipe(
                        map(data => ConfigurationActions.ConfigurationCreatedSuccessfully({configuration: data})),
                        catchError(error => of(ConfigurationActions.ConfigurationCreationFailed({error})))
                    ))));

    ConfigurationUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationActions.ConfigurationUpdated),
            switchMap((action) =>
                this.configurationService.update(action.configuration)
                    .pipe(
                        map(configuration => this.configCreationTest(configuration,action.partialConfiguration) ),
                        catchError(error => of(ConfigurationActions.ConfigurationUpdateFailed({error})))
                    ))));

    // ConfigurationUpdatedActivate$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(ConfigurationActions.ConfigurationUpdatedActivate),
    //         switchMap((action) =>
    //             this.configurationService.changeActivateProduct(action.configuration.id,action.activate)
    //                 .pipe(
    //                     map(configuration => ConfigurationActions.ConfigurationUpdatedActivateSuccessfully({
    //                         configuration : action.configuration,
    //                         partialConfiguration: action.partialConfiguration,
    //                         activate:action.activate
    //                     })),
    //                     catchError(error => of(ConfigurationActions.ConfigurationUpdateActivateFailed({error})))
    //                 ))));

    ConfigurationDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationActions.ConfigurationDeleted),
            switchMap((action) =>
                this.configurationService.delete(action.configurationId)
                    .pipe(
                        map(() => ConfigurationActions.ConfigurationDeletedSuccessfully({configurationId: action.configurationId})),
                        catchError(error => of(ConfigurationActions.ConfigurationDeleteFailed({error})))
                    ))));

    configCreationTest(configuration, partialConfiguration){
        return ConfigurationActions.ConfigurationUpdatedSuccessfully({
            configuration,
            partialConfiguration: partialConfiguration
        });
    }
}
