import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ImageModel} from "../../../../../../../core/ek-e-commerce/ek-models/image.model";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {BehaviorSubject} from "rxjs";
import {ImagesService} from "../../../../../../../core/ek-e-commerce/ek-services/images.service";
import {selectConfigurationById} from "../../../../../../../core/ek-e-commerce/ek-selectors/configuration.selector";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {ConfigurationModel} from "../../../../../../../core/ek-e-commerce/ek-models/configuration.model";
import {HttpResponse} from "@angular/common/http";
import {Update} from "@ngrx/entity";
import {
    ConfigurationsPageRequested,
    ConfigurationUpdatedSuccessfully
} from "../../../../../../../core/ek-e-commerce/ek-actions/configuration.action";
import {LayoutUtilsService, MessageType} from "../../../../../../../core/_base/crud";

@Component({
    selector: 'kt-edit-img',
    templateUrl: './edit-img.component.html',
    styleUrls: ['./edit-img.component.scss']
})
export class EkEditImgComponent implements OnInit {
    public selectedImageUrl: string;
    index: number = 0;
    imageToAdd: ImageModel;

    //images_:ImageModel []= [];
    loaddingImg: boolean;
    @ViewChild('img') img: ElementRef;
    currentRole: string;
    images_ = new BehaviorSubject<ImageModel[]>([]);
    order_: number = 0;
    configuration: ConfigurationModel;
    @Output() ImagesAdd = new EventEmitter<ImageModel[]>();
    counter = 0;
    updater: string;
    @Input() configurationId: number;
    readerResult: string | ArrayBuffer;

    constructor(private imagesService: ImagesService, private store: Store<AppState>, private layoutUtilsService: LayoutUtilsService) {
    }

    ngOnInit(): void {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.imageToAdd = new ImageModel();
        this.loaddingImg = false;
        if (this.configurationId && this.configurationId > 0) {
            const confSub$ = this.store.select(selectConfigurationById(this.configurationId)).subscribe(
                res => {
                    this.configuration = res;
                    this.images_.next(this.configuration.images);
                    if (this.images_.value.length > 0) {
                        this.selectedImageUrl = this.images_.value[0].url
                    }
                }
            );
        }


    }

    checkIfSelected(img: ImageModel): string {
        if (img.url == this.selectedImageUrl)
            return 'selected';
        else
            return '';
    }

    selectImage(imageUrl: string) {
        if (this.currentRole =='ROLE_GUEST_ADMIN'){
            event.stopPropagation();
            event.preventDefault();
            return
        }
        this.selectedImageUrl = imageUrl;
    }

    moveArrayItemToNewIndex(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        } else {
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        }

    }


    imagesDropped(event) {

        if (this.currentRole !== 'ROLE_SUPERADMIN'){
            return;
        }

        this.loaddingImg = true;
        const width = 800;
        const height = 800;
        event.preventDefault();
        let img = new ImageModel();
        const files = event.dataTransfer.files;
        for (let i = 0; i < files.length; i++) {
            let reader: FileReader = new FileReader();

            const file = files[i];

            reader.readAsDataURL(file)
            reader.onload = (event: any) => {
                let imageUrl = event.target.result
                let image = document.createElement("img")
                image.src = imageUrl
                image.onload = (e: any) => {
                    let canvas = document.createElement("canvas")
                    canvas.width = width
                    canvas.height = height
                    const context = canvas.getContext("2d")
                    context.drawImage(image, 0, 0, canvas.width, canvas.height)
                    let newImageUrl = context.canvas.toDataURL("image/jpeg", 90)
                    let newImage = document.createElement("img")
                    newImage.src = newImageUrl

                fetch(newImage.src)  // Fetch the image data
                .then(response => response.blob())  // Convert the response to a Blob
                .then(blob => {
                  const fileToUpload = new File([blob], `${file.name}`, { type: blob.type });
                  let readerToUpload = new FileReader()
                  readerToUpload.readAsDataURL(fileToUpload)
                  this.imageToAdd.configurationId = this.configurationId;
                  this.imageToAdd.file = fileToUpload;
                //   this.addImg(this.imageToAdd);
                //   this.multiImagesToAdd.push(this.imageToAdd);
                readerToUpload.onload = e => {
                    this.imagesService.uploadImage(fileToUpload).subscribe((res: any) => {
                     this.loaddingImg = false;
                     this.selectedImageUrl = res.data;
                     if(this.configurationId && this.configurationId > 0) {
                        this.imageToAdd.configurationId = this.configurationId;
                        this.imageToAdd.file = fileToUpload;
                        this.addImg(this.imageToAdd);
                        img.url = res.data;
                        img.ord = this.order_++;
                        // this.images_.value.push(img)
                        this.ImagesAdd.emit(this.images_.value);
                    } else {
                        img.url = res.data;
                        img.ord = this.order_++;
                        this.images_.value.push(img)
                        this.ImagesAdd.emit(this.images_.value);
                    }
                    // img.url = res.imageUrl;
                    // img.ord = this.order_++;
                    // img.configurationId = this.configurationId;
                    // this.selectedImageUrl = img.url;
                    // this.images_.value.push(img)
                    // this.imagesAdd(this.images_.value);
                    // if(i==files.length-1){
                    // this.imagesService.saveAll(this.images_.value).subscribe(res=>{
                    //     this.loaddingImg = false;
                    //     })
                    // }

                                });  // Now you can use or upload the file
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching image:', error);
                        });
                }
            }

            // this.loaddingImg = true;
            // if (file.size / 1024 <= 160) {
            //     const image = this.img;
            //     const reader = reader_;
            //     let img = new ImageModel();
            //     this.imageToAdd.configurationId = this.configurationId;
            //     this.imageToAdd.file = file;
            //     reader_.onload = (event: any) => {
            //         image.nativeElement.setAttribute('src', reader.result);
            //         this.readerResult = reader.result;
            //         const img_ = new Image();
            //         let height;
            //         let width;
            //         img_.src = reader.result as string;
            //         img_.onload = () => {
            //             height = img_.naturalHeight;
            //             width = img_.naturalWidth;
            //             if (height == 800 && width == 800) {
            //                 if (this.configurationId && this.configurationId > 0) {
            //                    // this.addImg(this.imageToAdd);
            //                    // this.multiImagesToAdd.push(this.imageToAdd);
            //                     this.imagesService.uploadImage(file.name, reader.result).subscribe(res => {
            //                         img.url = res.imageUrl;
            //                         img.ord = this.order_++;
            //                         img.configurationId = this.configurationId;
            //                         this.selectedImageUrl = img.url;
            //                         this.images_.value.push(img)
            //                         this.imagesAdd(this.images_.value);
            //                         if(i==files.length-1){

            //                           this.imagesService.saveAll(this.images_.value).subscribe(res=>{
            //                               this.loaddingImg = false;
            //                           })
            //                         }

            //                     });


            //                 } else {

            //                     this.imagesService.uploadImage(file.name, this.readerResult).subscribe(res => {
            //                         img.url = res.imageUrl;
            //                         img.ord = this.order_++;
            //                         this.selectedImageUrl = img.url;

            //                         this.images_.value.push(img);
            //                         this.imagesAdd(this.images_.value);

            //                         this.loaddingImg = false;
            //                     });


            //                 }
            //             } else {
            //                 const message = `Selectionner une image de dimension 800 X 800 `;
            //                 this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
            //                 this.loaddingImg = false;
            //             }
            //         }


            //     };
            //     reader_.readAsDataURL(file);

            // } else {
            //     const message = `Selectionner une image de taille  inferieur à 160 ko.`;
            //     this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
            //     this.loaddingImg = false;
            // }
        }
    }

    onDragOver(event) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    }

    onDragLeave(event) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    }

    next() {
        let oldIndex = this.index;
        let newIndex = this.index++;
        if (this.index >= this.images_.value.length) {
            this.index = 0;
        }
        const r = this.images_.value[this.index].url;
        this.selectImage(r);


        if (newIndex >= 2 && (newIndex + 2 <= this.images_.value.length)) {
            this.moveArrayItemToNewIndex(this.images_.value, newIndex + 1, 2);


        }


    }

    prev(): void {
        this.index--;
        if (this.index < 0) {
            this.index = this.images_.value.length - 1;
        }
        const r = this.images_[this.index].url;
        this.selectImage(r);
    }

    onSelectedFile(event) {
        if (this.currentRole !== 'ROLE_SUPERADMIN'){
            return;
        }

        const width = 800;
        const height = 800;
        this.loaddingImg = true;
        let img = new ImageModel();
        const file = event.target.files[0];
        // this.imageToAdd.file = file;
        let reader = new FileReader();
        // if(event.target.files[0].size/1024 <= 160 ){
        if ((event.target.files && event.target.files[0])) {
            let filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                // let reader = new FileReader();

                       reader.readAsDataURL(file)
                       reader.onload = (event : any) => {
                           let imageUrl = event.target.result
                           let image = document.createElement("img")
                           image.src = imageUrl
                           image.onload = (e: any) => {
                               let canvas = document.createElement("canvas")
                               canvas.width = width
                               canvas.height = height
                               const context = canvas.getContext("2d")
                               context.drawImage(image, 0, 0, canvas.width, canvas.height)
                               let newImageUrl = context.canvas.toDataURL("image/jpeg", 90)
                               let newImage = document.createElement("img")
                               newImage.src = newImageUrl
               
                               fetch(newImage.src)  // Fetch the image data
                               .then(response => response.blob())  // Convert the response to a Blob
                               .then(blob => {
                                 const fileToUpload = new File([blob], `${file.name}`, { type: blob.type });
                                 let readerToUpload = new FileReader()
                                 readerToUpload.readAsDataURL(fileToUpload)
                                 
                                //  this.addImg(this.imageToAdd);
                                //  this.multiImagesToAdd.push(this.imageToAdd);
                                 readerToUpload.onload = e => {
                                   this.imagesService.uploadImage(fileToUpload).subscribe((res: any) => {
                                    this.loaddingImg = false;
                                    this.selectedImageUrl = res.data;
                                    if(this.configurationId && this.configurationId > 0) {
                                        this.imageToAdd.configurationId = this.configurationId;
                                        this.imageToAdd.file = fileToUpload;
                                        this.addImg(this.imageToAdd);
                                        img.url = res.data;
                                        img.ord = this.order_++;
                                        // this.images_.value.push(img)
                                        this.ImagesAdd.emit(this.images_.value);
                                    } else {
                                        img.url = res.data;
                                        img.ord = this.order_++;
                                        this.images_.value.push(img)
                                        this.ImagesAdd.emit(this.images_.value);
                                    }
                                   
                                   //    img.configurationId = this.configurationId;
                                //    this.imagesService.saveAll(this.images_.value).subscribe(res=>{
                                //        this.loaddingImg = false;
                                //        })
               
                               });  // Now you can use or upload the file
                                }
                            })
                            .catch(error => {
                                console.error('Error fetching image:', error);
                            });
                    }
                }
                let newarr = this.images_.value.sort((a, b) => a.ord - b.ord);
                newarr.map(ex => ex.ord);
                reader.readAsDataURL(event.target.files[i]);

            }
        }


        // }
        // else{
        //     const message = `Selectionner une image de taille  inferieur à 160 ko.`;
        //     this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
        //     this.loaddingImg = false;
        // }
    }

    addImg(img) {
        this.imagesService.pushFileToStorageWithUpdater(img, this.updater).subscribe(
            {
                next : (res) => {

                if (res instanceof HttpResponse) {


                    if (res.body.body) {

                        let newImage = new ImageModel();
                        newImage.configurationId = img.configurationId.toString();
                        newImage.url = res.body.body['url'];
                        newImage.id = res.body?.body['id'];

                        this.images_.next([...this.images_.value, newImage]);

                        const updatedConfig = Object.assign({}, this.configuration);
                        updatedConfig.images = this.images_.value;
                        const updatedConf: Update<ConfigurationModel> = {
                            id: this.configurationId,
                            changes: updatedConfig,
                        };
                        this.store.dispatch(ConfigurationUpdatedSuccessfully({
                            configuration: this.configuration,
                            partialConfiguration: updatedConf
                        }));
                        this.ImagesAdd.emit(this.images_.value);


                        this.loaddingImg = false;

                        const message = `Image has been added Successfully.`;
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);

                    } else {
                        this.loaddingImg = false;
                        const message = `oups, il y a une erreur lors du téléchargement de l'image !`;
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
                    }
                }
            },
            error : () => {
                this.loaddingImg = false;
                const message = `oups, il y a une erreur lors du téléchargement de l'image !`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);
            }
            }
        );
    }

    removeSelectedFile(index, id) {
        if (this.configurationId && this.configurationId) {
            this.deleteImg(id);

        } else {
            this.images_.value.splice(index, 1);
            if (this.images_.value.length == 0) {
                this.selectedImageUrl = '';
            }
        }
    }

    deleteImg(id) {
        this.imagesService.deleteImage(id, this.updater).subscribe(
            res => {


                const updatedConfig = Object.assign({}, this.configuration);
                updatedConfig.images = updatedConfig.images.filter(img => img.id !== id);

                const updatedConf: Update<ConfigurationModel> = {
                    id: this.configurationId,
                    changes: updatedConfig,
                };
                this.store.dispatch(ConfigurationUpdatedSuccessfully({
                    configuration: this.configuration,
                    partialConfiguration: updatedConf
                }));
                this.ImagesAdd.emit(this.images_.value);
                const message = `Image has been deleted Successfully.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);

            }
        )
    }

    drop(event: CdkDragDrop<ImageModel[]>) {

        const currentValue = this.images_.getValue();

        if (event.currentIndex == event.previousIndex && currentValue[event.previousIndex].ord != null) {

            const message = `you chose the same order !`;
            // this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);


        } else {
            if (this.configurationId && this.configurationId > 0) {
                this.OnChangeOrderImages(currentValue[event.previousIndex].id, event.currentIndex, event.previousIndex);

            } else {
                this.ChangeOrderONLocal(currentValue[event.previousIndex], event.currentIndex, event.previousIndex);
            }
        }
    }

    ChangeOrderONLocal(img: ImageModel, value: number, position: number) {

        img.ord = value;


        if (position > value) {
            this.images_.value.forEach(imgs => {
                if (imgs.ord < position && imgs.ord >= value) {
                    imgs.ord = imgs.ord + 1;
                }
            })
        } else {
            this.images_.value.forEach(imgs => {
                if (imgs.ord > position && imgs.ord <= value) {
                    imgs.ord = imgs.ord - 1;
                }
            })
        }
        this.images_.value.sort((a, b) => a.ord - b.ord);
    }

    OnChangeOrderImages(id: number, value: number, position: number) {

        this.imagesService.changeOrderImage(id, value, position).subscribe(
            res => {

                this.store.dispatch(ConfigurationsPageRequested({productId: this.configuration.productId}));


            });

        const message = `Image order successfully has been changed.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);


    }

    AddImage() {

    }
}
