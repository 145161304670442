import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as BestSellsActions from "../_actions/bestSells.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {ConfigurationService} from "../../ek-e-commerce/ek-services/confguration.service";


@Injectable()
export class BestSellsEffects {


    constructor(private actions$: Actions, private configurationService: ConfigurationService, private store: Store<AppState>) {
    }

    BestSellsPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellsActions.BestSellsPageRequested),
            switchMap((action) =>
                this.configurationService.getNew(action.page.pageNumber,action.page.pageSize)
                    .pipe(
                        map(configurations => {
                         return BestSellsActions.BestSellsPageLoadedSuccessfully(
                                {
                                    configurations: configurations.body.content,
                                    page: configurations.body.totalElements,
                                    totalCount: configurations.body.totalElements
                                })
                        }),
                        catchError(error => of(BestSellsActions.BestSellsPageLoadFailed({error})))
                    ))));

    BestSellsCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellsActions.BestSellsCreated),
            switchMap((action) =>
                this.configurationService.save(action.configuration)
                    .pipe(
                        map(data => BestSellsActions.BestSellsCreatedSuccessfully({configuration: data})),
                        catchError(error => of(BestSellsActions.BestSellsCreationFailed({error})))
                    ))));

    BestSellsUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellsActions.BestSellsUpdated),
            switchMap((action) =>
                this.configurationService.update(action.configuration)
                    .pipe(
                        map(configuration => BestSellsActions.BestSellsUpdatedSuccessfully({
                            configuration,
                            partialConfiguration: action.partialConfiguration
                        })),
                        catchError(error => of(BestSellsActions.BestSellsUpdateFailed({error})))
                    ))));

    BestSellsUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellsActions.BestSellsUpdatedActivate),
            switchMap((action) =>
                this.configurationService.changeActivateProduct(action.configuration.id,action.activate,action.updater,action.event)
                    .pipe(
                        map(configuration => BestSellsActions.BestSellsUpdatedActivateSuccessfully({
                            configuration : action.configuration,
                            partialConfiguration: action.partialConfiguration,
                            activate:action.activate,
                            updater:action.updater
                        })),
                        catchError(error => of(BestSellsActions.BestSellsUpdateActivateFailed({error})))
                    ))));

    BestSellsDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BestSellsActions.BestSellsDeleted),
            switchMap((action) =>
                this.configurationService.delete(action.configurationId)
                    .pipe(
                        map(() => BestSellsActions.BestSellsDeletedSuccessfully({configurationId: action.configurationId})),
                        catchError(error => of(BestSellsActions.BestSellsDeleteFailed({error})))
                    ))));
}
