import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as NotificationActions from '../ek-actions/notificationRole.actions';
import {NotificationSettingsService} from '../ek-services/notification-settings.service';


@Injectable()
export class NotificationRoleEffects {
    constructor(private actions$: Actions,
                private NotificationService: NotificationSettingsService,
    ) {
    }

    NotificationsListingPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.notificationManagementPageRequested),
            switchMap((action) =>
                this.NotificationService.getNotificationSettingsListing()
                    .pipe(
                        map(Res => {
                            return NotificationActions.notificationManagementPageLoadedSuccessfully(
                                {
                                    notificationListing: Res,
                                    page: 0,
                                    totalCount: Res.length
                                });
                        }),
                        catchError(error => of(NotificationActions.notificationManagementPageLoadFailed({error})))
                    ))));
}
