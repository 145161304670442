import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ConfigurationCharacteristicModel} from "../ek-models/configuration-characteristic.model";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as ConfigurationCharacteristicActions from "../ek-actions/configuration-characteristic.actions";


export interface ConfigurationCharacteristicState extends EntityState<ConfigurationCharacteristicModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedConfigurationCharacteristicId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<ConfigurationCharacteristicModel> = createEntityAdapter<ConfigurationCharacteristicModel>();

export const initialConfigurationCharacteristicState: ConfigurationCharacteristicState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedConfigurationCharacteristicId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const configurationCharacteristicReducer = createReducer(
    initialConfigurationCharacteristicState,
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicsPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicsPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.configurationCharacteristics,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
            }))),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.configurationCharacteristic, {
            ...state,
            actionsLoading: true,
            lastCreatedConfigurationCharacteristicId: action.configurationCharacteristic.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialConfigurationCharacteristic, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.configurationCharacteristicId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_ConfigurationCharacteristic_SIMPLE.DELETE_SUCCESS'
        }))),
    on(ConfigurationCharacteristicActions.ConfigurationCharacteristicDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function configurationCharacteristicsReducer(state: ConfigurationCharacteristicState | undefined, action: Action) {
    return configurationCharacteristicReducer(state, action)
}
