import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {ClientModel} from "../ek-models/client.model";
import {ClientState} from "../ek-reducers/client.reducers";


export const selectClientsState = createFeatureSelector<ClientState>('clients');

export const selectClientById = (ClientId: number) => createSelector(
    selectClientsState,
    clientsState => {
        if (clientsState) return clientsState.entities[ClientId];
    }
);

export const selectClientsPageLoading = createSelector(
    selectClientsState,
    clientsState => clientsState.listLoading
);

export const selectError = createSelector(
    selectClientsState,
    clientsState => clientsState.error
);

export const selectLastAction = createSelector(
    selectClientsState,
    clientsState => clientsState.lastAction
);


export const selectClientsActionLoading = createSelector(
    selectClientsState,
    clientsState => clientsState.actionsLoading
);

export const selectLastCreatedClientId = createSelector(
    selectClientsState,
    clientsState => clientsState.lastCreatedClientId
);

export const selectClientsShowInitWaitingMessage = createSelector(
    selectClientsState,
    clientsState => clientsState.showInitWaitingMessage
);

export const selectClientsPageLastQuerys = createSelector(
    selectClientsState,
    clientsState => clientsState.lastQuery
);

export const getlastAction = createSelector(
    selectClientsState,
    clientsState => clientsState.lastAction
);

export const selectClientsInStore = createSelector(
    selectClientsState,
    clientsState => {
        const items: ClientModel[] = [];
        each(clientsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ClientModel[] = httpExtension.sortArray(items, clientsState.lastQuery.sortField, clientsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, clientsState.totalCount, '');
    });
export const selectClientsOrdersInStore = createSelector(
    selectClientsState,
    clientsState => {
        const items: ClientModel[] = [];
        each(clientsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ClientModel[] = httpExtension.sortArray(items, clientsState.lastQuery.sortField, clientsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, clientsState.totalCount, '');
    });

